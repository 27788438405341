import { useEffect, useRef } from "react";

export const usePreviousValue = <V>(value: V) => {
  const ref = useRef<V>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};
