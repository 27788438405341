import * as R from "ramda";

import { BaseDataModel, BaseOptionProps } from "types";

import { BaseAdapter } from "./BaseAdapter";

export type SegmentProps = BaseOptionProps & { id: string };

class SegmentsAdapterClass extends BaseAdapter<SegmentProps> {
  receivingMapper = {
    label: R.prop("label"),
    value: R.prop("id"),
    id: R.prop("id"),
  } as const;

  toModel(data: BaseDataModel[]) {
    return R.map(this.runMapper<SegmentProps>(this.receivingMapper), data);
  }
}

export const SegmentsAdapter = new SegmentsAdapterClass();
