import React, { useContext } from "react";

import { Flex, Layout } from "antd";
import cn from "classnames";

import { ConfigContext } from "context/ConfigContext";
import { useDateOptions } from "hooks";
import { dayjsUTC as dayjs, formatDate } from "utils";

import styles from "./Footer.module.scss";

const { Footer: FooterAntD } = Layout;

export const Footer = () => {
  const { VER, ENV } = useContext(ConfigContext);
  const { dateOptions } = useDateOptions();
  const isDateAvailable = !!dateOptions?.dataReceivedDate;
  const latestDate = formatDate(dateOptions?.dataReceivedDate, "M/D/YYYY");
  return (
    <FooterAntD className={cn("base-container", styles.footer)}>
      <Flex justify={isDateAvailable ? "space-between" : "center"}>
        <p>
          {dayjs().year()} © AxiomHealth {VER}
        </p>
        {!["qa", "prod", "dev", "demo"].includes(ENV) && isDateAvailable && (
          <p>Actual Data as of {latestDate}</p>
        )}
      </Flex>
    </FooterAntD>
  );
};
