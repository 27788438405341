import * as R from "ramda";

import { BaseDataModel } from "types";
import { capitalize, getStateCode } from "utils";

import { BaseAdapter } from "../BaseAdapter";

export type MapTableSeriesModel = Record<string, number | null>;

type FacilityModel = {
  npi: number;
  name: string;
  cityAndState: string;
  type: string;
  totalCount: number;
  counts: MapTableSeriesModel;
  periods: MapTableSeriesModel;
};

export type FacilityMapTableModel = {
  totalRecords: number;
  facilities: FacilityModel[];
};

const takeoutCounts = (key: string) =>
  R.pipe(
    R.propOr<{ name: string; value: number | null }[]>([], key),
    (data: { name: string; value: number | null }[]) =>
      data.reduce<Record<string, number | null>>((acc, curr) => {
        acc[curr.name] = curr.value;
        return acc;
      }, Object.assign({}))
  );

class FacilityMapTableAdapterClass extends BaseAdapter<FacilityMapTableModel> {
  facilityMapper = {
    npi: R.prop("facilityNpi"),
    name: R.prop("facilityName"),
    cityAndState: R.pipe<any, string[], string>(R.props(["city", "state"]), ([city, state]) => {
      return `${capitalize(R.toLower(city))}, ${getStateCode(state)}`;
    }),
    type: R.prop("facilityType"),
    totalCount: R.prop("totalCount"),
    counts: takeoutCounts("counts"),
    periods: takeoutCounts("periods"),
  } as const;

  receivingMapper = {
    totalRecords: R.prop("totalResults"),
    facilities: R.pipe(R.propOr([], "physicians"), R.map(this.runMapper(this.facilityMapper))),
  } as const;

  toModel(item: BaseDataModel) {
    return this.runMapper<FacilityMapTableModel>(this.receivingMapper)(item);
  }
}

export const FacilityMapTableAdapter = new FacilityMapTableAdapterClass();
