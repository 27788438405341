import React, { ReactNode } from "react";

import { Tooltip, TooltipProps } from "antd";
import cn from "classnames";

import { FC } from "types";

import styles from "./TooltipWrapper.module.scss";

type Props = {
  tooltip: ReactNode;
  show?: boolean;
} & TooltipProps;

export const TooltipWrapper: FC<Props> = ({
  children,
  tooltip,
  className,
  placement = "top",
  show = true,
  ...rest
}) => {
  return (
    <Tooltip
      id={`tooltip`}
      rootClassName={cn(styles.tooltip, className)}
      title={show ? tooltip : ""}
      color={"#fff"}
      placement={placement}
      {...rest}
    >
      {children}
    </Tooltip>
  );
};
