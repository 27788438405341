import { useCallback } from "react";

import { AxiosError } from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import * as ErrorMessages from "constants/errorMessages";
import { loginPath } from "constants/navigationPaths";
import { useAlerts } from "hooks/useAlerts";
import { useAuthenticate } from "hooks/useAuthenticate";
import { createErrorPagePath } from "utils/navigation";

export const useQueryError = () => {
  const { addAlert } = useAlerts();
  const { logout } = useAuthenticate();
  const navigate = useNavigate();
  const location = useLocation();

  const handleError = useCallback(
    <T extends AxiosError>(error: T) => {
      console.log("QueryError error: ", error);
      const { status } = error.response || {};

      switch (status) {
        case undefined: {
          addAlert(ErrorMessages.defaultError, "warning");
          break;
        }
        case 401: {
          logout();
          if (location.pathname !== loginPath) {
            navigate(loginPath, { state: { from: `${location.pathname}${location.search}` } });
          }
          setTimeout(() => {
            addAlert(ErrorMessages.sessionEnded, "error");
          }, 0);
          break;
        }
        case 403: {
          navigate(createErrorPagePath(403));
          break;
        }
        case 404: {
          addAlert(ErrorMessages.error_404, "error");
          break;
        }
        default: {
          addAlert(ErrorMessages.error_500, "error");
          break;
        }
      }
    },
    [addAlert, history]
  );

  return { handleError };
};
