import {
  assoc,
  clone,
  flatten,
  intersection,
  isEmpty,
  keys,
  map,
  pipe,
  pluck,
  prop,
  sortBy,
  uniqBy,
} from "ramda";

import { SegmentModel } from "adapters";

export const useCombinedSegmentsConfig = (
  segments: string[],
  data: Record<string, SegmentModel>
) => {
  if (isEmpty(segments)) return undefined;
  if (segments.length === 1) return data[segments[0]];

  const aggregationColumns = segments.slice(1).reduce((acc, curr) => {
    return intersection(acc, data[curr].aggregationColumns);
  }, data[segments[0]].aggregationColumns);

  const stackByColumns = segments.slice(1).reduce((acc, curr) => {
    return intersection(acc, data[curr].stackByColumns);
  }, data[segments[0]].stackByColumns);

  const tabs = segments.slice(1).reduce((acc, curr) => {
    return intersection(acc, data[curr].tabs || []);
  }, data[segments[0]].tabs || []);

  const layoutLabels = segments.slice(1).reduce((acc, curr) => {
    return intersection(acc, pluck("label", data[curr].layout));
  }, pluck("label", data[segments[0]].layout));

  const layout = map((label) => {
    const findLayoutRowFilters = (segment: string) =>
      data[segment].layout.find((row) => row.label === label)?.filterIds || [];

    const filterIds = segments.slice(1).reduce((acc, curr) => {
      return intersection(acc, findLayoutRowFilters(curr));
    }, findLayoutRowFilters(segments[0]));

    return { label, filterIds };
  }, layoutLabels);

  const filtersKeys = segments.slice(1).reduce((acc, curr) => {
    return intersection(acc, keys(data[curr].filters));
  }, keys(data[segments[0]].filters));

  const filters = filtersKeys.reduce((acc, curr) => {
    const getSegmentFilter = (segment: string) => clone(data[segment].filters[curr]);

    const isAggregation = aggregationColumns.includes(curr) && curr !== "procedure_categories";

    const filter = getSegmentFilter(segments[0]);

    const combinedOptions = pipe(
      map((segment: string) => {
        const segmentOptions = getSegmentFilter(segment).values;
        return isAggregation
          ? [{ label: data[segment].label, isHeader: true }, ...segmentOptions]
          : segmentOptions;
      }),
      flatten,
      (options) => {
        return isAggregation ? options : uniqBy(prop("value"), options);
      },
      (options) => {
        return curr === "procedure_categories" ? sortBy(prop<any>("value"), options) : options;
      }
    )(segments);

    acc[curr] = assoc("values", combinedOptions, filter);

    return acc;
  }, Object.assign({}));

  const id = `${segments.join("+")}`;

  return {
    aggregationColumns,
    stackByColumns,
    tabs,
    layout,
    filters,
    id,
    label: id,
  } as any as SegmentModel;
};
