import { useMemo } from "react";

import { isNil } from "ramda";
import { useSearchParams } from "react-router-dom";

export const SEARCH_KEY = "f";

type UrlFilters = Record<string, unknown> | null | undefined;

export const useUrlFilters = <T extends UrlFilters>() => {
  const [query, setQuery] = useSearchParams();

  const urlFilters = useMemo<T>(() => {
    return query.has(SEARCH_KEY) ? JSON.parse(query.get(SEARCH_KEY) || "") : null;
  }, [query]);

  const replaceUrlFilters = (filters: T | null | undefined) => {
    if (isNil(filters)) {
      setQuery("");
    } else {
      setQuery((prev) => {
        prev.set(SEARCH_KEY, JSON.stringify(filters));
        return prev;
      });
    }
  };

  return [urlFilters, replaceUrlFilters] as const;
};
