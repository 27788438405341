import { replace, toPairs, values } from "ramda";
import { useLocation, useParams } from "react-router-dom";

import * as PATHS from "constants/navigationPaths";

const allRoutes: string[] = values(PATHS);

export const useRoutePath = () => {
  const { pathname } = useLocation();
  const params = useParams();
  const paramsPairs = toPairs(params) as [string, string][];

  return paramsPairs.reduce((acc, [key, value]) => {
    const path = replace(value, `:${key}`, pathname);
    if (allRoutes.includes(path)) return path;
    const optionalPath = replace(value, `:${key}?`, pathname);
    return allRoutes.includes(optionalPath) ? optionalPath : "";
  }, pathname);
};
