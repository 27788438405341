import * as R from "ramda";

import { BaseDataModel, ObjectValues } from "types";

import { BaseAdapter } from "../BaseAdapter";

import { CompanyAdapter } from "./Company";
import { LicenseModule, LicenseModuleAdapter } from "./LicenseModule";

export const USER_ROLES = {
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  SUPER_USER: "SUPER_USER",
  USER: "USER",
} as const;

export type UserRole = ObjectValues<typeof USER_ROLES>;
export type AllowedUserRole = Exclude<UserRole, "SUPER_ADMIN">;

export type UserGroup = {
  id: number;
  name: string;
};

export type UserProfileModel = {
  id: number;
  username: string;
  enabled: boolean;
  details: {
    email: string;
    firstName: string;
    lastName: string;
    avatar?: string;
  };
  roles: UserRole[];
  licenses: LicenseModule[];
};

class UserProfileAdapterClass extends BaseAdapter<UserProfileModel> {
  detailsMapper = {
    email: R.propOr("", "email"),
    firstName: R.propOr("", "name"),
    lastName: R.propOr("", "lastname"),
    avatar: CompanyAdapter.detailsMapper.logo,
  } as const;

  receivingMapper = {
    id: R.prop("id"),
    enabled: R.prop("enabled"),
    username: R.pipe(R.props(["name", "lastname"]), R.join(" ")),
    details: this.runMapper(this.detailsMapper),
    roles: R.prop("roles"),
    licenses: R.pipe(
      R.propOr([], "licenseAllocations"),
      (allocations: { licensedModule: BaseDataModel }[]) =>
        allocations.reduce<LicenseModule[]>((acc, curr) => {
          const module = LicenseModuleAdapter.toModel(curr.licensedModule);
          if (module.isActive) {
            acc.push({
              ...module,
              groupId: R.prop("appGroupId", curr),
            });
          }
          return acc;
        }, [])
    ),
  } as const;

  sendingMapper = {
    email: R.prop("email"),
    name: R.prop("firstName"),
    lastname: R.prop("lastName"),
  } as const;

  toModel(item: BaseDataModel) {
    return this.runMapper<UserProfileModel>(this.receivingMapper)(item);
  }

  toApiModel(data: UserProfileModel["details"]) {
    return this.runMapper<BaseDataModel>(this.sendingMapper)(data);
  }
}

export const UserProfileAdapter = new UserProfileAdapterClass();
