import React, { useState } from "react";

import { Flex, Menu, MenuProps } from "antd";
import cn from "classnames";
import { isNil } from "ramda";
import { Link } from "react-router-dom";

import { DropdownArrowSVG } from "assets/icons";
import { AppModule, getModuleFromPath } from "constants/appModules";
import { useRoutePath, useRoutesMap } from "hooks";
import { HeaderLinkProps } from "types";

import styles from "./NavMenu.module.scss";

type mapProps = HeaderLinkProps & {
  currentPath?: string;
  currentModule?: AppModule;
  openMenu?: string;
  level?: number;
};

const mapLinkProps2MenuItem = ({
  openMenu,
  currentModule,
  currentPath,
  level = 1,
  ...item
}: mapProps): Exclude<MenuProps["items"], undefined>[number] => {
  const isActive =
    item.path === currentPath ||
    (level === 1 &&
      ((Array.isArray(item.moduleId) && item.moduleId.includes(currentModule?.id as any)) ||
        item.moduleId === currentModule?.id));

  return {
    key: item.label,
    label: item.path ? (
      <Link to={item.path} className={styles.itemContent}>
        {item.label}
      </Link>
    ) : !isNil(item.subLinks) ? (
      <Flex gap='0.25rem' align='center' className={styles.itemContent}>
        {item.label}
        <DropdownArrowSVG className={cn({ [styles.open]: item.label === openMenu })} />
      </Flex>
    ) : (
      item.label
    ),
    disabled: item.isDisabled,
    children: item.isDisabled
      ? undefined
      : item.subLinks?.map((subItem) =>
          mapLinkProps2MenuItem({ ...subItem, currentPath, level: level + 1 })
        ),
    className: cn(styles.menuItem, { [styles.activeItem]: isActive }),
    popupClassName: styles.navSubMenu,
    popupOffset: [0, -14],
  };
};

export const NavMenu = () => {
  const currentPath = useRoutePath();
  const { navigationLinksMap } = useRoutesMap();
  const [openMenu, setOpenMenu] = useState<string | undefined>(undefined);
  const currentModule = getModuleFromPath(currentPath);

  const menuItems = navigationLinksMap.map((item) =>
    mapLinkProps2MenuItem({ ...item, currentPath, currentModule, openMenu })
  );

  return (
    <Menu
      items={menuItems}
      mode='horizontal'
      className={styles.navMenu}
      triggerSubMenuAction='click'
      onOpenChange={(openChange) => {
        setOpenMenu(openChange[0]);
      }}
      selectable={false}
    />
  );
};
