import { DiagnosisApiOption, DiagnosisItem } from "types";

import { addDiagnosisKey } from "./dropdownOptions";

export const adaptDiagnosisOption = (d: DiagnosisApiOption) => {
  const {
    name,
    marketSegments = null,
    diagnosisLocations: locations = null,
    diagnosisCategories: categories = null,
  } = d;
  const newValue: Partial<DiagnosisItem> = { label: name };
  if (marketSegments) {
    newValue["marketSegments"] = marketSegments;
  }
  if (locations) {
    newValue["locations"] = locations;
  }
  if (categories) {
    newValue["categories"] = categories;
  }
  return addDiagnosisKey(newValue);
};
