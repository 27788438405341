import { readmissionFiltersValidationSchema } from "hooks/useGlobalFilters";
import { ObjectValues } from "types";

import { appModules } from "./appModules";
import * as PATHS from "./navigationPaths";

export const pageIdMap = {
  "targeting.hosp": {
    appModule: appModules.ST_F,
    path: PATHS.hospitalTargetingPath,
    apiFiltersPath: "/targeting/hospitals",
    persistFiltersModule: "targeting",
    validationSchema: undefined,
  },
  "targeting.hosp.profile": {
    appModule: appModules.ST_F,
    path: PATHS.hospitalProfilePath,
    apiFiltersPath: "/targeting/hospitals",
    persistFiltersModule: "targeting",
    validationSchema: undefined,
  },
  "targeting.phys": {
    appModule: appModules.ST_P,
    path: PATHS.physicianTargetingPath,
    apiFiltersPath: "/targeting/physicians",
    persistFiltersModule: "targeting",
    validationSchema: undefined,
  },
  "targeting.phys.profile": {
    appModule: appModules.ST_P,
    path: PATHS.physicianProfilePath,
    apiFiltersPath: "/targeting/physicians",
    persistFiltersModule: "targeting",
    validationSchema: undefined,
  },
  "market_intel.dashboard": {
    appModule: appModules.MI,
    path: PATHS.marketIntelligenceDashboardPath,
    apiFiltersPath: "/market-intelligence/procedures",
    persistFiltersModule: "market_intelligence",
    validationSchema: undefined,
  },
  "market_intel.procedures": {
    appModule: appModules.MI,
    path: PATHS.proceduresPath,
    apiFiltersPath: "/market-intelligence/procedures",
    persistFiltersModule: "market_intelligence",
    validationSchema: undefined,
  },
  "market_intel.readmissions": {
    appModule: appModules.MI_R,
    path: PATHS.readmissionsPath,
    apiFiltersPath: "/market-intelligence/readmissions",
    persistFiltersModule: "market_intelligence.readmissions",
    validationSchema: readmissionFiltersValidationSchema,
  },
} as const;

export type PageId = keyof typeof pageIdMap;
export type PageMeta = ObjectValues<typeof pageIdMap>;
