import * as R from "ramda";

import { BaseDataModel } from "types";

import { BaseAdapter } from "../BaseAdapter";

export type CompanyWithIcon = {
  name: string;
  logoUrl: string;
  total: number;
};

export type SponsorshipSummary = {
  company: string;
  logo: string;
  product: string;
  productType: string;
  royalty: boolean;
  consultant: boolean;
  speaker: boolean;
  honoraria: boolean;
};

class SponsorshipSummaryAdapterClass extends BaseAdapter<SponsorshipSummary[]> {
  receivingMapper = {
    company: R.prop("company"),
    logo: R.prop("companyLogo"),
    product: R.prop("product"),
    productType: R.prop("productCategory"),
    royalty: R.prop("royalty"),
    consultant: R.prop("consultingFee"),
    speaker: R.prop("speakingEngagement"),
    honoraria: R.prop("honoraria"),
  } as const;

  toModel(item: BaseDataModel) {
    return R.pipe(
      R.propOr([], "sponsorships"),
      R.map(this.runMapper<SponsorshipSummary>(this.receivingMapper))
    )(item);
  }
}
export const OpenPaymentsAdapter = new SponsorshipSummaryAdapterClass();
