import { Dayjs } from "dayjs";
import { curry, map, prop } from "ramda";

import { DateOptionsResponseProps } from "api/filtersOptions";
import { IAdapter, Mapper } from "types";
import { dayjsUTC } from "utils";

export type DateOptionsModel = {
  dataReceivedDate: Dayjs;
  lastPipelineRunDate: Dayjs;
  cutoffMonth: Dayjs;
  cutoffQuarter: Dayjs;
  cutoffYear: Dayjs;
  startDate: Dayjs;
  forecastEndDate: Dayjs;
};

const processDateVar = curry(
  (propName: keyof DateOptionsResponseProps, obj: DateOptionsResponseProps) => {
    const propValue = prop(propName, obj);
    return dayjsUTC(propValue);
  }
);

class DateOptionsAdapterClass implements IAdapter {
  receivingMapper = {
    dataReceivedDate: processDateVar("dataReceivedDate"),
    lastPipelineRunDate: processDateVar("lastPipelineRunDate"),
    cutoffMonth: processDateVar("monthlyCutoffDate"),
    cutoffQuarter: processDateVar("quarterlyCutoffDate"),
    cutoffYear: processDateVar("yearlyCutoffDate"),
    startDate: processDateVar("startDate"),
    forecastEndDate: processDateVar("endDate"),
  } as const;

  toModel(item: unknown) {
    return map<Mapper, DateOptionsModel>((fn) => fn(item), this.receivingMapper);
  }
}

export const DateOptionsAdapter = new DateOptionsAdapterClass();
