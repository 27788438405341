import * as R from "ramda";

import { PageId } from "constants/pageIds";
import { BaseDataModel } from "types";

import { BaseAdapter } from "./BaseAdapter";
import { HeaderFormik, SegmentFiltersModel } from "./SegmentFilters";

export type Bookmark = {
  id: number;
  pageId: PageId;
  name: string;
  description: string;
  filters: SegmentFiltersModel;
  params?: Record<string, unknown>;
};

export type SaveNewBookmark = Omit<Bookmark, "id">;

export class BookmarkAdapterClass extends BaseAdapter<Bookmark[]> {
  receivingMapper = {
    id: R.prop("id"),
    pageId: R.prop("pageId"),
    name: R.prop("name"),
    description: R.prop("description"),
    filters: R.pipe<
      any,
      [Omit<HeaderFormik, "segments">, Record<string, string[]> & { segments: string[] }],
      Bookmark["filters"]
    >(R.props(["metaFilters", "filters"]) as any, ([metaFilters, filters]) => {
      return {
        ...R.mapObjIndexed((v) => JSON.parse(v), metaFilters),
        segments: filters.segments,
        filters: R.dissoc("segments", filters),
      };
    }),
    params: R.pipe(R.prop("params"), (params) => (R.isEmpty(params) ? undefined : params)),
  } as const;

  sendingMapper = {
    pageId: R.prop("pageId"),
    name: R.prop("name"),
    description: R.prop("description"),
    filters: R.pipe<
      any,
      SegmentFiltersModel,
      [string[], SegmentFiltersModel["filters"]],
      Record<string, string[]>
    >(R.prop("filters"), R.props(["segments", "filters"]) as any, ([segments, filters]) => ({
      ...filters,
      segments,
    })),
    metaFilters: R.pipe<
      any,
      SegmentFiltersModel,
      Record<string, string | string[]>,
      Record<string, string>
    >(
      R.prop("filters"),
      R.omit(["filters", "segments"]),
      R.mapObjIndexed((v) => JSON.stringify(v))
    ),
    params: R.propOr({}, "params"),
  } as const;

  toModel(data: BaseDataModel[]) {
    return R.map(this.runMapper<Bookmark>(this.receivingMapper), data);
  }

  toApiModel(data: SaveNewBookmark) {
    return this.runMapper<SaveNewBookmark>(this.sendingMapper)(data);
  }
}

export const BookmarkAdapter = new BookmarkAdapterClass();
