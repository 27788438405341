import { AxiosRequestConfig } from "axios";

import { UserProfileAdapter } from "adapters";
import { BaseDataModel } from "types";
import ApiService from "utils/apiService";

export type AuthLoginData = {
  username: string;
  password: string;
};

type AuthResponse = {
  accessToken: string;
  authorities: unknown;
  expiresIn: number;
  idToken: string;
  refreshToken: string;
  tokenType: string;
};

export const authenticate = (data: AuthLoginData) =>
  ApiService.post<AuthResponse>("/api/auth/login", data).then(
    (data) => `${data.tokenType} ${data.idToken}`
  );

export const getLoggedUser = (options: AxiosRequestConfig = {}) =>
  ApiService.get<BaseDataModel>("/api/current-user", undefined, options).then((data) =>
    UserProfileAdapter.toModel(data)
  );

type NewUserData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  checkTOS: boolean;
  token?: string;
};

export const signup = (data: NewUserData) => ApiService.post("/api/auth/sign-up", data);

export const forgotPasswordSendEmail = (email: string) =>
  ApiService.post("/api/auth/forgot-password", { email });

export const resetPassword = (
  data: { password: string; passwordConfirm: string },
  hash: string,
  email: string
) => ApiService.put(`/api/auth/forgot-password/confirm?email=${email}&hash=${hash}`, data);

export const checkInviteStatus = (token: string) =>
  ApiService.get<{ status: "valid" | "expired" }>(`/api/auth/invite-status`, { token });

type ChangePasswordData = {
  username: string;
  password: string;
  passwordConfirm: string;
};
export const changePassword = (data: ChangePasswordData) =>
  ApiService.put("/api/change-password", data);
