import * as R from "ramda";

import { BaseDataModel } from "types";

import { BaseAdapter } from "../BaseAdapter";

export type FacilityModel = {
  npi: string;
  name: string;
  state: string;
  city: string;
  address: string;
};

class FacilitiesSearchAdapterClass extends BaseAdapter<FacilityModel[]> {
  receivingMapper = {
    npi: R.prop("npi"),
    name: R.prop("facilityName"),
    state: R.prop("state"),
    city: R.prop("city"),
    address: R.prop("address"),
  };

  toModel(res: BaseDataModel[]) {
    return R.map(this.runMapper<FacilityModel>(this.receivingMapper), res);
  }
}
export const FacilitiesSearchAdapter = new FacilitiesSearchAdapterClass();
