import { getCompanyGroups } from "api/company";
import { settingsQueryKeys } from "constants/queryKeys";

import { useQueryData } from "./useQueryData";

export const useCompanyGroups = () => {
  return useQueryData([settingsQueryKeys.companyGroups], ({ signal }) =>
    getCompanyGroups({ signal })
  );
};
