import { useCallback } from "react";

import { DateOptionsAdapter } from "adapters/DateOptions";
import { getDateOptions } from "api/filtersOptions";
import { AXIOMHEALTH_TOKEN_KEY } from "constants/authentication";
import { settingsQueryKeys } from "constants/queryKeys";
import { HOUR } from "constants/time";
import { TimeGranularity } from "types";
import { dayjsUTC } from "utils";

import { useLocalStorage } from "./useLocalStorage";
import { useQueryData } from "./useQueryData";

export const useDateOptions = () => {
  const { getItem } = useLocalStorage();
  const { data: dateOptions, isLoading } = useQueryData(
    [settingsQueryKeys.dateOptions],
    async () => {
      const data = await getDateOptions();
      return DateOptionsAdapter.toModel(data);
    },
    {
      suspense: true,
      staleTime: HOUR,
      refetchInterval: () => {
        const now = dayjsUTC();
        return now.endOf("day").diff(now, "ms") + 1;
      },
      enabled: !!getItem(AXIOMHEALTH_TOKEN_KEY),
    }
  );

  const getCutoffDate = useCallback(
    (granularity: TimeGranularity) => {
      if (!dateOptions) return dayjsUTC();

      switch (granularity) {
        case TimeGranularity.MONTHLY:
          return dateOptions.cutoffMonth;
        case TimeGranularity.QUARTERLY:
          return dateOptions.cutoffQuarter;
        case TimeGranularity.YEARLY:
          return dateOptions.cutoffYear;
      }
    },
    [dateOptions]
  );

  return { dateOptions, isLoading, getCutoffDate };
};
