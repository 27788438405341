import * as R from "ramda";

import { BaseDataModel } from "types";
import { capitalize, getStateCode } from "utils";

import { BaseAdapter } from "../BaseAdapter";

type ProgramInfo = { name: string; positions: string };

export type FacilityProfileModel = {
  name: string;
  npi: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  programs: {
    fellowships: ProgramInfo[];
    residencies: ProgramInfo[];
  };
  facilityInfo: {
    region: string;
    county: string;
    fips: string;
    teachingStatus: string;
    adultTraumaLevel: number;
    pediatricTraumaLevel: number;
    gpo: string;
    size: string;
    bedCount: string;
    operatingRooms: number;
    ehrSystem: string;
  };
};

const capitalizeProp = (key: string) =>
  R.pipe<any, string, string>(R.propOr("-", key), (val) => capitalize(val.toLowerCase()));

const getProgramsInfo = (key: string) =>
  R.pipe<any, { program_name: string; positions_available: string }[], ProgramInfo[]>(
    R.propOr([], key),
    R.map((item) => ({ name: item.program_name, positions: item.positions_available }))
  );

class FacilityProfileAdapterClass extends BaseAdapter<FacilityProfileModel> {
  facilityInfoMapper = {
    region: capitalizeProp("censusRegion"),
    county: capitalizeProp("county"),
    fips: R.propOr("-", "fipsCode"),
    teachingStatus: R.propOr("-", "teachingStatus"),
    adultTraumaLevel: R.propOr("-", "adultTraumaLevel"),
    pediatricTraumaLevel: R.propOr("-", "pediatricTraumaLevel"),
    gpo: R.propOr("-", "gpo"),
    size: R.propOr("-", "hospitalSize"),
    bedCount: R.propOr("-", "totalBedCount"),
    operatingRooms: R.propOr("-", "operatingRoomsNumber"),
    ehrSystem: R.propOr("-", "ehrSystem"),
  } as const;

  programsMapper = {
    fellowships: getProgramsInfo("fellowshipProgramsMeta"),
    residencies: getProgramsInfo("residencyProgramsMeta"),
  } as const;

  receivingMapper = {
    name: R.propOr("", "facilityName"),
    npi: R.prop("npi"),
    address: capitalizeProp("address"),
    city: capitalizeProp("city"),
    state: R.pipe<any, string, string>(R.prop("state"), getStateCode),
    zipCode: R.pipe(R.propOr("", "zipCode"), R.slice(0, 5)),
    programs: this.runMapper(this.programsMapper),
    facilityInfo: this.runMapper(this.facilityInfoMapper),
  } as const;

  toModel(item: BaseDataModel) {
    return this.runMapper<FacilityProfileModel>(this.receivingMapper)(item);
  }
}

export const FacilityProfileAdapter = new FacilityProfileAdapterClass();
