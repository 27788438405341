import { useCallback, useEffect, useMemo } from "react";

import { useRecoilState } from "recoil";
import * as Yup from "yup";

import { SegmentFiltersModel } from "adapters";
import { globalFiltersState } from "atoms/globalFilters";
import { PageId, pageIdMap } from "constants/pageIds";
import { SECOND } from "constants/time";
import { TimeGranularity } from "types";
import { formatDate } from "utils";

import { useAlerts } from "./useAlerts";
import { useDateOptions } from "./useDateOptions";
import { usePersistentState } from "./usePersistentState";
import { useUrlFilters } from "./useUrlFilters";

export const useGlobalFilters = <S extends SegmentFiltersModel>(
  pageId: PageId
): [S | undefined, (filters: S | undefined) => void] => {
  const { persistFiltersModule, validationSchema = defaultFiltersValidationSchema } =
    pageIdMap[pageId];
  const persistKey = `AXIOMHEALTH_${persistFiltersModule}_filters`;
  const [urlFilters, setUrlFilters] = useUrlFilters<S | undefined>();
  const [persistValue, setPersistValue] = usePersistentState<S | undefined>(persistKey, undefined);
  const [state, setState] = useRecoilState(globalFiltersState);
  const { dateOptions } = useDateOptions();
  const { addAlert, dismissAlert } = useAlerts();

  useEffect(() => {
    const schemaCheck = validationSchema(
      dateOptions ? formatDate(dateOptions.startDate) : undefined,
      dateOptions ? formatDate(dateOptions.forecastEndDate) : undefined
    );

    const handleError = (error: Yup.ValidationError, store: "URL" | "Persist") => {
      if (error.name === "ValidationError") {
        console.error(`${store} Filters ValidationError: `, error.errors);
        console.error(`${store} Filters ValidationError: `, error.value);
      } else {
        console.error(`${store} Filters Error: `, error);
      }
      setFilters(undefined);
      const id = addAlert(
        "Sorry, there was an error in stored filters, Please select new ones",
        "warning"
      );
      setTimeout(() => {
        dismissAlert(id);
      }, 15 * SECOND);
    };

    const checkAndSetFilters = async () => {
      if (urlFilters) {
        try {
          schemaCheck.validateSync(urlFilters, { abortEarly: false });
          setFilters(urlFilters);
        } catch (err: any) {
          handleError(err, "URL");
        }
      } else if (persistValue) {
        try {
          schemaCheck.validateSync(persistValue, { abortEarly: false });
          setFilters(persistValue);
        } catch (err: any) {
          handleError(err, "Persist");
        }
      }
    };

    checkAndSetFilters();
  }, []);

  const setFilters = useCallback(
    (filters: S | undefined) => {
      setUrlFilters(filters);
      setPersistValue(filters);
      setState((prev) => ({ ...prev, [persistFiltersModule]: filters }));
    },
    [module]
  );

  const moduleFilters = useMemo(
    () => state[persistFiltersModule],
    [state[persistFiltersModule], module]
  ) as S | undefined;

  return [moduleFilters, setFilters];
};

const defaultFiltersValidationSchema = (minDate = "2017-01-01", maxDate = "2027-12-31") =>
  Yup.object().shape({
    aggregationLevel: Yup.string().required(),
    segments: Yup.array().of(Yup.string()).min(1).required(),
    periodStart: Yup.date().min(minDate).max(Yup.ref("periodEnd")).required(),
    periodEnd: Yup.date().max(maxDate).min(Yup.ref("periodStart")).required(),
    timeGranularity: Yup.string()
      .required()
      .oneOf([TimeGranularity.MONTHLY, TimeGranularity.QUARTERLY, TimeGranularity.YEARLY]),
    filters: Yup.object().required(),
  });

export const readmissionFiltersValidationSchema = () =>
  Yup.object().shape({
    aggregationLevel: Yup.string().required(),
    segments: Yup.array().of(Yup.string()).min(1).required(),
    filters: Yup.object().required(),
    readmissionType: Yup.array().of(Yup.string()).length(1),
  });
