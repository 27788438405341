import { useCallback } from "react";

import { isEmpty } from "ramda";

import { UserRole } from "adapters";
import { getLoggedUser } from "api/authentication";
import { AppModule, appModules } from "constants/appModules";
import { AXIOMHEALTH_TOKEN_KEY } from "constants/authentication";
import { settingsQueryKeys } from "constants/queryKeys";
import { HOUR } from "constants/time";

import { useLocalStorage } from "./useLocalStorage";
import { useQueryData } from "./useQueryData";

export const useCurrentUser = () => {
  const { getItem } = useLocalStorage();
  const storedToken = getItem<string>(AXIOMHEALTH_TOKEN_KEY);
  const query = useQueryData(
    [settingsQueryKeys.currentUser, storedToken],
    () => {
      if (!storedToken) return;
      return getLoggedUser({ headers: { Authorization: storedToken } });
    },
    {
      enabled: !!storedToken,
      staleTime: HOUR,
      suspense: true,
    }
  );

  const checkUserHasAccess = (module: AppModule) => {
    if (module === appModules.INTERNAL) return true;
    return !isEmpty(query.data?.licenses.filter(({ moduleId }) => moduleId === module.id));
  };

  const hasRole = useCallback(
    (role: UserRole) => Boolean(query.data?.roles.includes(role)),
    [query.data]
  );

  const isSuperUser = useCallback(() => {
    return hasRole("SUPER_USER");
  }, [hasRole]);

  const isAdmin = useCallback(() => {
    return hasRole("ADMIN") || hasRole("SUPER_ADMIN");
  }, [hasRole]);

  return { ...query, checkUserHasAccess, isSuperUser, isAdmin };
};
