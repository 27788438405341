import * as R from "ramda";

import { IAdapter, Mapper } from "types";

export type InfoPanelModel = {
  nationalGrowth: number | null;
  pastProceduresCount: number | null;
  currentProceduresCount: number | null;
  covidSensitivity: number | null;
};

class InfoPanelAdapterClass implements IAdapter {
  receivingMapper = {
    nationalGrowth: R.prop("nationalGrowth"),
    pastProceduresCount: R.cond<any, number | undefined>([
      [R.has("pastTotal"), R.prop("pastTotal")],
      [R.has("pastProceduresCount"), R.prop("pastProceduresCount")],
      [R.T, R.always(undefined)],
    ]),
    currentProceduresCount: R.cond<any, number | undefined>([
      [R.has("currentTotal"), R.prop("currentTotal")],
      [R.has("currentProceduresCount"), R.prop("currentProceduresCount")],
      [R.T, R.always(undefined)],
    ]),
    covidSensitivity: R.cond<any, number | undefined>([
      [R.has("covid"), R.prop("covid")],
      [R.has("covidSensitivity"), R.prop("covidSensitivity")],
      [R.T, R.always(undefined)],
    ]),
  } as const;
  toModel(item: unknown) {
    return R.map<Mapper, InfoPanelModel>((fn) => fn(item), this.receivingMapper);
  }
}
export const InfoPanelAdapter = new InfoPanelAdapterClass();
