import * as R from "ramda";

import {
  DataSeriesResponseWithCAGR,
  ProceduresDataRequestProps,
  ProceduresPanelResponse,
} from "api/procedures";
import { colorsChart } from "constants/chartColors";
import {
  BaseOptionProps,
  DataSeriesModel,
  DataSeriesResponse,
  DisplayTypes,
  IAdapter,
  Mapper,
  TimeGranularity,
} from "types";
import {
  chartSeriesToModel,
  DateType,
  emptySeriesCheck,
  findFirstFuturePeriodIndex,
  formatLabel,
  makePlural,
  splitSnakeCase,
} from "utils";

export const ProceduresPanelTitleMap: { [level: string]: string } = {
  market_segments: "Market Segments",
  spine_procedure_categories: "Procedure Categories",
  spine_procedures: "Procedures",
};

export interface ProceduresPanelModel {
  series: DataSeriesResponseWithCAGR[];
  totals: DataSeriesResponseWithCAGR;
  hasModellingError: boolean;
  errorMessage?: string;
  failedItems: string[];
}
export interface ProceduresCountsPanelModel extends ProceduresPanelModel {
  revenue: {
    series: DataSeriesResponseWithCAGR[];
    totals: DataSeriesResponseWithCAGR;
  };
}

export type StackByOption = BaseOptionProps & { id: string };

export const doNotStackOpt = { value: "", label: "Do Not Stack" };
export const getInitialStack = (aggregationLevel: string | undefined, options: StackByOption[]) =>
  !!aggregationLevel ? options.find((s) => s.id === aggregationLevel) : undefined;

const getPeriodShortYear = (period: string) => period?.match(/\d{4}/)?.[0].replace(/^\d{2}/, "'");

const getShortYears = (periods: string[], forecastIndex: number) => ({
  pastStartYear: getPeriodShortYear(periods[0]),
  pastEndYear: getPeriodShortYear(
    periods[forecastIndex > 0 ? forecastIndex - 1 : periods.length - 1]
  ),
  forecastedStartYear: getPeriodShortYear(periods[forecastIndex]),
  forecastedEndYear: getPeriodShortYear(periods[periods.length - 1]),
});

const addCAGRHeaders = (periods: string[], forecastIndex: number, hidePredictions: boolean) => {
  const headers = R.clone(periods);
  const shortYears = getShortYears(periods, forecastIndex);
  if (forecastIndex !== 0) {
    headers.push(`${shortYears.pastStartYear}-${shortYears.pastEndYear} CAGR`);
  }
  if (!hidePredictions && forecastIndex > -1) {
    headers.push(`${shortYears.forecastedStartYear}-${shortYears.forecastedEndYear} CAGR`);
  }
  return headers;
};

const takeOutSeries = (series: DataSeriesResponseWithCAGR) => {
  const dataKeys = R.keys(series.data);
  const normalizeData = (data: DataSeriesResponseWithCAGR["data"][string]) => {
    const keys = R.keys(data);
    return keys.reduce((acc, curr) => {
      const value = data[curr];
      if (R.isNil(value) || typeof value !== "number") {
        acc[curr] = null;
      } else {
        acc[curr] = value;
      }

      return acc;
    }, Object.assign({}));
  };
  return {
    ...series,
    data: dataKeys.reduce((acc, curr) => {
      acc[curr] = normalizeData(series.data[curr]);
      return acc;
    }, Object.assign({})),
  };
};

class ProceduresPanelAdapterClass implements IAdapter<ProceduresPanelModel> {
  receivingMapper = {
    series: R.pipe<any, DataSeriesResponseWithCAGR[], DataSeriesResponseWithCAGR[]>(
      R.prop("dataSeries"),
      R.map(takeOutSeries)
    ),
    totals: R.pipe(R.prop("totals"), R.assoc("name", "Totals")),
    hasModellingError: R.prop("hasModellingError"),
    errorMessage: R.prop("errorMessage"),
    failedItems: R.propOr([], "failedItems"),
  };

  receivingMapperCounts = {
    ...this.receivingMapper,
    revenue: this.revenueMapper,
  };

  sendingMapper = {
    aggregationColumn: R.prop("aggregationLevel"),
    stackbyColumn: R.path(["stackBy", "id"]),
    filters: R.prop("filters"),
    startDate: R.prop("periodStart"),
    endDate: R.prop("periodEnd"),
    timeScale: R.prop("timeGranularity"),
  } as const;

  revenueMapper(data: ProceduresPanelResponse) {
    const apiRevenue = R.prop("revenueData", data);
    return {
      series: R.pipe<any, DataSeriesResponseWithCAGR[], DataSeriesResponseWithCAGR[]>(
        R.prop("dataSeries"),
        R.map((item) => ({
          ...item,
          name: splitSnakeCase(item.name),
        }))
      )(apiRevenue),
      totals: R.pipe<any, DataSeriesResponseWithCAGR, DataSeriesResponseWithCAGR>(
        R.prop("totals"),
        R.assoc("name", "Totals")
      )(apiRevenue),
    };
  }

  toModel(item: unknown) {
    return R.map<Mapper, ProceduresPanelModel>((fn) => fn(item), this.receivingMapper);
  }

  toModelCounts(item: unknown) {
    return R.map<Mapper, ProceduresCountsPanelModel>((fn) => fn(item), this.receivingMapperCounts);
  }

  toApiModel(data: unknown) {
    return R.map<Mapper, ProceduresDataRequestProps>((fn) => fn(data), this.sendingMapper);
  }

  takeOutChartData(
    data: DataSeriesResponse[],
    periods: string[],
    displayType: DisplayTypes,
    failedItems: string[]
  ) {
    if (!R.isEmpty(failedItems)) {
      data = R.map((item) => {
        let message = "";
        if (failedItems.includes(item.name)) {
          const lastValue = item.data[R.last(periods) as string]?.value;
          if (R.isNil(lastValue)) {
            message = " (No Predictions Available)";
          } else {
            message = " (Some Parts Of Predictions Not Available)";
          }
        }
        return {
          ...item,
          name: item.name + message,
        };
      }, data);
    }
    return R.addIndex<DataSeriesResponse, DataSeriesModel>(R.map)(
      (item, idx) => ({
        ...chartSeriesToModel(item, displayType, periods),
        color: colorsChart[idx],
      }),
      data
    );
  }

  takeOutTableData(
    data: DataSeriesResponseWithCAGR[],
    periods: string[],
    tableHeader: string,
    forecastIndex: number,
    timeGranularity: TimeGranularity,
    hidePredictions = false
  ) {
    const showCAGR = timeGranularity === TimeGranularity.YEARLY;
    const copyPeriods = showCAGR
      ? addCAGRHeaders(periods, forecastIndex, hidePredictions)
      : R.clone(periods);
    return {
      headers: [tableHeader, ...copyPeriods],
      rows: data
        .map((item) => {
          const cells = R.map<typeof periods, any>(
            (key) => R.path(["data", `${key}`], item),
            periods
          );
          if (showCAGR && forecastIndex !== 0) {
            cells.push({ value: item.cagrPast, isCAGR: true });
          }
          if (showCAGR && !hidePredictions && forecastIndex > -1) {
            cells.push({ value: item.cagrForecasted, isCAGR: true });
          }
          return {
            cells: [item.name, ...cells],
          };
        })
        .reverse(),
    };
  }

  takeOutExportData(
    data: DataSeriesResponseWithCAGR[],
    periods: string[],
    tableHeader: string,
    displayType: DisplayTypes,
    forecastIndex: number,
    timeGranularity: TimeGranularity,
    hidePredictions = false
  ) {
    const showCAGR = timeGranularity === TimeGranularity.YEARLY;
    const copyPeriods = showCAGR
      ? addCAGRHeaders(periods, forecastIndex, hidePredictions)
      : R.clone(periods);
    const exportData = [[tableHeader, ...copyPeriods]];
    const prepareValue = (item: DataSeriesResponse, key: string, prop: DisplayTypes = "value") => {
      const decimalPrecision = prop === "ratio" ? 1 : 0;
      const suffix = prop === "value" ? "" : "%";
      return R.pipe<any, number | null, string>(
        R.pathOr(null, ["data", key, prop]),
        (val: number | null) => (!R.isNil(val) ? `${val.toFixed(decimalPrecision)}${suffix}` : "-")
      )(item);
    };
    R.forEach((item) => {
      const name = item.name;
      const values = R.map((key) => `${prepareValue(item, key, displayType)}`, periods);
      if (showCAGR && forecastIndex !== 0) {
        values.push(`${formatLabel(item.cagrPast, true, 0)}`);
      }
      if (showCAGR && !hidePredictions && forecastIndex > -1) {
        values.push(`${formatLabel(item.cagrForecasted, true, 0)}`);
      }
      exportData.push([name, ...values]);
    }, data);
    if (displayType === "value") {
      const totals = R.last(data)!;
      const values = R.map((key) => `${prepareValue(totals, key, "growth")}`, periods);
      if (showCAGR && forecastIndex !== 0) {
        values.push(`${formatLabel(totals.cagrPast, true, 0)}`);
      }
      if (showCAGR && !hidePredictions && forecastIndex > -1) {
        values.push(`${formatLabel(totals.cagrForecasted, true, 0)}`);
      }
      exportData.push(["Growth", ...values]);
    }
    return exportData;
  }

  parseResponse(
    response: ProceduresPanelResponse,
    tableHeader: string,
    timeGranularity: TimeGranularity,
    dataReceivedDate: DateType,
    hidePredictions = false
  ) {
    const data = this.toModel(response);
    let periods = R.keys(R.pathOr({}, ["totals", "data"], data)) as string[];
    const forecastIndex = findFirstFuturePeriodIndex(periods, dataReceivedDate, timeGranularity);
    if (hidePredictions && forecastIndex > -1) {
      periods = periods.slice(0, forecastIndex);
    }
    const tableData = this.takeOutTableData(
      data.series,
      periods,
      tableHeader,
      forecastIndex,
      timeGranularity,
      hidePredictions
    );
    return {
      panelData: data,
      table: tableData,
      periods,
      tableHeader,
      forecastIndex: forecastIndex > -1 ? forecastIndex : undefined,
    };
  }

  parseStackedResponse(
    response: ProceduresPanelResponse,
    stackBy: BaseOptionProps,
    dataReceivedDate: DateType,
    timeGranularity: TimeGranularity
  ) {
    const data = this.toModel(response);
    const periods = R.keys(R.pathOr({}, ["totals", "data"], data)) as string[];
    const forecastIndex = findFirstFuturePeriodIndex(periods, dataReceivedDate, timeGranularity);
    const tableHeader = makePlural(stackBy.label);
    const tableData = this.takeOutTableData(
      R.clone(data.series.filter((s) => !emptySeriesCheck(s))),
      periods,
      tableHeader,
      forecastIndex,
      timeGranularity
    );
    return { panelData: data, table: tableData, periods, tableHeader, forecastIndex };
  }

  addTotalsToTable(
    data:
      | {
          panelData: Omit<ProceduresPanelModel, "hasModellingError">;
          table: { headers: string[]; rows: { cells: any[] }[] };
          periods: string[];
        }
      | undefined,
    displayType: DisplayTypes,
    forecastIndex: number,
    timeGranularity: TimeGranularity,
    hidePredictions = false
  ) {
    if (!data) return;
    const showCAGR = timeGranularity === TimeGranularity.YEARLY;
    const tableData = R.clone(data.table);
    const seriesTotal = R.pathOr<DataSeriesResponseWithCAGR | Record<string, unknown>>(
      {},
      ["panelData", "totals"],
      data
    );
    if (displayType !== "ratio") {
      const totalCells = data.periods.map((key) => R.pathOr<any>(null, ["data", key], seriesTotal));
      if (showCAGR && forecastIndex !== 0) {
        totalCells.push({ value: R.propOr(null, "cagrPast", seriesTotal), isCAGR: true });
      }
      if (showCAGR && !hidePredictions && forecastIndex > -1) {
        totalCells.push({ value: R.propOr(null, "cagrForecasted", seriesTotal), isCAGR: true });
      }
      tableData.rows = [
        ...tableData.rows,
        {
          isBold: true,
          cells: ["Totals", ...totalCells],
        } as any,
      ];
      if (displayType === "value") {
        const growthCells = data.periods.map((key) =>
          formatLabel(R.pathOr<any>(null, ["data", key, "growth"], seriesTotal), true, 0)
        );
        if (showCAGR && forecastIndex !== 0) {
          growthCells.push(formatLabel(R.propOr(null, "cagrPast", seriesTotal), true, 0));
        }
        if (showCAGR && !hidePredictions && forecastIndex > -1) {
          growthCells.push(formatLabel(R.propOr(null, "cagrForecasted", seriesTotal), true, 0));
        }
        tableData.rows = [
          ...tableData.rows,
          {
            isBold: true,
            noPrefix: true,
            cells: ["Growth", ...growthCells],
          } as any,
        ];
      }
    }
    return tableData;
  }

  getStackedTableHeader = (stackBy: Record<string, string>) => {
    let stackByLabel = `${stackBy.label}`;
    if (stackBy.id === "PROCEDURE_MARKET_SEGMENT") {
      stackByLabel = "Market Segment";
    }
    if (
      ["PROCEDURE", "PROCEDURE_CATEGORY", "PROCEDURE_MARKET_SEGMENT"].includes(`${stackBy.value}`)
    ) {
      stackByLabel = makePlural(stackByLabel);
    }
    return stackByLabel;
  };
}

export const ProceduresPanelAdapter = new ProceduresPanelAdapterClass();
