import * as R from "ramda";

import { USStatesCodeToNameMap } from "constants/USStates";
import { BaseDataModel } from "types";
import { capitalize } from "utils";

import { BaseAdapter } from "../BaseAdapter";

type EducationHistory = {
  organization: string;
  program: string;
  graduated: number | undefined;
};

type PhysicianFacility = {
  name: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  npi: string;
  accountType: string;
  region: string;
  county: string;
  fipsCode: string;
  teachingStatus: string;
  adultTraumaLevel: string;
  pediatricTraumaLevel: string;
  gpo: string;
  size: string;
  bedCount: string;
  operatingRooms: number;
};

export type PhysicianProfile = {
  displayName: string;
  npi: string;
  photoUrl?: string;
  yearsOfPractice: string;
  gender: string;
  basedIn: string;
  specialty: string;
  interests: string;

  contactInfo: {
    email: string;
    phone: string;
    addresses: {
      address: string;
      city: string;
      zipCode: string;
      state: string;
    }[];
  };

  educationHistory: {
    school: EducationHistory;
    residency: EducationHistory;
    fellowship: EducationHistory;
  };

  academicAndConsulting: {
    name: string;
    date: string;
  }[];

  calendar: {
    events: {
      date: string;
      title: string;
      description: string;
    }[];
    summary: {
      day: string;
      title: string;
    }[];
  };

  workHistory: {
    title: string;
    facilityName: string;
    facilityNpi: string;
    description: string;
    periodStart: string;
    periodEnd: string;
  }[];

  socials: {
    name: string;
    url: string;
  }[];

  certificates: {
    organization: string;
    certifiedIn?: string;
  }[];

  licenses: {
    organization: string;
    activeThrough: number;
  }[];

  marketReport: {
    lastYearTotalRevenue: number;
    lastThreeYearGrowth: number;
    marketShare: number;
  };

  axiomScores: {
    productSupporter: number;
    upAndComer: number;
    earlyAdopter: number;
  };

  topPoints: { title: string; description: string }[];

  facilities: PhysicianFacility[];
};

const mockAcademics: PhysicianProfile["academicAndConsulting"] = [
  { name: "Advances in Minimally Invasive Spinal Procedures", date: "2022-04-12" },
  { name: "Spinal Fusion Techniques: Current Trends", date: "2022-01-12" },
  { name: "Neurological Complications in Spinal Surgery", date: "2021-10-12" },
];

const mockWorkHistory: PhysicianProfile["workHistory"] = [
  {
    title: "Spinal Surgeon",
    facilityName: "Metro Orthopedic Clinic",
    facilityNpi: "1982913307",
    description:
      "Served as a Senior Spinal Surgeon at Metro Orthopedic Clinic, a prominent orthopedic facility. Specialized in diagnosing and treating various spinal disorders, including herniated discs, scoliosis, and spinal stenosis.",
    periodStart: "2005-04",
    periodEnd: "2013-01",
  },
  {
    title: "Head of Spine Surgery Department",
    facilityName: "Riverside Medical Center",
    facilityNpi: "1306991211",
    description:
      "Led the Spine Surgery Department at Riverside Medical Center, overseeing a team of skilled surgeons and healthcare professionals. Conducted a high volume of spinal surgeries, emphasizing innovative approaches to enhance patient recovery and reduce hospital stays.",
    periodStart: "2013-03",
    periodEnd: "2019-12",
  },
  {
    title: "Chief Spinal Surgeon",
    facilityName: "Baptist Medical Center",
    facilityNpi: "1598744856",
    description:
      "Currently holding the position of Chief Spinal Surgeon at Baptist Medical Center, a cutting-edge center for spinal care. Manages all aspects of spinal surgery, including patient consultations, surgical procedures, and post-operative care.",
    periodStart: "2019-06",
    periodEnd: "Present",
  },
];

const mockSocialLinks = [
  { name: "LinkedIn", url: "https://linkedin.com/in/andrea-cannestra/" },
  { name: "X", url: "https://x.com/andrea-cannestra" },
  { name: "Facebook", url: "https://facebook.com/andrea-cannestra" },
];

const mockMarketReport = {
  lastYearTotalRevenue: 860200,
  lastThreeYearGrowth: 3.3,
  marketShare: 12.9,
};

const mockAxiomScores: PhysicianProfile["axiomScores"] = {
  productSupporter: 74,
  upAndComer: 21,
  earlyAdopter: 55,
};

const mockTopPoints = [
  { title: "Top Procedure", description: "Pediatric Spinal Surgery (Fusion & Dynamic)" },
  {
    title: "Location",
    description: "Works out of Baptist Medical Center and Jones Medical Office",
  },
  { title: "Recent Publication", description: "Advances in Minimally Invasive Spinal Procedures" },
  {
    title: "Close Contacts",
    description: "Dr. James Lee, Jordan Flynn, Richard Carlsen, Dr. Samuel Erigaisi",
  },
  { title: "Hobbies", description: "Studying robotics, swimming, and cooking" },
];

const mockCalendar: PhysicianProfile["calendar"] = {
  events: [
    {
      date: "2023-09-23",
      title: "Attending HIMSS Conference",
      description:
        "The HIMSS Conference takes place in L.A. on September 9th. It is a place for physicians and healthcare professionals to connect.",
    },
    {
      date: "2023-09-29",
      title: "Lecture at Albany Medical College",
      description:
        "Albany Medical College will be hosting physicians to lecture new methodologies.",
    },
    {
      date: "2023-10-04",
      title: "Attending Global Neurology Summit",
      description:
        "The Global Neurology Summit takes place in Denver on October 4th. It is a place for physicians and healthcare professionals in neurology to connect.",
    },
  ],
  summary: [
    { day: "Monday", title: "Office Procedures, San Jose" },
    { day: "Tuesday", title: "Spinal Procedures at BMC" },
    { day: "Wednesday", title: "Office Procedures, San Jose" },
    { day: "Thursday", title: "Spinal Procedures at BMC" },
    { day: "Friday", title: "Private Practice Procedures" },
  ],
};

const takeOutHistory = (prop: string) =>
  R.pipe(R.pathOr({}, [prop, 0]), (data: any) => {
    if (R.isNil(data?.specialty) && R.isNil(data?.organization) && R.isNil(data?.years)) return;
    return {
      program: data?.specialty,
      organization: data?.organization,
      graduated: parseInt(data?.years?.split("-")[1]) || undefined,
    };
  });

const takeOutState = R.pipe(R.match(/\b[A-Z]{2}\b/), R.pathOr("", [0]));

class PhysicianProfileAdapterClass extends BaseAdapter<PhysicianProfile> {
  contactInfoMapper = {
    email: R.propOr("", "email"),
    phone: R.propOr("", "phone"),
    addresses: R.pipe(R.prop("officeAddress"), R.split(", "), (addressArray: string[]) => {
      const [city, stateAndZip] = R.takeLast(2, addressArray);
      const address = R.join(", ", R.dropLast(2, addressArray));
      return [
        {
          address,
          city,
          zipCode: stateAndZip.match(/\d+/gm)?.[0]?.slice(0, 5),
          state: takeOutState(stateAndZip),
        },
      ];
    }),
  } as const;

  educationHistoryMapper = {
    school: takeOutHistory("medicalSchools"),
    residency: takeOutHistory("residencies"),
    fellowship: takeOutHistory("fellowships"),
  } as const;

  licensesMapper = {
    organization: R.prop("organization"),
    activeThrough: R.pipe(R.prop("activeThrough"), parseInt),
  } as const;

  facilitiesMapper = {
    name: R.propOr("", "facilityName"),
    address: R.propOr("", "address"),
    city: R.propOr("", "city"),
    state: R.propOr("", "stateCode"),
    zipCode: R.pipe(R.propOr("", "zipCode"), (data: string) => data.slice(0, 5)),
    npi: R.propOr("", "npi"),
    accountType: R.propOr("-", "facilityType"),
    region: R.propOr("-", "regionName"),
    county: R.propOr("-", "county"),
    fipsCode: R.propOr("-", "fips"),
    teachingStatus: R.propOr("-", "teachingStatus"),
    adultTraumaLevel: R.propOr("-", "traumaCenterLevel"),
    pediatricTraumaLevel: R.propOr("-", "traumaCenterLevelPediatric"),
    gpo: R.propOr("-", "gpo"),
    size: R.propOr("-", "hospitalSize"),
    bedCount: R.propOr("-", "totalHospitalBeds"),
    operatingRooms: R.propOr("-", "numberOfOperatingRooms"),
  } as const;

  receivingMapper = {
    displayName: R.pipe(
      R.props(["namePrefix", "fullName", "title"]),
      R.filter((str) => !(R.isNil(str) || R.isEmpty(str))),
      R.join(" "),
      (displayName) => {
        if (/[a-z] [A-Z]+$/gm.test(displayName)) {
          return displayName.replace(/ [A-Z]+$/gm, (m) => `,${m}`);
        }
        return displayName;
      }
    ),
    npi: R.propOr("", "npi"),
    photoUrl: R.propOr("", "photoUrl"),
    yearsOfPractice: R.pipe(R.propOr("", "yearsOfExperience"), R.replace(/[^\d+\-]/gm, "")),
    gender: R.propOr("", "gender"),
    basedIn: R.pipe(
      R.propOr("", "officeAddress"),
      takeOutState,
      R.propOr("-", R.__, USStatesCodeToNameMap),
      R.toLower,
      capitalize
    ),
    specialty: R.propOr("-", "specialty"),
    interests: R.pipe(R.propOr([], "interests"), R.pluck("interest"), R.join(", ")),
    contactInfo: this.runMapper<PhysicianProfile["contactInfo"]>(this.contactInfoMapper),
    educationHistory: this.runMapper<PhysicianProfile["educationHistory"]>(
      this.educationHistoryMapper
    ),
    academicAndConsulting: R.propOr(mockAcademics, "academicAndConsulting"),
    workHistory: R.propOr(mockWorkHistory, "workHistory"),
    socials: R.propOr(mockSocialLinks, "socials"),
    certificates: R.propOr([], "certificates"),
    licenses: R.pipe(R.propOr([], "licenses"), R.map(this.runMapper(this.licensesMapper))),
    marketReport: R.propOr(mockMarketReport, "marketReport"),
    axiomScores: R.propOr(mockAxiomScores, "axiomScores"),
    topPoints: R.propOr(mockTopPoints, "topPoints"),
    facilities: R.pipe(R.propOr([], "facilities"), R.map(this.runMapper(this.facilitiesMapper))),
    calendar: R.propOr(mockCalendar, "calendar"),
  } as const;

  toModel(item: BaseDataModel) {
    return this.runMapper<PhysicianProfile>(this.receivingMapper)(item);
  }
}

export const PhysicianProfileAdapter = new PhysicianProfileAdapterClass();
