export type StoredValue = string | unknown[] | Record<string, unknown> | undefined;

export const useLocalStorage = () => {
  const setItem = (key: string, value: StoredValue) => {
    window.localStorage.setItem(key, JSON.stringify(value));
  };

  const getItem = <T extends StoredValue>(key: string) => {
    const storedData = window.localStorage.getItem(key);
    try {
      return storedData && (JSON.parse(storedData) as T);
    } catch (err) {
      console.log(`useLocalStorage getItem Error trying to get "${key}": `, err);
      return null;
    }
  };

  const removeItem = (key: string) => {
    window.localStorage.removeItem(key);
  };

  const clearStorage = () => {
    window.localStorage.clear();
  };

  return { setItem, getItem, removeItem, clearStorage };
};
