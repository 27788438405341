import { concat, filter, join, map, pathOr, pipe, prop, props, reverse, sortBy } from "ramda";

import { IAdapter, Mapper } from "types";

export type ReferralItemModel = {
  physicianName: string;
  physicianNpi: string;
  facilityName: string;
  count: number;
  hasData: boolean;
  physicianSpecialty: string;
  id: string;
  type: "inbound" | "outbound";
};

class ReferralNetworkAdapterClass implements IAdapter {
  referralMapper = {
    physicianName: pipe(props(["namePrefix", "physicianName"]), filter(Boolean), join(" ")),
    physicianNpi: prop("physicianNpi"),
    facilityName: prop("physicianMainHospitalName"),
    count: prop("count"),
    hasData: prop("hasData"),
    physicianSpecialty: pathOr("", ["physicianSpecialties", 0]),
    id: pipe(props(["type", "physicianNpi"]), join("-")),
    type: prop("type"),
  } as const;

  receivingMapper = {
    list: (data: any) => {
      const inboundList = this.takeOutReferral("inbound")(data.inbound);
      const outboundList = this.takeOutReferral("outbound")(data.outbound);

      return pipe<any, ReferralItemModel[], ReferralItemModel[]>(
        sortBy(prop("count")),
        reverse
      )(concat(inboundList, outboundList));
    },
  } as const;

  takeOutReferral = (type: ReferralItemModel["type"]) => (data: any) =>
    map(
      (item) => this.runMapper<ReferralItemModel[]>(this.referralMapper)({ ...item, type }),
      data
    );

  runMapper = <R, D = Record<string, unknown>>(mapper: Record<keyof D, any>) => {
    return (data: D) => map<Mapper, R>((fn) => fn(data), mapper);
  };

  toModel(item: unknown) {
    return map<Mapper, { list: ReferralItemModel[] }>((fn) => fn(item), this.receivingMapper).list;
  }
}

export const ReferralNetworkAdapter = new ReferralNetworkAdapterClass();
