import { useState } from "react";

import { isNil } from "ramda";

import { StoredValue, useLocalStorage } from "./useLocalStorage";

export const usePersistentState = <T extends StoredValue>(key: string, initialValue: T) => {
  const { setItem, removeItem, getItem } = useLocalStorage();

  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      return getItem<T>(key) || initialValue;
    } catch {
      return initialValue;
    }
  });

  const setValue = (value: T | ((old: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (isNil(valueToStore)) {
        removeItem(key);
      } else {
        setItem(key, valueToStore);
      }
    } catch (error) {}
  };

  return [storedValue, setValue] as [T, typeof setValue];
};
