import * as R from "ramda";

import { FacilityCountsRequestProps, FacilityCountsResponseProps } from "api/facilityProfile";
import { DataSeriesModel, DataSeriesResponse, IAdapter, Mapper } from "types";
import { splitSnakeCase } from "utils";

import { FacilityProfileFiltersAdapter } from "./Filters";

export type FacilityDetailsModel = {
  series: DataSeriesResponse[];
  tableData: {
    headers: string[];
    rows: { cells: (string | { value: number | null; ratio: number | null })[] }[];
  };
  totals: DataSeriesResponse & { total: number };
};

export class FacilityDetailsAdapterClass implements IAdapter {
  receivingMapper = {
    series: R.pipe<any, DataSeriesModel[], DataSeriesModel[], DataSeriesModel[]>(
      R.prop("dataSeries"),
      R.map((item) => ({ ...item, name: splitSnakeCase(item.name) })),
      R.sortBy(R.prop("total"))
    ),
    tableData: (item: FacilityCountsResponseProps) => {
      const headers = R.pipe(R.propOr({}, "data"), R.keys)(item.totals);
      const rows = R.map(
        (row) => ({
          cells: [row.name, ...R.map((header) => R.pathOr(null, ["data", header], row), headers)],
        }),
        item.dataSeries
      );
      rows.push({
        isBold: true,
        cells: [
          "Totals",
          ...R.map((header) => R.pathOr(null, ["data", header], item.totals), headers),
        ],
      } as any);
      return {
        headers,
        rows,
      };
    },
    totals: R.pipe(R.prop("totals"), R.assoc("name", "Totals")),
  };

  sendingMapper = {
    stackingColumn: R.path(["stackBy", "value"]),
  };

  toModel(item: unknown) {
    return R.map<Mapper, FacilityDetailsModel>((fn) => fn(item), this.receivingMapper);
  }

  toApiModel(item: unknown) {
    return {
      ...FacilityProfileFiltersAdapter.toApiModel(item),
      ...R.map<Mapper, FacilityCountsRequestProps>((fn) => fn(item), this.sendingMapper),
    };
  }
}

export const FacilityDetailsAdapter = new FacilityDetailsAdapterClass();
