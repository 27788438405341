import React from "react";

import { Layout } from "antd";
import { Outlet } from "react-router-dom";

import { Footer } from "components/Footer";
import { Header } from "components/Header";

const { Content } = Layout;

export const HomePageLayout = () => {
  return (
    <>
      <Header />
      <Content>
        <Outlet />
      </Content>
      <Footer />
    </>
  );
};
