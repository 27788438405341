export enum DiagnosisCategory {
  MARKET_SEGMENT = "diagnosisMarketSegment",
  LOCATION = "diagnosisLocation",
  CATEGORY = "diagnosisCategory",
  DIAGNOSIS = "diagnosis",
}

export type DiagnosisApiOption = {
  name: string;
  marketSegments?: string[];
  diagnosisLocations?: string[];
  diagnosisCategories?: string[];
};

export type DiagnosisItem = {
  label: string;
  value: string;
  marketSegments?: string[];
  locations?: string[];
  categories?: string[];
};

export type DiagnosisDataType = {
  [DiagnosisCategory.MARKET_SEGMENT]: DiagnosisItem[];
  [DiagnosisCategory.LOCATION]?: DiagnosisItem[];
  [DiagnosisCategory.CATEGORY]: DiagnosisItem[];
  [DiagnosisCategory.DIAGNOSIS]: DiagnosisItem[];
};
