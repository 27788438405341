import { FunctionType } from "types";

export const debounce = <P = any>(fn: FunctionType<P>, delay: number) => {
  let timeout: unknown;

  const clear = () => {
    if (timeout) {
      clearTimeout(timeout as number);
      timeout = null;
    }
  };

  const wrapperFn = (...args: P[]) => {
    clear();

    timeout = setTimeout(() => {
      fn(...args);
      clear();
    }, delay);
  };

  wrapperFn.clear = () => {
    clear();
  };

  return wrapperFn;
};
