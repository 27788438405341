import * as R from "ramda";

import { DataSeriesModel, DataSeriesResponse, IAdapter, Mapper } from "types";
import { chartSeriesToModel } from "utils";

export type PhysicianPaymentsDetails = {
  series: DataSeriesModel[];
  periods: string[];
};

class PaymentsTotalAdapterClass implements IAdapter {
  receivingMapper = {
    series: R.pipe<any, DataSeriesResponse[], DataSeriesModel[]>(
      R.prop("dataSeries"),
      R.map((series) => chartSeriesToModel(series, "value"))
    ),
    periods: R.pipe(R.pathOr([], ["dataSeries", 0, "data"]), R.keys),
  } as const;

  toModel(item: unknown) {
    return R.map<Mapper, PhysicianPaymentsDetails>((fn) => fn(item), this.receivingMapper);
  }

  takeOutExportData(series: DataSeriesModel[], periods: string[], header: string) {
    const exportData = [[header, ...periods]];

    const data2Dictionary = (data: DataSeriesModel["data"]) =>
      data.reduce<Record<string, number | null>>((acc, { name, y }) => {
        acc[name] = y;
        return acc;
      }, Object.assign({}));

    R.forEach((data) => {
      const dataDictionary = data2Dictionary(data.data);
      const periodsData = periods.map((key) => `${R.propOr("-", key, dataDictionary)}`);
      exportData.push([data.name, ...periodsData]);
    }, series);

    return exportData;
  }
}

export const PaymentsTotalAdapter = new PaymentsTotalAdapterClass();
