import { RefObject, useEffect } from "react";

export const useClickOutComponent = (
  ref: RefObject<any>,
  action: (event: MouseEvent) => void,
  dependencies?: any[]
) => {
  if (!dependencies) {
    dependencies = [];
  }
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        action(event);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, ...dependencies]);
};
