import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSetRecoilState } from "recoil";

import { authenticate } from "api/authentication";
import { globalFiltersState } from "atoms/globalFilters";
import { AXIOMHEALTH_TOKEN_KEY } from "constants/authentication";
import { useLocalStorage } from "hooks/useLocalStorage";
import { axiosInst } from "utils";

export const useAuthenticate = () => {
  const { setItem, clearStorage } = useLocalStorage();
  const setGlobalFilters = useSetRecoilState(globalFiltersState);
  const queryClient = useQueryClient();

  const createLoginMutation = (action?: (token: string) => void) =>
    useMutation({
      mutationFn: authenticate,
      onSuccess: (token) => {
        axiosInst.defaults.headers.common["Authorization"] = token;
        setItem(AXIOMHEALTH_TOKEN_KEY, token);
        action?.(token);
      },
    });

  const logout = (action?: () => void) => {
    clearStorage();
    axiosInst.defaults.headers.common["Authorization"] = undefined;
    setGlobalFilters({});
    queryClient.clear();
    queryClient.invalidateQueries();
    action?.();
  };

  return { createLoginMutation, logout };
};
