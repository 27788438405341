import { cond, endsWith, includes, pipe, propOr, replace, T } from "ramda";

import { USStatesNameToCodeMap } from "../constants/USStates";

export const toSnakeCase = (text: string) => text.toLowerCase().replace(/\s+/g, "_");
export const splitSnakeCase = (text: string) => text.replace(/_+/g, " ");
export const splitCamelCase = (text: string) =>
  text.replace(/[a-z][A-Z]/gm, (m: string) => `${m[0]} ${m[1]}`);
export const capitalize = (text: string) =>
  text.replace(/\W?\b[a-z]/gm, (m: string) => (m[0] === "'" ? m : m.toUpperCase()));
export const toIdFormat = (text: string) =>
  toSnakeCase(splitCamelCase(text)).replace(/[^a-zA-Z0-9_]+/g, "");

export const makePlural = cond<[string], string>([
  [endsWith("sis"), replace(/sis$/gm, "ses")],
  [endsWith("s"), (word) => word],
  [endsWith("y"), replace(/y$/gm, "ies")],
  [T, (word) => word + "s"],
]);

export const makeSingular = cond<[string], string>([
  [endsWith("ies"), replace(/ies$/gm, "y")],
  [endsWith("ses"), replace(/ses$/gm, "sis")],
  [endsWith("sis"), (word) => word],
  [endsWith("s"), replace(/s$/gm, "")],
  [T, (word) => word],
]);

const shortWords = [
  "and",
  "as",
  "by",
  "down",
  "in",
  "into",
  "is",
  "it",
  "of",
  "off",
  "on",
  "onto",
  "or",
  "that",
  "the",
  "to",
  "male",
  "for",
  "east",
  "west",
  "rita",
  "area",
  "john",
  "oaks",
  "luke",
  "last",
  "good",
  "edge",
  "san",
  "ohio",
  "new",
  "york",
  "city",
  "all",
];
export const normalizeName = pipe(
  splitSnakeCase,
  replace(/\b\w+\b(?![\w\s]*[)\/])/gm, (match: string) => {
    const len = match.length;
    const lowerMatch = match.toLowerCase();
    if (len < 2) return lowerMatch;
    if (len > 4 || includes(lowerMatch, shortWords)) return capitalize(lowerMatch);
    return match;
  })
);

export const getStateCode = (key: string) =>
  propOr<string, typeof USStatesNameToCodeMap, string>(
    key,
    key.toUpperCase(),
    USStatesNameToCodeMap
  );

export const normalizeAggregationLevel = (aggregationLevel?: string) =>
  capitalize(makePlural(splitCamelCase(splitSnakeCase(aggregationLevel || "Procedures"))));
