import * as R from "ramda";

import { BaseDataModel, BaseOptionProps, ObjectValues, TimeGranularity } from "types";

import { BaseAdapter } from "./BaseAdapter";

const FILTER_TYPES = {
  SINGLE_SELECT: "SINGLE_SELECT",
  MULTI_SELECT: "MULTI_SELECT",
  RANGE: "RANGE",
} as const;

type FilterTypes = ObjectValues<typeof FILTER_TYPES>;

export type FiltersValue = BaseOptionProps & { parentValues: string[] | null };
// export type FiltersValueWithChildren = FiltersValue & { children?: FiltersValueWithChildren[] };
export type FilterProps = {
  id: string;
  label: string;
  parentId: string | null;
  enabled?: boolean;
  type: FilterTypes;
  values: FiltersValue[];
};

export type SegmentTabModel = {
  id: string;
  label: string;
  type: string;
  title: string;
  order: number;
  description?: string;
};

export type SegmentModel = {
  filters: Record<string, FilterProps>;
  aggregationColumns: string[];
  stackByColumns: (BaseOptionProps & { id: string })[];
  layout: { label: string; filterIds: string[] }[];
  tabs?: SegmentTabModel[];
  id: string;
  label: string;
};

export type HeaderFormik = {
  aggregationLevel: string;
  segments: string[];
  periodStart: string;
  periodEnd: string;
  timeGranularity: TimeGranularity;
};

export const headerFormikKeys = [
  "aggregationLevel",
  "segments",
  "periodStart",
  "periodEnd",
  "timeGranularity",
] as const;

export type SegmentFiltersModel = HeaderFormik & { filters: Record<string, string[]> };

export const isRangeInput = (value: unknown) =>
  typeof value === "object" &&
  !Array.isArray(value) &&
  !R.isNil(value) &&
  (R.has("min", value) || R.has("max", value));

type ApiFiltersModel = {
  aggregationColumn: string;
  stackbyColumn: string;
  filters: Record<string, string[]>;
  startDate: string;
  endDate: string;
  timeScale: string;
};

export type ApiFiltersConfig = {
  common: Partial<Omit<SegmentModel, "id" | "label" | "layout">>;
  segments: SegmentModel[];
};

export const MARKET_SEGMENT_AGG_LVL = "market_segments";

const sortNumericOptions = (array: FilterProps["values"]) => {
  const collator = new Intl.Collator([], { numeric: true });
  return R.sort((a, b) => collator.compare(a.label, b.label), array);
};

const combineCommonAndSegment =
  (prop: string) =>
  ({ common, segment }: { segment: SegmentModel; common: ApiFiltersConfig["common"] }) => {
    const commonStackBy = R.propOr<{ id: string }[], typeof common, { id: string }[]>(
      [],
      prop,
      common
    );
    const segmentStackBy = R.propOr<{ id: string }[], typeof common, { id: string }[]>(
      [],
      prop,
      segment
    );
    return [...commonStackBy, ...segmentStackBy];
  };

export class SegmentFiltersAdapterClass extends BaseAdapter<SegmentModel> {
  stackByColumnMapper = {
    label: R.prop("label"),
    value: R.prop("id"),
    id: R.prop("id"),
  } as const;

  receivingMapper = {
    filters: R.pipe<any, SegmentModel["filters"], SegmentModel["filters"]>(
      ({ common, segment }: { segment: SegmentModel; common: ApiFiltersConfig["common"] }) => {
        const commonFilters = R.propOr<FilterProps[], typeof common, FilterProps[]>(
          [],
          "filters",
          common
        );
        const segmentFilters = R.propOr<
          Record<string, FilterProps>,
          typeof segment,
          Record<string, FilterProps>
        >({}, "filters", segment);
        const segmentFilterKeys = R.keys(segmentFilters);
        return commonFilters.reduce((acc, curr) => {
          if (!segmentFilterKeys.includes(curr.id)) {
            acc[curr.id] = curr;
          }
          return acc;
        }, Object.assign({ ...segmentFilters }));
      },
      R.mapObjIndexed((option) => {
        if (option.values[0].value.match(/^(-?\d+|\d+\+?|\d+-\d+|\d{1,2}-\d{1,2} yrs)$/gm)) {
          option.values = sortNumericOptions(option.values);
        }
        return option;
      })
    ),
    aggregationColumns: R.path(["segment", "aggregationColumns"]),
    stackByColumns: R.pipe(
      combineCommonAndSegment("stackbyColumns"),
      R.uniqBy(R.prop("id")) as any,
      R.map(this.runMapper(this.stackByColumnMapper))
    ),
    tabs: R.pipe(
      combineCommonAndSegment("tabs"),
      R.uniqBy(R.prop("id")),
      R.sortBy(R.prop("order")),
      R.cond([
        [R.isEmpty, R.always(undefined)],
        [R.T, (data) => data],
      ])
    ),
    layout: R.path(["segment", "layout"]),
    id: R.path(["segment", "id"]),
    label: R.path(["segment", "label"]),
  } as const;

  sendingMapper = {
    aggregationColumn: R.prop("aggregationLevel"),
    stackbyColumn: R.path(["stackBy", "id"]),
    filters: R.pipe<
      any,
      SegmentFiltersModel,
      SegmentFiltersModel["filters"],
      ApiFiltersModel["filters"],
      ApiFiltersModel["filters"]
    >(
      (data) => R.assocPath(["filters", "market_segments"], R.prop("segments", data), data),
      R.prop("filters"),
      R.filter(R.pipe(R.isEmpty, R.not)),
      R.mapObjIndexed((val) => {
        if (isRangeInput(val)) {
          return [R.propOr(0, "min", val), R.propOr(100, "max", val)];
        }
        return val;
      })
    ),
    startDate: R.prop("periodStart"),
    endDate: R.prop("periodEnd"),
    timeScale: R.prop("timeGranularity"),
    area: R.propOr(undefined, "area"),
  } as const;

  toModel(data: ApiFiltersConfig) {
    const { common, segments } = data;
    return segments.reduce<Record<string, SegmentModel>>((acc, curr) => {
      acc[curr.id] = this.runMapper<SegmentModel, unknown>(this.receivingMapper)({
        segment: curr,
        common,
      });
      return acc;
    }, Object.assign({}));
  }

  toApiModel(data: SegmentFiltersModel) {
    return this.runMapper<BaseDataModel>(this.sendingMapper)(data);
  }
}

export const SegmentFiltersAdapter = new SegmentFiltersAdapterClass();
