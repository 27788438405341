import { getCompanyInfo } from "api/company";
import { settingsQueryKeys } from "constants/queryKeys";

import { useQueryData } from "./useQueryData";

export const useCompanyData = () => {
  return useQueryData([settingsQueryKeys.companyProfile], ({ signal }) =>
    getCompanyInfo({ signal })
  );
};
