import React, { lazy, Suspense } from "react";

import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";

import { Alerts } from "components/Alerts";
import { FallbackSpinner } from "components/Spinner";
import * as PATHS from "constants/navigationPaths";
import { useFeatureFlags } from "hooks";
import { MainLayout, HomePageLayout, AuthLayout } from "layouts";

import { RouteBehindFlag } from "./RouteBehindFlag";
import { RouteProtection } from "./RouteProtection";
import { RouteTracker } from "./RouteTracker";

const HomePage = lazy(() => import("pages/HomePage"));

const LoginPage = lazy(() => import("pages/Auth/Login"));
const SignupPage = lazy(() => import("pages/Auth/Signup"));
const SignupFinishedPage = lazy(() => import("pages/Auth/Signup/SignupFinished"));
const ForgotPassPage = lazy(() => import("pages/Auth/Reset/ProvideEmail"));
const ForgotPassEmailSentPage = lazy(() => import("pages/Auth/Reset/ResetEmailSent"));
const ResetPassPage = lazy(() => import("pages/Auth/Reset/ResetPassword"));
const ResetPassFinishedPage = lazy(() => import("pages/Auth/Reset/ResetFinished"));
// const DocumentPage = lazy(() => import("pages/documents/Document"));

const ErrorPage = lazy(() => import("pages/ErrorPage"));

const ProceduresPage = lazy(() => import("pages/MarketIntelligence/Procedures/ProceduresPage"));
const ReadmissionsPage = lazy(() => import("pages/MarketIntelligence/Readmissions"));

const FacilityProfileSearchPage = lazy(() => import("pages/SalesTargeting/FacilityProfileSearch"));
const FacilityMapViewPage = lazy(() => import("pages/SalesTargeting/FacilityMapView"));
const FacilityProfilePage = lazy(() => import("pages/SalesTargeting/FacilityProfile"));
const PhysicianProfileSearchPage = lazy(
  () => import("pages/SalesTargeting/PhysicianProfileSearch")
);
const PhysicianMapViewPage = lazy(() => import("pages/SalesTargeting/PhysicianMapview"));
const PhysicianProfilePage = lazy(() => import("pages/SalesTargeting/PhysicianProfile"));

const OrganizationSettingsPage = lazy(() => import("pages/Settings/Organization"));
const ProfileSettingsPage = lazy(() => import("pages/Settings/Profile"));
const OwnProfileSettingsPage = lazy(() => import("pages/Settings/Profile/OwnProfile"));
const CreateOrgGroupPage = lazy(() => import("pages/Settings/GroupDetails/CreateGroup"));
const EditOrgGroupPage = lazy(() => import("pages/Settings/GroupDetails/EditGroup"));

const SupportLayout = lazy(() => import("pages/Support/SupportLayout"));
const FAQPage = lazy(() => import("pages/Support/FAQ/FAQPage"));
const KeyFeaturePage = lazy(() => import("pages/Support/KeyFeatures/KeyFeaturesPage"));

export const Router = () => {
  const { FF_ORG_SETTINGS_PAGE, FF_SALES_TARGETING, FF_MI_READMISSIONS } = useFeatureFlags();

  const router = createBrowserRouter([
    {
      Component: RouteTracker,
      children: [
        {
          Component: RouteProtection,
          children: [
            {
              Component: AuthLayout,
              children: [
                { path: "/login", element: <Navigate to={PATHS.loginPath} /> },
                { path: PATHS.loginPath, Component: LoginPage },
                { path: PATHS.signupPath, Component: SignupPage },
                { path: PATHS.signupFinishedPath, Component: SignupFinishedPage },
                { path: PATHS.forgotPasswordPath, Component: ForgotPassPage },
                { path: PATHS.forgotPasswordEmailSendPath, Component: ForgotPassEmailSentPage },
                { path: PATHS.resetPasswordPath, Component: ResetPassPage },
                { path: PATHS.resetPasswordFinishedPath, Component: ResetPassFinishedPage },
              ],
            },
            {
              Component: HomePageLayout,
              children: [{ path: PATHS.mainPath, Component: HomePage }],
            },
            {
              Component: MainLayout,
              children: [
                // Market Intelligence
                { path: PATHS.proceduresPath, Component: ProceduresPage },
                {
                  element: <RouteBehindFlag enabled={FF_MI_READMISSIONS} />,
                  children: [{ path: PATHS.readmissionsPath, Component: ReadmissionsPage }],
                },

                // Sales Targeting
                {
                  element: <RouteBehindFlag enabled={FF_SALES_TARGETING} />,
                  children: [
                    { path: PATHS.hospitalTargetingPath, Component: FacilityMapViewPage },
                    { path: PATHS.hospitalProfilePath, Component: FacilityProfilePage },
                    { path: PATHS.hospitalSegmentationPath, Component: FacilityProfileSearchPage },
                    { path: PATHS.physicianTargetingPath, Component: PhysicianMapViewPage },
                    { path: PATHS.physicianProfilePath, Component: PhysicianProfilePage },
                    {
                      path: PATHS.physicianSegmentationPath,
                      Component: PhysicianProfileSearchPage,
                    },
                  ],
                },

                // Settings
                {
                  element: <RouteBehindFlag enabled={FF_ORG_SETTINGS_PAGE} />,
                  children: [
                    { path: PATHS.settingsMyProfilePath, Component: OwnProfileSettingsPage },
                    { path: PATHS.settingsUserProfilePath, Component: ProfileSettingsPage },
                    { path: PATHS.settingsOrgPath, Component: OrganizationSettingsPage },
                    { path: PATHS.settingsOrgAddGroupPath, Component: CreateOrgGroupPage },
                    { path: PATHS.settingsOrgGroupPath, Component: EditOrgGroupPage },
                  ],
                },

                {
                  Component: SupportLayout,
                  children: [
                    { path: PATHS.supportFAQSPath, Component: FAQPage },
                    { path: PATHS.supportKeyFeaturesPath, Component: KeyFeaturePage },
                  ],
                },
              ],
            },
          ],
        },
        { path: PATHS.errorPath, Component: ErrorPage },
        { path: "*", Component: ErrorPage },
      ],
    },
  ]);

  return (
    <>
      <Alerts />
      <Suspense fallback={<FallbackSpinner />}>
        <RouterProvider router={router} />
      </Suspense>
    </>
  );
};
