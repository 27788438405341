import * as R from "ramda";

import { BaseDataModel } from "types";

import { BaseAdapter } from "../BaseAdapter";

export type PhysicianSearchFiltersModel = {
  name: string;
  npi: string;
};

export type PhysicianSearchTableModel = {
  name: string;
  npi: string;
  address: string;
  specialty: string;
};

class PhysicianProfileSearchAdapterClass extends BaseAdapter<PhysicianSearchTableModel> {
  receivingMapper = {
    name: R.prop("fullName"),
    npi: R.prop("npi"),
    address: R.prop("address"),
    specialty: R.prop("specialty"),
  };

  sendingMapper = {
    npi: R.propOr(undefined, "npi"),
    query: R.propOr(undefined, "name"),
  };

  toModel(item: BaseDataModel[]) {
    return R.map(this.runMapper<PhysicianSearchTableModel>(this.receivingMapper), item);
  }

  toApiModel(item: BaseDataModel) {
    return this.runMapper<PhysicianSearchFiltersModel>(this.sendingMapper)(item);
  }
}

export const PhysicianProfileSearchAdapter = new PhysicianProfileSearchAdapterClass();
