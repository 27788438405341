import { AxiosRequestConfig } from "axios";

import {
  AllowedUserRole,
  Company,
  CompanyAdapter,
  CompanyGroupCreate,
  CompanyGroupsAdapter,
  CompanyGroupUpdate,
  CompanyStaffAdapter,
  UserProfileAdapter,
  UserProfileModel,
} from "adapters";
import { EditPermissionsFormik } from "pages/Settings/Profile/EditPermissions";
import { BaseDataModel } from "types";
import ApiService from "utils/apiService";

export const getCompanyInfo = (options?: AxiosRequestConfig) =>
  ApiService.get<BaseDataModel>("/tenants", undefined, options).then((res) =>
    CompanyAdapter.toModel(res)
  );

export const updateCompanyInfo = (data: Company["details"]) =>
  ApiService.put("/tenants", CompanyAdapter.toApiModel(data));

export const updateCompanyLogo = (orgID: string, data: FormData) =>
  ApiService.post(`/tenants/${orgID}/assets`, data);

export const getCompanyUsers = (options?: AxiosRequestConfig) =>
  ApiService.get<BaseDataModel[]>("/tenants/users", undefined, options).then((res) =>
    CompanyStaffAdapter.toModel(res)
  );

export const getCompanyGroups = (options?: AxiosRequestConfig) =>
  ApiService.get<BaseDataModel[]>("/tenants/groups", undefined, options).then((data) =>
    CompanyGroupsAdapter.toModel(data)
  );

export const getCompanyGroup = (id: string, options?: AxiosRequestConfig) =>
  ApiService.get<BaseDataModel>(`/tenants/groups/${id}`, undefined, options)
    .then((data) => CompanyGroupsAdapter.toModel([data]))
    .then((data) => data?.[0]);

export const createCompanyGroup = (data: CompanyGroupCreate) =>
  ApiService.post("/tenants/groups", CompanyGroupsAdapter.toCreateModel(data));

export const updateCompanyGroup = (data: CompanyGroupUpdate) =>
  ApiService.put("/tenants/groups", CompanyGroupsAdapter.toUpdateModel(data));

export const removeCompanyGroup = (id: number) => ApiService.delete(`/tenants/groups/${id}`);

export const removeUserFromGroup = (groupID: string, userID: number) =>
  ApiService.delete(`/tenants/groups/${groupID}/users/${userID}`);

export const getUserProfile = (userId: number, options?: AxiosRequestConfig) =>
  ApiService.get<BaseDataModel>(`/tenants/users/${userId}`, undefined, options).then((data) =>
    UserProfileAdapter.toModel(data)
  );

export const toggleUserStatus = (userId: number, data: { enabled: boolean }) =>
  ApiService.put(`/tenants/users/${userId}/status`, data);

export const updateUserAvatar = (userId: number, data: FormData) =>
  ApiService.post(`/tenants/users/${userId}/assets`, data);

export const updateUserProfile = (userId: number, data: UserProfileModel["details"]) =>
  ApiService.put(`/tenants/users/${userId}`, UserProfileAdapter.toApiModel(data));

export const updateUserPermissions = (userId: number, data: EditPermissionsFormik) =>
  ApiService.put(`/tenants/users/${userId}/permissions`, data);

export const inviteUsers = (data: { invites: { email: string; role: AllowedUserRole }[] }) =>
  ApiService.post("/tenants/invites", data);
