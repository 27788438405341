import React from "react";

import {
  assocPath,
  dissocPath,
  filter,
  find,
  isEmpty,
  isNil,
  keys,
  omit,
  pipe,
  pluck,
  prop,
  sort,
} from "ramda";

import {
  headerFormikKeys,
  SegmentFiltersAdapter,
  SegmentFiltersAdapterClass,
  SegmentFiltersModel,
} from "adapters";
import { FilterPill } from "components/FiltersComponents/FilterPill";
import { FiltersPills, PillsFilters } from "components/FiltersComponents/FiltersPills";
import { PageId } from "constants/pageIds";
import { useCombinedSegmentsConfig, useSegmentFilters, useSegments } from "hooks";
import { updateArray } from "utils";

export const useFiltersPills = <A extends SegmentFiltersAdapterClass>(
  appliedFilters: SegmentFiltersModel | undefined,
  onApply: (filters: SegmentFiltersModel) => void,
  pageId: PageId,
  Adapter: A = SegmentFiltersAdapter as any
) => {
  const { data: segments } = useSegments(pageId);

  const segmentsProps = segments?.filter((segment) =>
    appliedFilters?.segments?.includes(segment.id)
  );

  const { data } = useSegmentFilters(pageId, pluck("id", segmentsProps || []), Adapter);

  const config =
    isNil(appliedFilters) || isNil(data)
      ? undefined
      : useCombinedSegmentsConfig(appliedFilters?.segments || [], data as any);

  const renderPills = (omitKeys: string[] = [], className?: string) => {
    if (isNil(appliedFilters) || isNil(config)) return null;

    const generalFilters = pipe<
      any,
      Record<string, string[]>,
      Record<string, string[]>,
      PillsFilters
    >(prop("filters"), omit([...headerFormikKeys, ...omitKeys]), (appliedData) => {
      const dataKeys = pipe(
        keys,
        sort((a: string, b: string) =>
          config.aggregationColumns.includes(a)
            ? -1
            : config.aggregationColumns.includes(b)
            ? 1
            : a.localeCompare(b)
        )
      )(appliedData);
      return dataKeys.reduce((acc, key: keyof typeof appliedData) => {
        const appliedValue = appliedData[key];
        if (isEmpty(appliedValue)) {
          return acc;
        }
        const filterProps = config.filters[key];
        const isSingle = filterProps.type === "SINGLE_SELECT";

        if (isSingle) {
          acc[key] = find(({ value }) => value === appliedValue[0], filterProps.values)!;
        } else {
          acc[key] = filter(({ value }) => appliedValue.includes(value), filterProps.values);
        }

        return acc;
      }, Object.assign({}) as PillsFilters);
    })(appliedFilters);

    if (isNil(appliedFilters)) return null;

    return (
      <span className={className}>
        <label>Active Filters:</label>
        {segmentsProps?.map((segment) => (
          <FilterPill label={segment.label} permanent key={segment.id} />
        ))}
        <FiltersPills
          appliedFilters={generalFilters}
          handlePillRemove={(id, opt) => {
            const newValues = updateArray<string>(appliedFilters?.filters[id], opt.value);
            const filtersToApply = isEmpty(newValues)
              ? dissocPath<typeof appliedFilters>(["filters", id], appliedFilters)
              : assocPath(["filters", id], newValues, appliedFilters);

            onApply(filtersToApply);
          }}
        />
      </span>
    );
  };

  return { renderPills };
};
