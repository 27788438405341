import { useEffect } from "react";

import {
  QueryFunction,
  QueryKey,
  useQuery,
  useQueryErrorResetBoundary,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import { useQueryError } from "hooks/useQueryError";

export const useQueryData = <TQueryFnData = unknown, TError = unknown, TData = TQueryFnData>(
  queryKey: QueryKey,
  queryFn: QueryFunction<TQueryFnData>,
  options?: UseQueryOptions<TQueryFnData, TError & AxiosError, TData>
) => {
  const { handleError } = useQueryError();
  const { reset } = useQueryErrorResetBoundary();
  const { data, isFetching, error, isError } = useQuery<TQueryFnData, TError & AxiosError, TData>(
    queryKey,
    queryFn,
    options
  );

  useEffect(() => {
    if (isError && !!error) {
      handleError(error);
      reset();
    }
  }, [isError, error]);

  return { data, isLoading: isFetching, isError };
};
