export const settingsQueryKeys = {
  dateOptions: "dateOptions",

  companyProfile: "company",
  companyGroups: "companyGroups",
  companyGroup: "companyGroup",
  companyUsers: "companyUsers",

  currentUser: "currentUser",
  userProfile: "profile",
} as const;
