import { map } from "ramda";

import { BaseDataModel, IAdapter, Mapper } from "types";

export class BaseAdapter<ResultModel> implements Partial<IAdapter<ResultModel>> {
  receivingMapper = {} as const;

  runMapper<ResultModel, DataModel = BaseDataModel>(mapper: Record<keyof DataModel, any>) {
    return (data: DataModel) => map<Mapper, ResultModel>((fn) => fn(data), mapper);
  }
}
