import * as R from "ramda";

import { BaseDataModel } from "types";

import { SegmentFiltersAdapterClass, SegmentModel } from "../SegmentFilters";

export type ProceduresPanelTab = {
  label: string;
  id: string;
  title: string;
  description?: string;
  showPredictions: boolean;
  type: "REVENUE" | "COUNTS" | "STACKED_COUNTS";
  stackingColumn: string;
  hideTable?: boolean;
};

export type ProceduresSegmentModel = SegmentModel & {
  tabs: ProceduresPanelTab[];
};

class ProceduresFiltersAdapterClass extends SegmentFiltersAdapterClass {
  newReceivingMapper = {
    ...this.receivingMapper,
    tabs: R.prop("tabs"),
  } as const;

  // toModel(data: BaseDataModel) {
  //   return this.runMapper<ProceduresSegmentModel>(this.newReceivingMapper)(data);
  // }
}

export const ProceduresFiltersAdapter = new ProceduresFiltersAdapterClass();
