import React, { useContext } from "react";

import { Dropdown, MenuProps } from "antd";
import { drop, insert } from "ramda";
import { Link, useNavigate } from "react-router-dom";

import { LogoutSVG, PersonCircleSVG } from "assets/icons";
import { loginPath, settingsOrgPath, settingsMyProfilePath } from "constants/navigationPaths";
import { ConfigContext } from "context/ConfigContext";
import { useCurrentUser, useFeatureFlags, useAuthenticate } from "hooks";

import styles from "./UserMenu.module.scss";

export const UserMenu = () => {
  const { FF_ORG_SETTINGS_PAGE } = useFeatureFlags();
  const { logout } = useAuthenticate();
  const { data: userData, isSuperUser, isAdmin } = useCurrentUser();
  const navigate = useNavigate();
  const { API_URL } = useContext(ConfigContext);

  let items: MenuProps["items"] = [
    {
      label: <Link to={settingsMyProfilePath}>My Profile</Link>,
      key: 0,
      className: styles.userMenuItem,
    },
    { type: "divider" },
    {
      label: (
        <a
          onClick={(e) => {
            e.preventDefault();
            logout(() => {
              navigate(loginPath);
              navigate(0);
            });
          }}
          role='logout'
        >
          Logout <LogoutSVG />
        </a>
      ),
      key: 2,
      className: styles.userMenuItem,
    },
  ];

  if (isSuperUser() || isAdmin()) {
    items = insert(
      1,
      {
        label: <Link to={settingsOrgPath}>Organization Profile</Link>,
        key: 1,
        className: styles.userMenuItem,
      },
      items
    );
  }

  if (!FF_ORG_SETTINGS_PAGE) {
    items = drop(items.length - 1, items);
  }

  return (
    <Dropdown menu={{ items }} placement='bottomRight'>
      {userData?.details.avatar ? (
        <img
          src={`${API_URL}${userData.details.avatar}`}
          alt='avatar'
          className={styles.userMenuImage}
          role='userMenu'
        />
      ) : (
        <PersonCircleSVG role='userMenu' className={styles.userMenuDefaultIcon} />
      )}
    </Dropdown>
  );
};
