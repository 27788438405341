import React, { useEffect, useState } from "react";

import { flatten, isEmpty, keys, map } from "ramda";

import { BaseOptionProps } from "types";
import { capitalize, makeSingular, splitCamelCase, splitSnakeCase } from "utils";

import { FilterPill } from "../FilterPill";

export type PillsFilters = Record<string, BaseOptionProps | BaseOptionProps[]>;

type Props = {
  appliedFilters: PillsFilters;
  handlePillRemove: (field: string, item: BaseOptionProps) => void;
};

export const FiltersPills = ({ appliedFilters, handlePillRemove }: Props) => {
  const [pills, setPills] = useState<{ label: string; onClose: () => void }[]>([]);

  useEffect(() => {
    if (isEmpty(appliedFilters)) {
      setPills([]);
      return;
    }

    const newPills = flatten(
      map((key) => {
        const value = appliedFilters[key];
        if (Array.isArray(value)) {
          return map((item) => {
            const pillLabel = `${makeSingular(capitalize(splitCamelCase(splitSnakeCase(key))))}: ${
              item.label
            }`;
            return {
              label: pillLabel,
              onClose: () => {
                handlePillRemove(key, item);
              },
            };
          }, value);
        } else {
          return {
            label: value.label,
            onClose: () => {
              handlePillRemove(key, value);
            },
          };
        }
      }, keys(appliedFilters))
    );
    setPills(newPills);
  }, [appliedFilters]);

  return (
    <>
      {map(
        (args) => (
          <FilterPill {...args} key={args.label} />
        ),
        pills
      )}
    </>
  );
};
