export type BaseOptionProps<T = string> = {
  value: T;
  label: string;
};

export type OptionProps = BaseOptionProps & {
  key?: string;
  info?: string;
};

export enum TimeGranularity {
  MONTHLY = "monthly",
  QUARTERLY = "quarterly",
  YEARLY = "yearly",
}
