import { prop } from "ramda";
import { generatePath } from "react-router-dom";

import { breadcrumbsMap } from "constants/breadcrumbsMap";
import {
  settingsUserProfilePath,
  physicianProfilePath,
  hospitalProfilePath,
  settingsOrgGroupPath,
  errorPath,
  supportFAQSPath,
  supportKeyFeaturesPath,
} from "constants/navigationPaths";

export const createEditUserPath = (userID: string) =>
  generatePath(settingsUserProfilePath, { userID });

export const createEditOrgGroupPath = (groupID: string) =>
  generatePath(settingsOrgGroupPath, { groupID });

export const createPhysicianProfilePath = (npi: string) =>
  generatePath(physicianProfilePath, { npi });

export const createHospitalProfilePath = (npi: string) =>
  generatePath(hospitalProfilePath, { npi });

export const createErrorPagePath = (code?: number) => generatePath(errorPath, { code: `${code}` });

export const createFAQPath = (slug: string) => generatePath(supportFAQSPath, { slug });

export const createKeyFeaturePath = (slug: string) =>
  generatePath(supportKeyFeaturesPath, { slug });

export const getBreadcrumbs = (path: string) => prop(path, breadcrumbsMap);
