import React from "react";

import { Outlet, Navigate } from "react-router-dom";

import { mainPath } from "constants/navigationPaths";

type Props = {
  enabled: boolean;
};

export const RouteBehindFlag = ({ enabled }: Props) => {
  if (!enabled) return <Navigate to={mainPath} />;

  return <Outlet />;
};
