import { exportDateFormat } from "constants/dateFormat";
import { formatDate } from "utils";

export const blobExport = (filename: string, data: Blob, fileExtension: string) => {
  const anchor = document.createElement("a");
  anchor.download = `${filename}_${formatDate(null, exportDateFormat)}.${fileExtension}`;
  anchor.href = (window.webkitURL || window.URL).createObjectURL(data);
  anchor.click();
  anchor.remove();
};
