import React from "react";

import { Flex, Layout } from "antd";
import { Link } from "react-router-dom";

import { AxiomHealthLogoSVG, HelpSVG } from "assets/icons";
import { TooltipWrapper } from "components/TooltipWrapper";
import { UserMenu } from "components/UserMenu";
import { mainPath } from "constants/navigationPaths";
import { createFAQPath } from "utils/navigation";

import { NavMenu } from "./NavMenu";

import styles from "./Header.module.scss";

const { Header: HeaderAntD } = Layout;

export const Header = () => {
  return (
    <HeaderAntD className={styles.headerContainer}>
      <div className={styles.logoContainer}>
        <Link
          to={mainPath}
          data-testid='navbar_logo'
          onKeyDown={(e) => {
            if (e.key === " ") {
              e.preventDefault();
              e.currentTarget.click();
            }
          }}
        >
          <AxiomHealthLogoSVG className={styles.logo} />
        </Link>
      </div>
      <Flex flex={1}>
        <NavMenu />
      </Flex>
      <Flex gap='1rem' align='center' className={styles.actionsBar}>
        <div>
          <TooltipWrapper tooltip='Support' placement='bottom'>
            <Link to={createFAQPath("0")} target='_blank'>
              <HelpSVG className={styles.actionsIcon} />
            </Link>
          </TooltipWrapper>
        </div>
        <UserMenu />
      </Flex>
    </HeaderAntD>
  );
};
