import axios, { AxiosRequestConfig } from "axios";
import { assocPath, clone } from "ramda";

import { BaseDataModel } from "types";

export const axiosInst = axios.create({
  headers: {
    post: {
      "Content-Type": "application/json",
    },
  },
});

export type ParamsType = Record<string, number | string | (string | number)[]>;
export type HeadersType = Record<string, string>;

class ApiService {
  paramsToString = (params: ParamsType) => {
    const searchParams = new URLSearchParams(params as any);
    return searchParams.toString();
  };

  checkBaseURL = (path: string) => {
    return /^https?:\/\//.test(path) || Boolean(axiosInst.defaults.baseURL);
  };

  updateRequestOptions = (options: AxiosRequestConfig, data: BaseDataModel | FormData) => {
    let result = clone(options);
    if (data instanceof FormData) {
      result = assocPath(["headers", "Content-Type"], "multipart/form-data", result);
    }
    return result;
  };

  get = <R>(path: string, params: ParamsType = {}, options: AxiosRequestConfig = {}) => {
    if (!this.checkBaseURL(path)) return Promise.reject<R>();
    const url = `${path}?${this.paramsToString(params)}`;
    return axiosInst.get<R>(url, options).then((res) => res.data);
  };

  post = <R>(
    path: string,
    data: BaseDataModel | FormData = {},
    options: AxiosRequestConfig = {}
  ) => {
    if (!this.checkBaseURL(path)) return Promise.reject<R>();
    const reqOptions = this.updateRequestOptions(options, data);
    return axiosInst.post<R>(path, data, reqOptions).then((res) => res.data);
  };

  put = <R>(
    path: string,
    data: BaseDataModel | FormData = {},
    options: AxiosRequestConfig = {}
  ) => {
    if (!this.checkBaseURL(path)) return Promise.reject<R>();
    const reqOptions = this.updateRequestOptions(options, data);
    return axiosInst.put<R>(path, data, reqOptions).then((res) => res.data);
  };

  delete = <R>(path: string, options: AxiosRequestConfig = {}) => {
    return axiosInst.delete<R>(path, options).then((res) => res.data);
  };
}

export default new ApiService();
