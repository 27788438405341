import * as R from "ramda";

import { BaseDataModel } from "types";

import { BaseAdapter } from "../BaseAdapter";

export type FacilityMapPanelsModel = {
  facilitiesCount: number;
  trend: number;
  avgProceduresPerUnit: number;
  avgProceduresPerYear: number;
};

const retrieveData = (key: string) =>
  R.pipe(R.pathOr("", ["info", key]), parseFloat, (data) => (isNaN(data) ? null : data));

class FacilityMapInfoPanelAdapterClass extends BaseAdapter<FacilityMapPanelsModel> {
  receivingMapper = {
    facilitiesCount: retrieveData("facilitiesCount"),
    trend: retrieveData("trend"),
    avgProceduresPerUnit: retrieveData("avgProceduresPerUnit"),
    avgProceduresPerYear: retrieveData("avgProceduresPerYear"),
  };

  toModel(item: BaseDataModel) {
    return this.runMapper<FacilityMapPanelsModel>(this.receivingMapper)(item);
  }
}

export const FacilityMapInfoPanelAdapter = new FacilityMapInfoPanelAdapterClass();
