import * as R from "ramda";

import { BaseDataModel, OptionProps } from "types";

import { BaseAdapter } from "../BaseAdapter";

export type CityOption = OptionProps & { states: string[] };

export type FacilityProfileSearchOptions = {
  cities: CityOption[];
  divisions: OptionProps[];
  gpos: OptionProps[];
  regions: OptionProps[];
  states: OptionProps[];
};

export type FacilitySearchFiltersModel = {
  name: string;
  npi: string;
};

class FacilityProfileSearchAdapterClass extends BaseAdapter<any> {
  sendingMapper = {
    npi: R.propOr(undefined, "npi"),
    query: R.propOr(undefined, "name"),
  };

  toApiModel(item: BaseDataModel) {
    return this.runMapper<FacilitySearchFiltersModel>(this.sendingMapper)(item);
  }
}

export const FacilityProfileSearchAdapter = new FacilityProfileSearchAdapterClass();
