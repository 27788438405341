import { isNil } from "ramda";

export const numberRegEx = /^-?\d+(\.\d+)?/;

export const insertCommasIntoNumber = (value: string) => {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatNumberWithCommas = (number: number, decimal = 2) => {
  return insertCommasIntoNumber((number || 0).toFixed(decimal));
};

export const toFixedNoRound = (value: number, decimal = -1) => {
  const re = new RegExp(`^-?\\d+(?:\.\\d{0,${decimal}})?`);
  const valMatch = value.toString().match(re);
  if (!valMatch) return "";
  return valMatch[0];
};

export const formatNumberWithMagnitude = (value: number, decimal = 0) => {
  if ((!value && value !== 0) || Number(value) !== value) return value;
  if (Math.abs(value) >= 1000000000)
    return `${insertCommasIntoNumber((value / 1000000).toFixed(decimal))}M`;
  if (Math.abs(value) >= 1000) return `${insertCommasIntoNumber((value / 1000).toFixed(decimal))}k`;
  return Number.isInteger(value) ? `${value}` : value.toFixed(decimal);
};

export type NumberMagnitude = "" | "k" | "M" | "B";
export const denominatorMap: Record<NumberMagnitude, number> = {
  "": 1,
  k: 1000,
  M: 1000000,
  B: 1000000000,
};
export const formatNumberToMagnitude = (value: number, magnitude: NumberMagnitude, decimal = 0) => {
  if (isNil(value) || isNaN(value)) return "-";

  const denominator = denominatorMap[magnitude];

  if (denominator === 1 && Number.isInteger(value)) return insertCommasIntoNumber(`${value}`);

  return `${insertCommasIntoNumber((value / denominator).toFixed(decimal))}${magnitude}`;
};

export const fitInRange = (value: number, min: number, max: number) =>
  Math.max(min, Math.min(max, value));
