import { UseQueryOptions } from "@tanstack/react-query";
import { isNil, pick } from "ramda";

import { SegmentFiltersAdapter, SegmentFiltersAdapterClass, SegmentModel } from "adapters";
import { getSegmentFilters } from "api/segments";
import { PageId, pageIdMap } from "constants/pageIds";

import { useQueryData } from "./useQueryData";

export const useSegmentFilters = <A extends SegmentFiltersAdapterClass>(
  pageId: PageId,
  segments: string[] | undefined,
  Adapter: A = SegmentFiltersAdapter as any,
  options?: UseQueryOptions<Record<string, SegmentModel>, any>
) => {
  const page = pageIdMap[pageId];

  const { data, isLoading } = useQueryData(
    [`${pageId}_segmentFilters`, pageId, page.apiFiltersPath],
    ({ signal }) => getSegmentFilters(page.apiFiltersPath, { signal }, Adapter),
    options
  );

  return {
    data: isNil(data) || isNil(segments) ? undefined : pick(segments, data),
    isLoading,
  };
};
