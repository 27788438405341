import { curry, filter, flatten, has, intersection, isEmpty, keys, map, pipe, prop } from "ramda";

import { ProcedureItem, ProceduresOptions } from "adapters";
import {
  DiagnosisCategory,
  DiagnosisItem,
  DropdownHeaderType,
  FunctionType,
  ProcedureCategories,
} from "types";
import { updateArray } from "utils/arrays";
import { checkIfFieldIncludesValue } from "utils/filters";

import { capitalize } from "./textFormat";

export const addDiagnosisKey = (item: Partial<DiagnosisItem>) => {
  const keyValue = `${item.marketSegments ? `${item.marketSegments}_` : ""}${
    item.locations ? `${item.locations}_` : ""
  }${item.categories ? `${item.categories}_` : ""}${item.label}`;
  return { ...item, value: keyValue } as DiagnosisItem;
};

const adjustProceduresLowerFilters = <C extends string>(
  category: C,
  filters: { [key: string]: unknown[] },
  selectedFilters: { [key: string]: unknown[] },
  filtersData: { [key: string]: unknown[] },
  filterOut: FunctionType<any, (ProcedureItem | DiagnosisItem)[]>
) => {
  const isNewValuesEmpty = isEmpty(filters[category]);
  switch (category) {
    case ProcedureCategories.MARKET_SEGMENT: {
      filters.procedureCategory = isNewValuesEmpty
        ? []
        : filterOut(selectedFilters.procedureCategory, filtersData.procedureCategory);
    }
    case ProcedureCategories.PROCEDURE_CATEGORY: {
      filters.procedure = isNewValuesEmpty
        ? []
        : filterOut(selectedFilters.procedure, filtersData.procedure);
      break;
    }
    case DiagnosisCategory.MARKET_SEGMENT: {
      if (has(DiagnosisCategory.LOCATION, selectedFilters)) {
        filters.diagnosisLocation = isNewValuesEmpty
          ? []
          : filterOut(
              selectedFilters?.diagnosisLocation || [],
              filtersData?.diagnosisLocation || []
            );
      }
    }
    case DiagnosisCategory.LOCATION: {
      filters.diagnosisCategory = isNewValuesEmpty
        ? []
        : filterOut(selectedFilters.diagnosisCategory, filtersData.diagnosisCategory);
    }
    case DiagnosisCategory.CATEGORY: {
      filters.diagnosis = isNewValuesEmpty
        ? []
        : filterOut(selectedFilters.diagnosis, filtersData.diagnosis);
      break;
    }
  }
  return filters;
};

const defaultFilterOutFieldMap = {
  [ProcedureCategories.MARKET_SEGMENT]: "marketSegment",
  [ProcedureCategories.PROCEDURE_CATEGORY]: "category",
  [DiagnosisCategory.MARKET_SEGMENT]: "marketSegments",
  [DiagnosisCategory.LOCATION]: "locations",
  [DiagnosisCategory.CATEGORY]: "categories",
};

export const handleProcedureSelect = curry(
  <
    D extends { [key: string]: ProcedureItem[] | DiagnosisItem[] } = ProceduresOptions,
    C extends string = ProcedureCategories
  >(
    filtersData: D,
    selectedFilters: D,
    category: C,
    value: ProcedureItem | DiagnosisItem | null,
    filterOutFieldMap: Record<string, string> = defaultFilterOutFieldMap,
    adjustLowerFilters = adjustProceduresLowerFilters
  ) => {
    let newValues: (ProcedureItem | DiagnosisItem)[] = [];
    if (!!value) {
      newValues = updateArray(selectedFilters[category], value, "value");
    }

    const newFilters = {
      [category]: newValues,
    };

    const filterOut = (
      selected: ProcedureItem[] | DiagnosisItem[],
      filters: ProcedureItem[] | DiagnosisItem[]
    ) =>
      pipe<any[], ProcedureItem[] | DiagnosisItem[], ProcedureItem[] | DiagnosisItem[]>(
        filter(
          checkIfFieldIncludesValue(filterOutFieldMap[category], map(prop("label"), newValues))
        ),
        intersection(selected)
      )(filters);

    return adjustLowerFilters(category, newFilters, selectedFilters, filtersData, filterOut);
  }
);

export const prepareDividedOptions = (
  options: ProcedureItem[],
  key: keyof ProcedureItem
): (ProcedureItem | DropdownHeaderType)[] => {
  const dividedOptions = options.reduce<Record<string, ProcedureItem[]>>((acc, curr) => {
    const divider = `${curr[key]}`;
    if (!divider) return acc;
    if (!acc[divider]) {
      acc[divider] = [];
    }
    acc[divider].push(curr);
    return acc;
  }, Object.assign({}));
  const dividers = keys(dividedOptions);

  return flatten(
    dividers
      .sort()
      .map((key) => [
        { isHeader: true, label: capitalize(key.toLowerCase()) },
        ...dividedOptions[key],
      ])
  ) as any;
};
