import * as R from "ramda";

import {
  FacilityDetailsAdapter,
  FacilityDetailsAdapterClass,
  FacilityDetailsModel,
} from "adapters/facilityProfile";
import { PhysicianDetailsItem } from "api/physicianProfile";
import { DataSeriesModel, Mapper } from "types";
import { splitSnakeCase } from "utils";

export type PhysicianDetailsModel = Record<string, FacilityDetailsModel & { name: string }>;

class PhysicianDetailsAdapterClass extends FacilityDetailsAdapterClass {
  newReceivingMapper = {
    ...this.receivingMapper,
    series: R.pipe<any, DataSeriesModel[], DataSeriesModel[], DataSeriesModel[]>(
      R.prop("dataSeries"),
      R.map((item) => ({ ...item, name: splitSnakeCase(item.name) })),
      R.sortBy(R.prop("total"))
    ),
    tableData: (item: any) => {
      const headers = R.pipe(R.propOr({}, "data"), R.keys)(item.totals);
      const rows = R.map(
        (row: DataSeriesModel) => ({
          cells: [row.name, ...R.map((header) => R.pathOr(null, ["data", header], row), headers)],
        }),
        item.dataSeries
      );
      rows.push({
        isBold: true,
        cells: [
          "Totals",
          ...R.map((header) => R.pathOr(null, ["data", header], item.totals), headers),
        ],
      } as any);
      return {
        headers,
        rows,
      };
    },
    name: R.prop("name"),
  };

  toModel(item: unknown) {
    return (item as any).dataSeriesGroups.reduce(
      (acc: Record<string, FacilityDetailsModel>, curr: PhysicianDetailsItem) => {
        acc[curr.name.toUpperCase()] = R.map<Mapper, FacilityDetailsModel>(
          (fn) => fn(curr),
          this.newReceivingMapper
        );
        return acc;
      },
      Object.assign({})
    );
  }
}

export const PhysicianDetailsAdapter = new PhysicianDetailsAdapterClass();
