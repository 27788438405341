import ApiService from "utils/apiService";

export type DateOptionsResponseProps = {
  dataReceivedDate: string;
  dataReceivedEpoch: number;
  lastPipelineRunDate: string;
  lastPipelineRunEpoch: number;
  monthlyCutoffDate: string;
  monthlyCutoffString: string;
  quarterlyCutoffDate: string;
  quarterlyCutoffString: string;
  yearlyCutoffDate: string;
  yearlyCutoffString: string;
  startDate: string;
  endDate: string;
};

export const getDateOptions = () => ApiService.get<DateOptionsResponseProps>("/options/dates");
