import { useCallback, useState } from "react";

import { isNil } from "ramda";

export const useRefCallback = <T>(initial: T | null = null): [T | null, (v: T) => void] => {
  const [node, setNode] = useState<T | null>(initial);
  const setRef = useCallback((el: T) => {
    if (!isNil(el)) setNode(el);
  }, []);

  return [node, setRef];
};
