import { find, values } from "ramda";

import { ObjectValues } from "types";

export const appModules = {
  MI: { id: "market_intelligence.procedures", path: "/market-intelligence/procedures" },
  MI_R: { id: "market_intelligence.readmissions", path: "/market-intelligence/readmissions" },
  ST_F: { id: "targeting.hospitals", path: "/sales-targeting/facility" },
  ST_P: { id: "targeting.physicians", path: "/sales-targeting/physician" },
  INTERNAL: { id: "internal" },
} as const;

export type AppModule = ObjectValues<typeof appModules>;

const appModulesValues = values(appModules);
export const getModuleFromPath = (path: string): AppModule => {
  const pathModule = find(
    (module) => module.id !== "internal" && path.includes(module.path),
    appModulesValues
  );

  return pathModule || appModules.INTERNAL;
};
