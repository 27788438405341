import { getCompanyUsers } from "api/company";
import { settingsQueryKeys } from "constants/queryKeys";

import { useQueryData } from "./useQueryData";

export const useCompanyUsers = () => {
  return useQueryData([settingsQueryKeys.companyUsers], ({ signal }) =>
    getCompanyUsers({ signal })
  );
};
