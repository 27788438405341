import * as R from "ramda";

import { BaseDataModel } from "types";

import { BaseAdapter } from "../BaseAdapter";
import { SegmentFiltersAdapter, SegmentFiltersModel } from "../SegmentFilters";

class ReadmissionFiltersAdapterClass extends BaseAdapter<BaseDataModel> {
  sendingMapper = {
    ...SegmentFiltersAdapter.sendingMapper,
    readmissionType: R.path(["readmissionType", 0]),
    startDate: R.always(undefined),
    endDate: R.always(undefined),
    timeScale: R.always(undefined),
  } as const;

  toApiModel(data: SegmentFiltersModel) {
    return this.runMapper<BaseDataModel>(this.sendingMapper)(data);
  }
}

export const ReadmissionFiltersAdapter = new ReadmissionFiltersAdapterClass();
