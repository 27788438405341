import React, { KeyboardEvent } from "react";

import { Form, InputNumber } from "antd";
import cn from "classnames";
import { FormikProps } from "formik";
import { pathOr } from "ramda";

import styles from "./RangeInput.module.scss";

export type RangeInputProps = {
  id: string;
  label: string;
  formik: FormikProps<Record<string, { min: string; max: string }>>;
  min?: number;
  max?: number;
};

export const checkRangeInputValid = (value: { min: string; max: string }) => {
  if (!value) return false;
  const min = parseInt(value.min);
  const max = parseInt(value.max);
  return !((min || min === 0) && (max || max === 0) && max < min);
};

export const RangeInput = ({ label, min = 0, max = 100, formik, id }: RangeInputProps) => {
  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    ["e", "E", "+", "-", ".", ","].includes(e.key) && e.preventDefault();
  };

  const onChange = (prop: "min" | "max") => (value: number | null) => {
    if (!value) {
      formik.setValues((prev) => {
        return {
          ...prev,
          [id]: { ...prev[id], [prop]: "0" },
        };
      });
      return;
    }
    const valueStr = String(value);
    let newValue = valueStr.replace(/^00$/, "0");
    if (newValue[0] === "0" && value > 0) {
      newValue = newValue.replace(/^0+/, "");
    }
    formik.setValues((prev) => {
      if (value > 100 || value < 0 || !/^\d*$/.test(valueStr)) {
        return { ...prev };
      } else {
        return {
          ...prev,
          [id]: { ...prev[id], [prop]: newValue || undefined },
        };
      }
    });
  };

  const isInvalid = !checkRangeInputValid(formik.values[id]);

  const renderInput = (prop: "min" | "max") => (
    <InputNumber
      type='number'
      value={parseInt(pathOr("0", [id, prop], formik.values)) || undefined}
      onKeyDown={onKeyDown}
      onChange={onChange(prop)}
      min={min}
      max={max}
      role={`${prop}-input`}
    />
  );

  return (
    <div className={styles.rangeInput}>
      <label className={cn(styles.label)}>{label}</label>
      <Form layout='inline' className={styles.inputsContainer}>
        <Form.Item
          label='From'
          className={styles.inputGroup}
          validateStatus={isInvalid ? "error" : undefined}
        >
          {renderInput("min")}
        </Form.Item>
        <Form.Item
          label='To'
          className={styles.inputGroup}
          validateStatus={isInvalid ? "error" : undefined}
        >
          {renderInput("max")}
        </Form.Item>
      </Form>
    </div>
  );
};
