import React from "react";

import { Layout } from "antd";
import { Outlet } from "react-router-dom";

import { Breadcrumbs } from "components/Breadcrumbs";
import { Footer } from "components/Footer";
import { Header } from "components/Header";

const { Content } = Layout;

export const MainLayout = () => {
  return (
    <>
      <Header />
      <Content className='base-container'>
        <Breadcrumbs />
        <Outlet />
      </Content>
      <Footer />
    </>
  );
};
