import { appModules } from "constants/appModules";
import * as PATHS from "constants/navigationPaths";
import { useCurrentUser, useFeatureFlags } from "hooks";
import { HeaderLinkProps } from "types";

export const useRoutesMap = () => {
  const { FF_SALES_TARGETING, FF_MI_READMISSIONS } = useFeatureFlags();
  const { checkUserHasAccess } = useCurrentUser();

  const navigationLinksMap: HeaderLinkProps[] = [
    {
      label: "Market Intelligence",
      moduleId: [appModules.MI.id, appModules.MI_R.id],
      isDisabled: !checkUserHasAccess(appModules.MI) && !checkUserHasAccess(appModules.MI_R),
      subLinks: [
        {
          label: "Procedures",
          path: PATHS.proceduresPath,
          isDisabled: !checkUserHasAccess(appModules.MI),
        },
        {
          label: "Readmissions",
          path: PATHS.readmissionsPath,
          isDisabled: !FF_MI_READMISSIONS || !checkUserHasAccess(appModules.MI_R),
        },
      ],
    },
  ];

  if (FF_SALES_TARGETING) {
    navigationLinksMap.push({
      label: "Sales Targeting",
      moduleId: [appModules.ST_F.id, appModules.ST_P.id],
      isDisabled: !FF_SALES_TARGETING,
      subLinks: [
        {
          label: "Facility Targeting",
          path: PATHS.hospitalTargetingPath,
          isDisabled: !checkUserHasAccess(appModules.ST_F),
        },
        {
          label: "Physician Targeting",
          path: PATHS.physicianTargetingPath,
          isDisabled: !checkUserHasAccess(appModules.ST_P),
        },
        {
          label: "Search Facility",
          path: PATHS.hospitalSegmentationPath,
          isDisabled: !checkUserHasAccess(appModules.ST_F),
        },
        {
          label: "Search Physician",
          path: PATHS.physicianSegmentationPath,
          isDisabled: !checkUserHasAccess(appModules.ST_P),
        },
      ],
    });
  }

  return { navigationLinksMap };
};
