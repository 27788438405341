import { AxiosRequestConfig } from "axios";

import { SegmentModel, SegmentFiltersAdapterClass, ApiFiltersConfig } from "adapters";
import { PageMeta } from "constants/pageIds";
import ApiService from "utils/apiService";

export const getSegmentFilters = (
  filtersPath: PageMeta["apiFiltersPath"],
  options: AxiosRequestConfig = {},
  Adapter: SegmentFiltersAdapterClass
): Promise<Record<string, SegmentModel>> =>
  ApiService.get<ApiFiltersConfig>(`${filtersPath}/config`, undefined, options).then((res) =>
    Adapter.toModel(res)
  );
