import { UseQueryOptions } from "@tanstack/react-query";
import { values } from "ramda";

import { SegmentFiltersAdapter, SegmentModel, SegmentsAdapter } from "adapters";
import { getSegmentFilters } from "api/segments";
import { PageId, pageIdMap } from "constants/pageIds";

import { useQueryData } from "./useQueryData";

export const useSegments = (
  pageId: PageId,
  options?: UseQueryOptions<Record<string, SegmentModel>, any>
) => {
  const page = pageIdMap[pageId];
  const query = useQueryData(
    [`${pageId}_segmentFilters`, pageId, page.apiFiltersPath],
    ({ signal }) => getSegmentFilters(page.apiFiltersPath, { signal }, SegmentFiltersAdapter),
    options
  );

  const segments = query.data ? SegmentsAdapter.toModel(values(query.data)) : undefined;
  return { ...query, data: segments };
};
