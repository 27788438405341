import React, { createContext, useMemo, useState } from "react";

import { always } from "ramda";

import { FC, SetState } from "types";

export type ConfigModel = {
  API_URL: string;
  ENV: string;
  VER: string;
  ARCGIS_WEBMAP_ID: string;
  ARCGIS_API_KEY: string;
  HOTJAR_ID: number;
  AMPLITUDE_TRACKING_ID: string;
  flags: Record<string, boolean>;
};

const initialConfig: ConfigModel = {
  API_URL: "",
  ENV: "",
  VER: "",
  ARCGIS_WEBMAP_ID: "",
  ARCGIS_API_KEY: "",
  HOTJAR_ID: -1,
  AMPLITUDE_TRACKING_ID: "",
  flags: {},
};

type ConfigContextValue = ConfigModel & {
  setConfig: SetState<ConfigModel>;
};

export const ConfigContext = createContext<ConfigContextValue>({
  ...initialConfig,
  setConfig: always(undefined),
});

export const ConfigContextProvider: FC = ({ children }) => {
  const [config, setConfig] = useState(initialConfig);
  const value = useMemo<ConfigContextValue>(
    () => ({
      ...config,
      setConfig,
    }),
    [config, setConfig]
  );
  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};
