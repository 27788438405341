import { prop } from "ramda";

import { AppModule, appModules } from "./appModules";

const makePath = (module: AppModule, path: string) => {
  return `${prop("path", module)}${path}`;
};

export const mainPath = "/";

export const loginPath = "/auth/login";
export const signupPath = "/auth/signup";
export const signupFinishedPath = "/auth/signup/finished";
export const forgotPasswordPath = "/auth/forgot-password";
export const forgotPasswordEmailSendPath = "/auth/forgot-password/sent";
export const resetPasswordPath = "/auth/reset-password/:hash";
export const resetPasswordFinishedPath = "/auth/reset-password/finished";

export const documentsPath = "/documents/:doc";

export const marketIntelligenceDashboardPath = "/market-intelligence/dashboard";
export const proceduresPath = appModules.MI.path;
export const readmissionsPath = appModules.MI_R.path;

export const hospitalTargetingPath = makePath(appModules.ST_F, "/targeting");
export const hospitalSegmentationPath = makePath(appModules.ST_F, "/segmentation");
export const hospitalProfilePath = makePath(appModules.ST_F, "/:npi?");
export const physicianTargetingPath = makePath(appModules.ST_P, "/targeting");
export const physicianSegmentationPath = makePath(appModules.ST_P, "/segmentation");
export const physicianProfilePath = makePath(appModules.ST_P, "/:npi?");

export const settingsOrgPath = "/settings/organization";
export const settingsOrgAddGroupPath = "/settings/organization/group/create";
export const settingsOrgGroupPath = "/settings/organization/group/:groupID";
export const settingsMyProfilePath = "/settings/user/me";
export const settingsUserProfilePath = "/settings/user/:userID";

export const supportFAQSPath = "/support/faqs/:slug";
export const supportKeyFeaturesPath = "/support/features/:slug";

export const errorPath = "/error/:code?";
