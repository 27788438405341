import React, { useContext, useEffect } from "react";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { useQuery } from "@tanstack/react-query";

import { FallbackSpinner } from "components/Spinner";
import { AXIOMHEALTH_TOKEN_KEY } from "constants/authentication";
import { ConfigContext, ConfigModel } from "context/ConfigContext";
import { useAlerts, useAuthenticate, useHotJar, useLocalStorage, useTracking } from "hooks";
import { axiosInst, isRecommendedBrowser } from "utils";

import { Router } from "./Router";

const Routing = () => {
  const { setConfig } = useContext(ConfigContext);
  const { initializeTracking } = useTracking();
  const { addAlert } = useAlerts();
  const { initHotJar } = useHotJar();
  const { logout } = useAuthenticate();
  const { setItem, getItem } = useLocalStorage();
  const { data, isLoading } = useQuery(["config"], () =>
    fetch(`/config.json?t=${Date.now()}`).then<ConfigModel>((res) => res.json())
  );

  useEffect(() => {
    const disclaimerKey = "browserDisclaimer";
    const disclaimer = getItem(disclaimerKey);
    if (!isRecommendedBrowser() && !disclaimer) {
      addAlert(
        <>
          <h3>Disclaimer!</h3>
          We recommend that you use the latest version of Chrome or Firefox for the best experience.
        </>
      );
      setItem(disclaimerKey, "ok");
    }
  }, []);

  const token = getItem<string>(AXIOMHEALTH_TOKEN_KEY);
  useEffect(() => {
    if (token) {
      axiosInst.defaults.headers.common["Authorization"] = token;
    }
  }, [token]);

  useEffect(() => {
    const versionKey = "AXIOM_APP_VER";
    if (data) {
      setConfig(data);
      const storedVer = getItem<string>(versionKey);
      if (storedVer !== data.VER) {
        logout();
        setItem(versionKey, data.VER);
      }
      axiosInst.defaults.baseURL = data.API_URL;

      if (process.env.NODE_ENV === "production") {
        initializeTracking(data.AMPLITUDE_TRACKING_ID);
        Sentry.init({
          dsn: "https://b243c75883d6478db7b9921e88ee569f@o468601.ingest.sentry.io/5568438",
          autoSessionTracking: true,
          debug: data.ENV !== "prod",
          integrations: [new Integrations.BrowserTracing()],
          tracesSampleRate: 1.0,
          environment: data.ENV,
        });
      }

      if (data.HOTJAR_ID) {
        initHotJar(data.HOTJAR_ID, 6, data.ENV !== "prod");
      }
    }
  }, [data]);

  if (isLoading) {
    return <FallbackSpinner />;
  }

  return <Router />;
};

export default Routing;
