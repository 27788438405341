import * as R from "ramda";

import { FacilityMetricsRequestProps } from "api/facilityProfile";
import { DataSeriesResponse, IAdapter, Mapper } from "types";
import { normalizeName } from "utils";

export type FacilityProfileMetricsModel = {
  marketSegments: DataSeriesResponse;
  topProcedures: DataSeriesResponse;
  topDiagnosis: DataSeriesResponse;
  siteOfService: DataSeriesResponse;
  specialty: DataSeriesResponse;
  payerType: DataSeriesResponse;
  age: DataSeriesResponse;
};

const getSeries = (name: string) => (dataSeries: DataSeriesResponse[]) =>
  R.find(R.propEq("name", name), dataSeries) || { name: "", data: {} };

const fixSeriesName = (series: DataSeriesResponse) => ({
  ...series,
  name: normalizeName(series.name),
});

const takeOutSeries = (name: string) => R.pipe(getSeries(name), fixSeriesName);

class FacilityProfileMetricsAdapterClass implements IAdapter {
  receivingMapper = {
    marketSegments: takeOutSeries("MARKET_SEGMENTS"),
    topProcedures: takeOutSeries("TOP_5_PROCEDURES"),
    topDiagnosis: takeOutSeries("TOP_5_DIAGNOSIS_CATEGORIES"),
    siteOfService: takeOutSeries("SITE_OF_SERVICE"),
    specialty: takeOutSeries("SPECIALTY"),
    payerType: takeOutSeries("PAYER_TYPE"),
    age: takeOutSeries("AGE"),
  };

  sendingMapper = {
    npi: R.prop("npi"),
    year: R.pipe(R.path(["year"]), Number),
  };

  toModel(item: unknown) {
    return R.map<Mapper, FacilityProfileMetricsModel>((fn) => fn(item), this.receivingMapper);
  }

  toApiModel(item: unknown) {
    return R.map<Mapper, FacilityMetricsRequestProps>((fn) => fn(item), this.sendingMapper);
  }
}

export const FacilityProfileMetricsAdapter = new FacilityProfileMetricsAdapterClass();
