export enum ProcedureCategories {
  MARKET_SEGMENT = "procedureMarketSegment",
  PROCEDURE_CATEGORY = "procedureCategory",
  PROCEDURE = "procedure",
}

export type ProcedureApiOption = {
  segment: string;
  category: string;
  location: string;
  name: string;
  id: string;
};
