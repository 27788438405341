import React from "react";

import { ErrorBoundary as SentryErrorBoundary, ErrorBoundaryProps } from "@sentry/react";

import { DefaultFallback } from "components/DefaultFallback";

export const ErrorBoundary = ({
  children,
  fallback = <DefaultFallback />,
  ...props
}: ErrorBoundaryProps) => {
  return (
    <SentryErrorBoundary fallback={fallback} {...props}>
      {children}
    </SentryErrorBoundary>
  );
};
