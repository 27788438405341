import React from "react";

import { FallbackRender } from "@sentry/react/types/errorboundary";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfigProvider, ConfigProviderProps } from "antd";
import { pathOr, prop } from "ramda";
import { RecoilRoot } from "recoil";

import { ErrorBoundary } from "components/ErrorBoundary";
import { MINUTE } from "constants/time";
import { ConfigContextProvider } from "context/ConfigContext";
import ErrorPage from "pages/ErrorPage";
import Routing from "routing";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (count: number, error: any) =>
        ![401, 403, 404].includes(error?.response?.status || error?.status) && count < 2,
      cacheTime: 20 * MINUTE,
      staleTime: 5 * MINUTE,
      useErrorBoundary: false,
    },
  },
});

const theme: ConfigProviderProps["theme"] = {
  token: {
    colorPrimary: "#3ebfc2",
    fontFamily: "Poppins, Roboto, sans-serif",
  },
};

export const AppErrorFallback: FallbackRender = ({ error }) => {
  const code = prop("code", error) || pathOr<number>(500, ["response", "status"], error);
  return <ErrorPage errorCode={code} />;
};

function App() {
  return (
    <ConfigProvider theme={theme}>
      <ErrorBoundary fallback={AppErrorFallback}>
        <RecoilRoot>
          <QueryClientProvider client={queryClient}>
            <ConfigContextProvider>
              <Routing />
            </ConfigContextProvider>
            {process.env.NODE_ENV === "development" && <ReactQueryDevtools />}
          </QueryClientProvider>
        </RecoilRoot>
      </ErrorBoundary>
    </ConfigProvider>
  );
}

export default App;
