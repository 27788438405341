import React, { ReactNode } from "react";

import { Button, Col, Layout, Row } from "antd";
import cn from "classnames";
import { useParams } from "react-router-dom";

import { AxiomHealthLogoSVG } from "assets/icons";
import { mainPath } from "constants/navigationPaths";

import styles from "./ErrorPage.module.scss";

const { Content } = Layout;

const getErrorDescriptions = (errorCode: number): { title: ReactNode; message: ReactNode } => {
  switch (errorCode) {
    case 500: {
      return {
        title: "Internal error",
        message:
          "We encountered an error and cannot fulfill the request. The error has been traced and we will work hard to get a fix out as soon as possible.",
      };
    }
    case 403: {
      return {
        title: "Access to Module denied",
        message: "Contact admin of your Organisation, to grant you needed permissions.",
      };
    }
    default: {
      return {
        title: "Page not found",
        message: "We’re sorry but we can’t find page you’re looking for.",
      };
    }
  }
};

type Props = {
  errorCode?: number;
};

const ErrorPage = ({ errorCode = 404 }: Props) => {
  const { code = errorCode } = useParams<{ code?: string }>();
  const { title, message } = getErrorDescriptions(Number(code));

  return (
    <Content className={cn("base-container", styles.errorPage)}>
      <header className={styles.errorPage__header}>
        <AxiomHealthLogoSVG />
      </header>
      <Row>
        <Col xs={24} className={styles.errorPage__body}>
          <div className={styles.errorCard}>
            {Number(code) === 404 ? (
              <div className={styles.errorCard__errorNumber}>{code}</div>
            ) : null}
            <div className={styles.errorCard__body}>
              <div className={styles.errorCard__body__title}>{title}</div>
              <div className={styles.errorCard__body__message}>{message}</div>
              <div className={styles.errorCard__body__footer}>
                <Button type='primary' href={mainPath}>
                  Go to home page
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
};

export default ErrorPage;
