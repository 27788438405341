import * as R from "ramda";

import { BaseDataModel } from "types";
import { getStateCode } from "utils";
import { takeOutCoordinate } from "utils/map";

import { BaseAdapter } from "../BaseAdapter";
import { PhysicianMapPointModel } from "../physicianMap";

export type FacilityMapPointModel = PhysicianMapPointModel;

class FacilitiesMapDataAdapterClass extends BaseAdapter<FacilityMapPointModel[]> {
  facilityMapper = {
    address: R.prop("address"),
    city: R.prop("city"),
    count: R.prop("count"),
    decile: R.prop("decile"),
    name: R.prop("facilityName"),
    type: R.prop("facilityType"),
    lat: takeOutCoordinate("lat"),
    lng: takeOutCoordinate("lng"),
    npi: R.prop("npi"),
    state: R.pipe(R.prop("state"), getStateCode),
    zipCode: R.pipe(R.prop("zipCode"), R.slice(0, 5)),
  } as const;

  toModel(data: BaseDataModel[]) {
    return R.pipe(
      R.map<BaseDataModel, FacilityMapPointModel>(
        this.runMapper<FacilityMapPointModel>(this.facilityMapper)
      ),
      R.filter<FacilityMapPointModel>(R.pipe(R.props(["lat", "lng"]), R.all(Boolean)))
    )(data);
  }
}

export const FacilitiesMapDataAdapter = new FacilitiesMapDataAdapterClass();
