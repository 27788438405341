import { FormikProps } from "formik";
import * as R from "ramda";

import {
  HeaderFormik,
  isRangeInput,
  MARKET_SEGMENT_AGG_LVL,
  SegmentFiltersModel,
} from "adapters/SegmentFilters";
import { checkRangeInputValid } from "components/FiltersComponents/RangeInput";
import { useFormikActions } from "hooks";
import { BaseDataModel, DiagnosisItem } from "types";

export const checkIfFieldIncludesValue = <T = { name: string }>(
  field = "",
  arr: string[] = []
): ((testObj: T) => boolean) =>
  R.where({
    [field]: (item: string | string[]) =>
      R.isEmpty(arr) || !item || R.isEmpty(item)
        ? R.always(R.T)
        : typeof item === "string"
        ? R.includes(item, arr)
        : !R.isEmpty(R.intersection(item, arr)),
  });

export const filterBySegments = (options: { label: string }[], field = "segments") =>
  checkIfFieldIncludesValue(field, R.map(R.prop("label"), options));

export const filterByCategories = (options: { label: string }[], field = "categories") =>
  checkIfFieldIncludesValue(field, R.map(R.prop("label"), options));

export const filterByLocations = (options: { label: string }[], field = "locations") =>
  checkIfFieldIncludesValue(field, R.map(R.prop("label"), options));

export const filterByDiagnosisMarketSegment = (diagnosis: DiagnosisItem[]) =>
  filterBySegments(diagnosis, "marketSegments");

export const filterByDiagnosisLocation = (diagnosis: DiagnosisItem[]) =>
  filterByLocations(diagnosis);

export const filterByDiagnosisCategory = (diagnosis: DiagnosisItem[]) =>
  filterByCategories(diagnosis);

export const areArraysEqual = (origin: unknown[], compare: unknown[]) =>
  R.isEmpty(R.symmetricDifference(origin, compare));

export const checkEqualFilters = <F = { [key: string]: unknown }>(
  origin: F,
  current: F,
  checkAggLvlOnly = true
) => {
  let keysWithArray = R.filter(
    (key) => Array.isArray(origin[key]),
    R.keys(origin) as (keyof F)[]
  ) as string[];
  const omitArrays = R.omit(keysWithArray);
  const simpleValueFilters = R.equals(omitArrays(origin), omitArrays(current));
  if (checkAggLvlOnly) {
    let aggLevel: string | Record<string, string> = R.prop("aggregationLevel", current as any);
    if (typeof aggLevel !== "string") {
      aggLevel = R.prop("value", aggLevel);
    }
    keysWithArray = R.filter(
      (key) =>
        !(key !== aggLevel && /^procedure|^diagnosis/gm.test(key)) || key === "procedureLocations",
      keysWithArray
    );
  }
  const arrayFiltersIsEqual = R.map(
    (key) =>
      !(R.has(key, origin) && R.has(key, current)) ||
      areArraysEqual(origin[key] as unknown[], current[key] as unknown[]),
    keysWithArray
  );
  return simpleValueFilters && R.all(R.equals(true), arrayFiltersIsEqual);
};

export const diffObject = <T extends Record<string, unknown>>(objCurr: T, objOrigin: T) => {
  const keys = R.keys(objCurr);
  return R.reduce(
    (acc, curr) => {
      if (!R.equals(objCurr[curr], objOrigin[curr])) {
        acc[curr] = objCurr[curr];
      }
      return acc;
    },
    {} as Partial<T>,
    keys
  );
};

export const equalsObject = <T extends BaseDataModel>(obj1: T, obj2: T): boolean => {
  const obj1Keys = R.keys(obj1);
  const obj2Keys = R.keys(obj2);

  if (!areArraysEqual(obj1Keys, obj2Keys)) return false;

  return R.all((key) => {
    const value1 = obj1[key];
    const value2 = obj2[key];

    if (Array.isArray(value1) && Array.isArray(value2)) return areArraysEqual(value1, value2);
    if (R.is(Object, value1) && R.is(Object, value2))
      return equalsObject(value1 as BaseDataModel, value2 as BaseDataModel);

    return R.equals(value1, value2);
  }, obj1Keys);
};

export const checkIsValid = (
  headerFormik: FormikProps<HeaderFormik>,
  filtersFormik: FormikProps<Record<string, string[]>> | null,
  applied: SegmentFiltersModel | undefined
) => {
  if (R.isEmpty(headerFormik.values.segments)) return false;
  const headerCheck = useFormikActions(headerFormik);
  const filtersCheck = !R.isNil(filtersFormik) ? useFormikActions(filtersFormik) : undefined;

  if (R.isNil(filtersFormik) || R.isNil(filtersCheck)) return false;
  const aggLevel = headerFormik.values.aggregationLevel;
  const headerUnchanged = headerCheck.checkIsDataUnchanged();
  const filtersUnchanged = filtersCheck.checkIsDataUnchanged(undefined, applied?.filters);

  const correctRange = R.pipe<any, string[], boolean>(
    R.filter<string>((key) => {
      const value = filtersFormik.values[key];
      return isRangeInput(value);
    }),
    R.all((key) => {
      const value = filtersFormik.values[key] as any as { min: string; max: string };
      return checkRangeInputValid(value);
    })
  )(R.keys(filtersFormik.values));

  const isChanged = !headerUnchanged || !filtersUnchanged;

  if (aggLevel === MARKET_SEGMENT_AGG_LVL) return isChanged && correctRange;
  return !R.isEmpty(R.propOr([], aggLevel, filtersFormik.values)) && isChanged && correctRange;
};

export const filterOutEmpty = (filters: Record<string, string[]>): Record<string, string[]> =>
  R.pipe(R.keys, (filtersKeys) =>
    filtersKeys.reduce<typeof filters>((acc, key) => {
      const value = filters[key];
      if (!R.isEmpty(value)) {
        acc[key] = value;
      }
      return acc;
    }, Object.assign({}))
  )(filters);
