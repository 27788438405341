import { prop, propEq } from "ramda";

export const updateArray = <T>(currentArray: T[], newElement: T, fieldToMatch?: keyof T) => {
  const newArray = [...currentArray];
  let existedIndex = -1;
  if (typeof newElement === "object") {
    if (fieldToMatch) {
      const newElementValue = prop(fieldToMatch, newElement);
      existedIndex = (newArray as Record<string, unknown>[]).findIndex((v) =>
        propEq(fieldToMatch as string, newElementValue, v)
      );
    }
  } else {
    existedIndex = newArray.findIndex((v) => v === newElement);
  }

  if (existedIndex === -1) {
    newArray.push(newElement);
  } else {
    newArray.splice(existedIndex, 1);
  }
  return newArray;
};

export enum SortOrder {
  ASC = 1,
  DESC = -1,
}

export const sortByKey: <A>(array: A[], key: keyof A, order?: SortOrder) => A[] = (
  array,
  key,
  order = SortOrder.ASC
) => {
  return array.sort((a, b) => {
    if (a[key] && b[key]) {
      return a[key] > b[key] ? order : a[key] < b[key] ? -order : 0;
    } else {
      return a[key] && !b[key] ? order : !a[key] && b[key] ? -order : 0;
    }
  });
};

export const sortStringBySimilarity = (a: string, b: string, search: string) => {
  switch (true) {
    case a === search:
    case a.startsWith(search) && !b.startsWith(search):
    case a.includes(search) && !b.includes(search):
      return -1;

    case b === search:
    case !a.startsWith(search) && b.startsWith(search):
    case !a.includes(search) && b.includes(search):
      return 1;

    default:
      return 0;
  }
};
