import { atom } from "recoil";

import { SegmentFiltersModel } from "adapters";
import { PageMeta } from "constants/pageIds";

type GlobalFiltersModel = Partial<Record<PageMeta["persistFiltersModule"], SegmentFiltersModel>>;

export const globalFiltersState = atom<GlobalFiltersModel>({
  key: "globalFilters",
  default: {},
});
