export enum ChartExportingMimeType {
  PNG = "image/png",
  JPEG = "image/jpeg",
  PDF = "application/pdf",
  SVG = "image/svg+xml",
}

export type DataSeriesResponse = {
  name: string;
  data: Record<string, { value: number | null; ratio: number | null; growth?: number | null }>;
};

export type DataSeriesModel = {
  name: string;
  data: { name: string; y: number | null }[];
};
