import * as R from "ramda";

import { BaseDataModel, IAdapter, Mapper } from "types";

import { BaseAdapter } from "../BaseAdapter";

export type PhysicianMapPanelsModel = {
  physiciansCount: number;
  trend: number;
  avgProceduresPerUnit: number;
  avgProceduresPerYear: number;
};

const retrieveData = (key: string) =>
  R.pipe(R.pathOr("", ["info", key]), parseFloat, (data) => (isNaN(data) ? null : data));

class PhysicianMapPanelsAdapterClass extends BaseAdapter<PhysicianMapPanelsModel> {
  receivingMapper = {
    physiciansCount: retrieveData("physiciansCount"),
    trend: retrieveData("trend"),
    avgProceduresPerUnit: retrieveData("avgProceduresPerUnit"),
    avgProceduresPerYear: retrieveData("avgProceduresPerYear"),
  };

  toModel(item: BaseDataModel) {
    return this.runMapper<PhysicianMapPanelsModel>(this.receivingMapper)(item);
  }
}

export const PhysicianMapPanelsAdapter = new PhysicianMapPanelsAdapterClass();
