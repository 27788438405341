import { ChangeEvent, useEffect } from "react";

export const useSyncScroll = <H extends HTMLElement>(elements: (H | undefined)[]) => {
  let activeElem: EventTarget | null = null;

  const setScrollLeftFromRatio = (ratio: number, elem: H) => {
    const { clientWidth, scrollWidth } = elem;
    const maxScroll = scrollWidth - clientWidth;
    elem.scrollLeft = maxScroll * ratio;
  };

  const handleElemScroll = (e: ChangeEvent<H>) => {
    if (activeElem === e.target) {
      const { clientWidth, scrollLeft, scrollWidth } = e.target;
      const maxScroll = scrollWidth - clientWidth;
      const ratio = scrollLeft / maxScroll;
      elements
        .filter((el) => el !== e.target)
        .forEach((el) => {
          el && setScrollLeftFromRatio(ratio, el);
        });
    }
  };

  const toggleActiveElem = (setActive: boolean) => (e: MouseEvent) => {
    activeElem = setActive ? e.target : null;
  };

  useEffect(() => {
    elements.forEach((el) => {
      if (!el) return;
      el.addEventListener("scroll", handleElemScroll as any);
      el.addEventListener("mouseenter", toggleActiveElem(true));
      el.addEventListener("mouseleave", toggleActiveElem(false));
    });

    return () => {
      elements.forEach((el) => {
        if (!el) return;
        el.removeEventListener("scroll", handleElemScroll as any);
        el.removeEventListener("mouseenter", toggleActiveElem(true));
        el.removeEventListener("mouseleave", toggleActiveElem(false));
      });
    };
  }, [elements]);
};
