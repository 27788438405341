import * as R from "ramda";

import { BaseDataModel } from "types";
import { getStateCode } from "utils";
import { takeOutCoordinate } from "utils/map";

import { BaseAdapter } from "../BaseAdapter";

export type PhysicianMapPointModel = {
  address: string;
  city: string;
  count: number;
  decile: number;
  name: string;
  type: string;
  lat: number;
  lng: number;
  npi: string;
  state: string;
  zipCode: string;
};

export type PhysicianMapPointDetailsModel = {
  physicians: {
    counts: Record<string, number | null>;
    name: string;
    npi: string;
    totalCount: number;
  }[];
  totals: {
    counts: Record<string, number | null>;
    totalCount: number;
  };
};

class PhysicianMapDataAdapterClass extends BaseAdapter<PhysicianMapPointModel[]> {
  facilityMapper = {
    address: R.prop("address"),
    city: R.prop("city"),
    count: R.prop("count"),
    decile: R.prop("decile"),
    name: R.prop("facilityName"),
    type: R.prop("facilityType"),
    lat: takeOutCoordinate("lat"),
    lng: takeOutCoordinate("lng"),
    npi: R.prop("npi"),
    state: R.pipe(R.prop("state"), getStateCode),
    zipCode: R.pipe(R.prop("zipCode"), R.slice(0, 5)),
  } as const;

  physicianDetailsMapper = {
    counts: R.pipe(R.prop("counts"), (data: { name: string; value: number | null }[]) =>
      data.reduce<Record<string, number | null>>((acc, { name, value }) => {
        acc[name] = value;
        return acc;
      }, Object.assign({}))
    ),
    name: R.prop("physicianName"),
    npi: R.prop("physicianNpi"),
    totalCount: R.prop("totalCount"),
  } as const;

  detailsMapper = {
    physicians: R.pipe<
      any,
      Record<string, unknown>[],
      PhysicianMapPointDetailsModel["physicians"],
      PhysicianMapPointDetailsModel["physicians"],
      PhysicianMapPointDetailsModel["physicians"]
    >(
      R.prop("physicians"),
      R.map(
        this.runMapper<PhysicianMapPointDetailsModel["physicians"][number]>(
          this.physicianDetailsMapper
        )
      ),
      R.sortBy(R.prop("totalCount")),
      R.reverse
    ),
    totals: (data: { physicians: unknown[]; totalCount: number }) => {
      const procedures = R.pipe<any, { name: string }[], string[]>(
        R.pathOr([], ["physicians", 0, "counts"]),
        R.pluck("name")
      )(data);
      const counts = procedures.reduce((acc, key) => {
        acc[key] = data.physicians.reduce<number>((sum, curr) => {
          const physicianCounts = R.propOr([], "counts", curr) as { name: string; value: number }[];
          const keyCount = R.find(({ name }) => name === key, physicianCounts);
          return sum + (R.propOr(0, "value", keyCount) as number);
        }, 0);
        return acc;
      }, Object.assign({}));
      return {
        counts,
        totalCount: R.prop("totalCount", data),
      };
    },
  } as const;

  toModel(data: BaseDataModel[]) {
    return R.pipe(
      R.map<BaseDataModel, PhysicianMapPointModel>(
        this.runMapper<PhysicianMapPointModel>(this.facilityMapper)
      ),
      R.filter<PhysicianMapPointModel>(R.pipe(R.props(["lat", "lng"]), R.all(Boolean)))
    )(data);
  }

  toDetailModel(data: { physicians: unknown[]; totalCount: number }) {
    return this.runMapper<PhysicianMapPointDetailsModel>(this.detailsMapper)(data);
  }
}

export const PhysicianMapDataAdapter = new PhysicianMapDataAdapterClass();
