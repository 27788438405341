import { clone, findIndex, propEq } from "ramda";
import { useRecoilState } from "recoil";
import { v4 as uuidv4 } from "uuid";

import { alertsState } from "atoms/alerts";
import { AlertItem, AlertType } from "types";

export const useAlerts = () => {
  const [alerts, setAlerts] = useRecoilState(alertsState);

  const addAlert = (message: AlertItem["message"], type: AlertType = "info") => {
    const index = findIndex(propEq("message", message), alerts);
    const baseArray = clone(alerts);
    if (index > -1) {
      const newArray = alerts.map((a, idx) => {
        if (idx === index) {
          return { ...a, isShaking: true };
        }
        return { ...a };
      });
      setAlerts(newArray);
      setTimeout(() => {
        setAlerts(baseArray);
      }, 500);
      return alerts[index].key;
    } else {
      const key = uuidv4();
      const newAlert: AlertItem = {
        key,
        type,
        message,
        isShown: true,
      };
      setAlerts([...baseArray, { ...newAlert, isShowing: true }]);
      setTimeout(() => {
        setAlerts([...baseArray, newAlert]);
      }, 500);
      return key;
    }
  };

  const dismissAlert = (key: string) => {
    const hideAlert = alerts.map((a) => {
      if (a.key === key) {
        return { ...a, isShown: false };
      }
      return { ...a };
    });
    setAlerts([...hideAlert]);
    setTimeout(() => {
      const newArray = alerts.filter((a) => a.key !== key);
      setAlerts([...newArray]);
    }, 750);
  };

  const clearAlerts = () => {
    setAlerts([]);
  };

  return {
    alerts,
    setAlerts,
    addAlert,
    dismissAlert,
    clearAlerts,
  };
};
