import React from "react";

import cn from "classnames";

import styles from "./Spinner.module.scss";

export type Props = {
  diameter?: number;
  borderWidth?: number;
  containerClass?: string;
  containerStyle?: React.CSSProperties;
  spinnerClass?: string;
  spinnerStyle?: React.CSSProperties;
};

export const Spinner = ({
  diameter = 35,
  borderWidth = 5,
  containerClass,
  containerStyle,
  spinnerClass,
  spinnerStyle = {},
}: Props) => {
  const spinnerFinalStyle: React.CSSProperties = {
    width: diameter,
    height: diameter,
    borderWidth,
    ...spinnerStyle,
  };

  return (
    <div
      className={cn(styles.spinnerContainer, containerClass)}
      style={containerStyle}
      data-testid='spinner'
    >
      <div className={cn(styles.spinner, spinnerClass)} style={spinnerFinalStyle} />
    </div>
  );
};
