import React, { Fragment } from "react";

import { Col, Row } from "antd";
import cn from "classnames";
import { isEmpty } from "ramda";
import { Link } from "react-router-dom";

import { HomeSVG, BreadcrumbsNextSVG } from "assets/icons";
import { mainPath, proceduresPath } from "constants/navigationPaths";
import { useRoutePath } from "hooks";
import { Breadcrumb } from "types";
import { getBreadcrumbs } from "utils/navigation";

import styles from "./Breadcrumbs.module.scss";

export const Breadcrumbs = () => {
  const path = useRoutePath();
  const breadcrumbs = getBreadcrumbs(path);

  if (!breadcrumbs || isEmpty(breadcrumbs)) {
    return null;
  }

  const renderBreadcrumb = (crumb: Breadcrumb, idx: number) => {
    if (typeof crumb === "string") {
      return (
        <Fragment key={`${crumb}-${idx}`}>
          <BreadcrumbsNextSVG className={styles.breadcrumbs_next_icon} />
          {crumb}
        </Fragment>
      );
    }
    return (
      <Fragment key={`${crumb.label}-${idx}`}>
        <BreadcrumbsNextSVG className={styles.breadcrumbs_next_icon} />
        <Link to={crumb.path}>{crumb.label}</Link>
      </Fragment>
    );
  };

  return (
    <Row className={styles.ah_breadcrumbs} data-testid='ah_breadcrumbs'>
      <Col className={styles.breadcrumbsContainer}>
        <Link
          to={mainPath}
          onKeyDown={(e) => {
            if (e.key === " ") {
              e.preventDefault();
              e.currentTarget.click();
            }
          }}
        >
          <HomeSVG className={styles.home_icon} />
        </Link>
        {breadcrumbs.map((crumb, idx) => renderBreadcrumb(crumb, idx))}
      </Col>
    </Row>
  );
};
