import React from "react";

import cn from "classnames";

import styles from "./DefaultFallback.module.scss";

type Props = { className?: string };

export const DefaultFallback = ({ className }: Props) => {
  return (
    <div className={cn(styles.defaultFallback, className)}>
      Sorry. Something went wrong, while rendering component.
    </div>
  );
};
