import * as R from "ramda";

import { BaseDataModel } from "../../types";
import { BaseAdapter } from "../BaseAdapter";

export type LicenseModule = {
  id: number;
  groupId?: number;
  moduleId: string;
  name: string;
  seatsTotal: number;
  seatsAvailable: number;
  seatsTaken: number;
  segments: {
    id: number;
    segmentId: string;
    name: string;
  }[];
  isActive: boolean;
};

class LicenseModuleAdapterClass extends BaseAdapter<LicenseModule> {
  receivingMapper = {
    id: R.path(["id"]),
    moduleId: R.path(["module", "appModuleName"]),
    name: R.path(["module", "name"]),
    seatsTotal: R.prop("numOfSeats"),
    seatsAvailable: R.prop("seatsAvailable"),
    seatsTaken: R.prop("seatsTaken"),
    segments: R.path(["module", "segments"]),
    isActive: R.path(["isActive"]),
  };

  toModel(data: BaseDataModel) {
    return this.runMapper<LicenseModule>(this.receivingMapper)(data);
  }
}
export const LicenseModuleAdapter = new LicenseModuleAdapterClass();
