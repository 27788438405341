import { sortByKey, SortOrder } from "./arrays";

export type ColorRange = {
  from?: number;
  to?: number;
  color: string;
};

export const colorRangesForPercetages: ColorRange[] = [
  {
    to: -25,
    color: "#A20000",
  },
  {
    from: -25,
    to: -20,
    color: "#D00000",
  },
  {
    from: -20,
    to: -15,
    color: "#D85004",
  },
  {
    from: -15,
    to: -10,
    color: "#E25F00",
  },
  {
    from: -10,
    to: -5,
    color: "#E67421",
  },
  {
    from: -5,
    to: 5,
    color: "#E69721",
  },
  {
    from: 5,
    to: 10,
    color: "#E6BB21",
  },
  {
    from: 10,
    to: 15,
    color: "#E5C61E",
  },
  {
    from: 15,
    to: 20,
    color: "#92DD47",
  },
  {
    from: 20,
    to: 25,
    color: "#61D21C",
  },
  {
    from: 25,
    color: "#16BB37",
  },
];

export const colorRangesForValues: ColorRange[] = [
  {
    from: 0,
    to: 8.333,
    color: "#821717",
  },
  {
    from: 8.333,
    to: 16.666,
    color: "#9E1F1F",
  },
  {
    from: 16.666,
    to: 25,
    color: "#B62525",
  },
  {
    from: 25,
    to: 33.333,
    color: "#CE2C2C",
  },
  {
    from: 33.333,
    to: 41.666,
    color: "#E73232",
  },
  {
    from: 41.666,
    to: 50,
    color: "#FF4444",
  },
  {
    from: 50,
    to: 58.333,
    color: "#92DD47",
  },
  {
    from: 58.333,
    to: 66.666,
    color: "#61D21C",
  },
  {
    from: 66.666,
    to: 75,
    color: "#16BB37",
  },
  {
    from: 75,
    to: 83.333,
    color: "#10AB2F",
  },
  {
    from: 83.333,
    to: 91.666,
    color: "#0E9B2A",
  },
  {
    from: 91.666,
    to: 100,
    color: "#0B8724",
  },
];

export const labelsPercentages = sortByKey(
  [...colorRangesForPercetages],
  "from",
  SortOrder.DESC
).reduce<string[]>((output, item) => {
  if (item.from && item.to && item.from < 0 && item.to > 0) {
    output.push("0%");
  }

  let itemLabel = "";
  if (!item.from) {
    output[output.length - 1] = `<${item.to}%`;
    return output;
  } else if (!item.to) {
    itemLabel = `>${item.from}%`;
  } else {
    itemLabel = `${item.from}%`;
  }
  output.push(itemLabel);
  return output;
}, []);

export const getRangeColors = (range: ColorRange[]) => {
  return sortByKey([...range], "from", SortOrder.DESC).reduce<string[]>((output, item) => {
    output.push(item.color);
    return output;
  }, []);
};

export const getValueColor = (value: number) => {
  const range = colorRangesForValues.find(
    (item) =>
      (item.from || item.from === 0) &&
      (item.to || item.to === 0) &&
      value >= item.from &&
      value <= item.to
  );
  if (!range) return "#fff";

  return range.color;
};

export const getPercentageColor = (value: number) => {
  const range = colorRangesForPercetages.find((item) => {
    if (item.from && item.to) {
      return value >= item.from && value < item.to;
    }
    return (item.from && value >= item.from) || (item.to && value < item.to);
  });
  if (!range) return "#fff";

  return range.color;
};
