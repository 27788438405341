import dayjs, { Dayjs, QUnitType } from "dayjs";
import AdvancedFormat from "dayjs/plugin/advancedFormat";
import MinMax from "dayjs/plugin/minMax";
import QuarterOfYear from "dayjs/plugin/quarterOfYear";
import Utc from "dayjs/plugin/utc";
import { findIndex, map, pipe, sort } from "ramda";

import { defaultDateFormat } from "constants/dateFormat";
import { TimeGranularity } from "types";

dayjs.extend(Utc);
dayjs.extend(QuarterOfYear);
dayjs.extend(AdvancedFormat);
dayjs.extend(MinMax);

export type DateType = Date | Dayjs | number | string;

export const dayjsUTC = (date?: DateType, format?: string) =>
  date ? dayjs.utc(date, format) : dayjs.utc();

export const getMonths = (format = "MMMM") =>
  new Array(12).fill(null).map((_, idx) => dayjs().month(idx).format(format));

export const getDaysOfWeek = (format = "dddd") =>
  new Array(7).fill(null).map((_, idx) =>
    dayjs()
      .day(idx + 1)
      .format(format)
  );

export const MONTHS = getMonths("MMM").map((month) => month.toLowerCase());
export const DAYS_OF_WEEK = getDaysOfWeek();

export const formatDate = (date: DateType | null = null, format = defaultDateFormat) => {
  if (!date) {
    date = dayjsUTC();
  }
  return dayjsUTC(date).format(format);
};

export const dateDisplayMap = (date: DateType, timeGranularity: TimeGranularity) => {
  switch (timeGranularity) {
    case TimeGranularity.YEARLY: {
      return formatDate(date, "YYYY");
    }

    case TimeGranularity.QUARTERLY: {
      return `Q${formatDate(date, "Q/YYYY")}`;
    }

    case TimeGranularity.MONTHLY: {
      return formatDate(date, "MM/YYYY");
    }

    default: {
      return formatDate(date);
    }
  }
};

export const displayDateInTimeGranularity = (date: DateType, timeGranularity: TimeGranularity) => {
  switch (timeGranularity) {
    case TimeGranularity.YEARLY: {
      return formatDate(date, "YYYY");
    }

    case TimeGranularity.QUARTERLY: {
      return `Q${formatDate(date, "Q YYYY")}`;
    }

    case TimeGranularity.MONTHLY: {
      return formatDate(date, "MMM YYYY");
    }

    default: {
      return formatDate(date);
    }
  }
};

export const convertDateToPoint = (
  date: DateType | null | undefined,
  isEndPoint = false,
  timeGranularity: TimeGranularity = TimeGranularity.MONTHLY,
  startDate: DateType,
  isDoubleMove = false
) => {
  if (!date) return 0;
  if (timeGranularity !== TimeGranularity.MONTHLY) {
    const divider = timeGranularity === TimeGranularity.YEARLY ? 12 : 3;
    return (
      Math.floor(dayjsUTC(date).diff(dayjsUTC(startDate), "month") / divider) *
        (isDoubleMove ? 2 : 1) +
      (isEndPoint ? 1 : 0)
    );
  }
  return dayjsUTC(date).diff(dayjsUTC(startDate), "month");
};

export const convertPointToDate = (
  point: number,
  isEndPoint = false,
  timeGranularity: TimeGranularity = TimeGranularity.MONTHLY,
  startDate: DateType,
  isDoubleMove = false
) => {
  const timeUnit = timeUnitMap[timeGranularity];
  const duration = isDoubleMove ? Math.floor(point / 2) : point;
  let newDate = dayjsUTC(startDate).add(duration, timeUnit).startOf(timeUnit);
  if (isEndPoint) {
    newDate = newDate.endOf(timeUnit);
  }
  return formatDate(newDate);
};

export const shouldRemoveLastQuarter = (date: Dayjs) => {
  return Math.abs(date.diff(date.endOf("quarter"), "day")) > 0;
};

export const shouldRemoveLastYear = (date: Dayjs) => {
  return date.diff(date.startOf("year"), "month") < 10;
};

export const getDefaultEndDate = (date: DateType | undefined, timeGranularity: TimeGranularity) => {
  let defaultEndDate = dayjsUTC(date);
  if (timeGranularity === TimeGranularity.QUARTERLY && shouldRemoveLastQuarter(defaultEndDate)) {
    defaultEndDate = defaultEndDate.startOf("quarter").subtract(1, "day");
  }
  if (timeGranularity === TimeGranularity.YEARLY && shouldRemoveLastYear(defaultEndDate)) {
    defaultEndDate = defaultEndDate.startOf("year").subtract(1, "day");
  }
  return defaultEndDate;
};

export const timeUnitMap: Record<string, QUnitType> = {
  [TimeGranularity.MONTHLY]: "month",
  [TimeGranularity.QUARTERLY]: "quarter",
  [TimeGranularity.YEARLY]: "year",
};

const defaultTimeLengthMap = {
  [TimeGranularity.MONTHLY]: 11,
  [TimeGranularity.QUARTERLY]: 3,
  [TimeGranularity.YEARLY]: 1,
};

export const getDefaultTimeFilters = (
  filters: {
    currentTimeEnd: string | null;
    currentTimeStart: string | null;
    pastTimeEnd: string | null;
    pastTimeStart: string | null;
  },
  timeGranularity: TimeGranularity,
  endDate: DateType | undefined,
  startDate: Dayjs,
  timeLengthMap = defaultTimeLengthMap
) => {
  const defaultEndDate = getDefaultEndDate(endDate, timeGranularity);
  const timeUnit = timeUnitMap[timeGranularity];
  const defaultDates: {
    pastTimeStart: string;
    pastTimeEnd: string;
    currentTimeStart: string;
    currentTimeEnd: string;
  } = {
    pastTimeStart: filters.pastTimeStart || formatDate(startDate),
    pastTimeEnd: filters.pastTimeEnd || "",
    currentTimeStart: filters.currentTimeStart || "",
    currentTimeEnd: filters.currentTimeEnd || formatDate(defaultEndDate.endOf(timeUnit)),
  };
  if (!(defaultDates.currentTimeStart && defaultDates.pastTimeEnd)) {
    const timeLength = timeLengthMap[timeGranularity];
    defaultDates["currentTimeStart"] =
      filters.currentTimeStart ||
      formatDate(defaultEndDate.subtract(timeLength, timeUnit).startOf(timeUnit));
    defaultDates["pastTimeEnd"] =
      filters.pastTimeEnd || formatDate(startDate.add(timeLength, timeUnit).endOf(timeUnit));
  }

  return defaultDates;
};

export const getPeriodsNames = (
  start: DateType,
  end: DateType,
  timeGranularity: TimeGranularity
) => {
  const startDate = dayjsUTC(start);
  const endDate = getDefaultEndDate(end, timeGranularity);
  const timeUnit = timeUnitMap[timeGranularity];
  const diff = endDate.diff(startDate, timeUnit);
  const names: string[] = [];
  for (let i = 0; i <= diff; i++) {
    names.push(displayDateInTimeGranularity(startDate.add(i, timeUnit), timeGranularity));
  }
  return names;
};

export const checkIfIsNextPeriod = (
  current: DateType,
  next: DateType,
  timeGranularity: TimeGranularity
) => {
  const timeUnit = timeUnitMap[timeGranularity];
  return dayjsUTC(next).diff(dayjsUTC(current), timeUnit) === 0;
};

export const parsePeriodName2Date = (period: string, timeGranularity: TimeGranularity) => {
  let dateStr = period;
  const timeUnit = timeUnitMap[timeGranularity];
  if (timeGranularity === TimeGranularity.MONTHLY) {
    dateStr = `2 ${dateStr}`;
  } else if (timeGranularity === TimeGranularity.QUARTERLY) {
    const quarter = Number(dateStr[1]);
    dateStr = `${dateStr.slice(3)}-${quarter * 3 - 1}`;
  }

  return dayjsUTC(dateStr).startOf(timeUnit);
};

export const quarterStringToDate = (dateStr: string) => {
  const match = dateStr.match(/\d+/g);
  const quarter = match?.[0];
  const year = match?.[1];
  return dayjsUTC(year).quarter(Number(quarter));
};

const mouthStringToDate = (dateStr: string) => {
  const [month, year] = dateStr.split(" ");
  const monthIdx = MONTHS.findIndex((m) => m === month.toLowerCase());
  return dayjsUTC(year).month(monthIdx);
};

export const sortQuarterStrings = (array: string[]) =>
  pipe(
    map(quarterStringToDate),
    sort((a, b) => a.valueOf() - b.valueOf()),
    map((d) => `Q${formatDate(d, "Q YYYY")}`)
  )(array);

export const sortDateStrings = (array: string[], granularity: TimeGranularity) => {
  let toDate;
  switch (granularity) {
    case TimeGranularity.QUARTERLY: {
      toDate = quarterStringToDate;
      break;
    }
    case TimeGranularity.MONTHLY: {
      toDate = mouthStringToDate;
      break;
    }
    default: {
      toDate = dayjsUTC;
      break;
    }
  }

  return pipe(
    map(toDate),
    map((date) => date.set("date", 2)),
    sort((a, b) => a.valueOf() - b.valueOf()),
    map<Dayjs, string>((x) => displayDateInTimeGranularity(x, granularity))
  )(array);
};

export const findFirstFuturePeriodIndex = (
  periods: string[],
  currentDate: DateType,
  timeGranularity: TimeGranularity
) =>
  findIndex((period) => {
    const date = parsePeriodName2Date(period, timeGranularity);
    return date.isAfter(dayjsUTC(currentDate));
  }, periods);

export const adjustPeriodDates = (
  { periodStart, periodEnd }: { periodStart: string; periodEnd: string },
  timeGranularity: TimeGranularity,
  startDate: Dayjs,
  endDate: Dayjs
) => {
  const timeUnit = timeUnitMap[timeGranularity];
  let start = dayjsUTC(periodStart).startOf(timeUnit);
  let end = dayjsUTC(periodEnd).endOf(timeUnit);
  if (end.isAfter(endDate)) {
    end = endDate.clone();
  }
  if (
    start.get(timeUnit as any) === end.get(timeUnit as any) &&
    (timeGranularity === TimeGranularity.YEARLY || start.year() === end.year())
  ) {
    const newStart = start.subtract(1, timeUnit);
    if (newStart.isBefore(startDate, timeUnit)) {
      end = end.add(1, timeUnit);
    } else {
      start = newStart;
    }
  }
  return {
    periodStart: formatDate(start),
    periodEnd: formatDate(end),
  };
};
