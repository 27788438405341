import React, { useEffect } from "react";

import { pathOr } from "ramda";
import { Navigate, useLocation, Outlet } from "react-router-dom";

import { Spinner } from "components/Spinner";
import { getModuleFromPath } from "constants/appModules";
import { AXIOMHEALTH_TOKEN_KEY } from "constants/authentication";
import { loginPath, mainPath } from "constants/navigationPaths";
import { useHotJar, useLocalStorage, useRoutePath, useTracking, useCurrentUser } from "hooks";
import { createErrorPagePath } from "utils/navigation";

export const RouteProtection = () => {
  const { setUserId } = useTracking();
  const { identifyHotJar } = useHotJar();
  const { getItem } = useLocalStorage();
  const authToken = getItem<string>(AXIOMHEALTH_TOKEN_KEY);
  const { data: userData, isLoading, checkUserHasAccess } = useCurrentUser();
  const { search, pathname, state } = useLocation();
  const routePath = useRoutePath();

  const isAuthPath = routePath.startsWith("/auth/");

  useEffect(() => {
    if (userData) {
      setUserId(`${userData.id}`);
      identifyHotJar(userData);
    }
  }, [userData]);

  if (isLoading) {
    return <Spinner diameter={120} borderWidth={8} containerStyle={{ height: "100vh" }} />;
  }

  if (!authToken && !isAuthPath) {
    return <Navigate to={loginPath} state={{ from: `${pathname}${search}` }} />;
  }

  const pathModule = getModuleFromPath(routePath);
  if (!checkUserHasAccess(pathModule)) {
    return <Navigate to={createErrorPagePath(403)} />;
  }

  if (authToken && routePath === loginPath) {
    const goToPage = pathOr(mainPath, ["from"], state);
    return <Navigate to={goToPage} />;
  }

  return <Outlet />;
};
