import * as R from "ramda";

import { BaseDataModel } from "types";

import { BaseAdapter } from "../BaseAdapter";

type ReadmissionData = {
  name: string;
  min: number;
  dot5tile: number;
  dot25tile: number;
  mean: number;
  median: number;
  dot75tile: number;
  dot95tile: number;
  max: number;
  low: boolean;
};

export type Readmission = {
  name: string;
  data: Record<string, ReadmissionData>;
};

class ReadmissionsAdapterClass extends BaseAdapter<Readmission[]> {
  dataMapper = {
    dot5tile: R.pipe(R.prop("dot5tile"), R.multiply(100)),
    dot25tile: R.pipe(R.prop("dot25tile"), R.multiply(100)),
    dot75tile: R.pipe(R.prop("dot75tile"), R.multiply(100)),
    dot95tile: R.pipe(R.prop("dot95tile"), R.multiply(100)),
    max: R.pipe(R.prop("max"), R.multiply(100)),
    median: R.pipe(R.prop("median"), R.multiply(100)),
    min: R.pipe(R.prop("min"), R.multiply(100)),
    name: R.prop("name"),
    year: R.prop("year"),
    low: R.prop("low"),
  } as const;

  seriesPoint = {
    name: R.prop("name"),
    data: R.pipe(R.prop("data"), R.mapObjIndexed(this.runMapper(this.dataMapper))),
  } as const;

  toModel(data: BaseDataModel) {
    const series = R.propOr([], "dataSeries", data);
    return R.map<unknown, Readmission[]>(this.runMapper<Readmission>(this.seriesPoint), series);
  }
}

export const ReadmissionsAdapter = new ReadmissionsAdapterClass();
