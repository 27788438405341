import { Dayjs } from "dayjs";
import * as R from "ramda";

import { BaseDataModel } from "types";
import { dayjsUTC } from "utils";

import { BaseAdapter } from "../BaseAdapter";

import { LicenseModuleAdapter, LicenseModule } from "./LicenseModule";

export type Company = {
  details: {
    id: number;
    orgID: string;
    name: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    logo: string;
    mainContact: {
      name: string;
      phone: number;
      email: string;
    };
  };
  modules: LicenseModule[];
  licenceExpires: Dayjs;
};

class CompanyAdapterClass extends BaseAdapter<Company> {
  mainContactMapper = {
    name: R.propOr("", "mainContactName"),
    phone: R.propOr("", "mainContactTelephone"),
    email: R.propOr("", "mainContactEmail"),
  } as const;

  detailsMapper = {
    id: R.prop("id"),
    orgID: R.prop("orgId"),
    name: R.propOr("", "name"),
    address: R.propOr("", "address"),
    city: R.propOr("", "city"),
    state: R.propOr("", "state"),
    zip: R.propOr("", "zip"),
    logo: R.pipe(R.props(["orgId", "logo"]), ([orgID, logo]) => {
      if (R.isNil(logo)) return undefined;
      return `/tenants/${orgID}/assets/${logo}`;
    }),
    mainContact: this.runMapper(this.mainContactMapper),
  } as const;

  receivingMapper = {
    details: this.runMapper(this.detailsMapper),
    modules: R.pipe(R.pathOr([], ["license", "licensedModules"]), (data: BaseDataModel[]) =>
      data.reduce<LicenseModule[]>((acc, curr) => {
        const module = LicenseModuleAdapter.toModel(curr);
        if (module.isActive) {
          acc.push(module);
        }
        return acc;
      }, [])
    ),
    licenceExpires: R.pipe(R.path(["license", "expires"]), dayjsUTC),
  };

  sendingMapper = {
    name: R.pipe(R.prop("name"), R.trim),
    address: R.pipe(R.prop("address"), R.trim),
    city: R.pipe(R.prop("city"), R.trim),
    state: R.pipe(R.prop("state"), R.trim),
    zip: R.pipe(R.prop("zip"), R.trim),
    mainContactEmail: R.pipe(R.pathOr("", ["mainContact", "email"]), R.trim),
    mainContactName: R.pipe(R.pathOr("", ["mainContact", "name"]), R.trim),
    mainContactTelephone: R.pipe(R.pathOr("", ["mainContact", "phone"]), R.trim),
  } as const;

  toModel(data: BaseDataModel) {
    return this.runMapper<Company>(this.receivingMapper)(data);
  }

  toApiModel(data: Company["details"]) {
    return this.runMapper<BaseDataModel>(this.sendingMapper)(data);
  }
}

export const CompanyAdapter = new CompanyAdapterClass();
