import * as PATHS from "constants/navigationPaths";
import { Breadcrumb } from "types";

export const breadcrumbsMap: Record<string, Breadcrumb[]> = {
  // Market Intelligence
  [PATHS.marketIntelligenceDashboardPath]: ["Market Intelligence", "Dashboard"],
  [PATHS.proceduresPath]: ["Market Intelligence", "Procedures"],
  [PATHS.readmissionsPath]: ["Market Intelligence", "Readmissions"],

  // Sales Targeting
  [PATHS.hospitalTargetingPath]: ["Sales Targeting", "Facility Targeting"],
  [PATHS.hospitalProfilePath]: [
    "Sales Targeting",
    { path: PATHS.hospitalTargetingPath, label: "Facility Targeting" },
    "Facility Details",
  ],
  [PATHS.hospitalSegmentationPath]: ["Sales Targeting", "Search Facility"],
  [PATHS.physicianTargetingPath]: ["Sales Targeting", "Physician Targeting"],
  [PATHS.physicianProfilePath]: [
    "Sales Targeting",
    { path: PATHS.hospitalTargetingPath, label: "Physician Targeting" },
    "Physician Details",
  ],
  [PATHS.physicianSegmentationPath]: ["Sales Targeting", "Search Physician"],

  [PATHS.supportFAQSPath]: ["Support", "Frequently Asked Questions"],
  [PATHS.supportKeyFeaturesPath]: ["Support", "Key Features"],

  // Settings
  [PATHS.settingsOrgPath]: ["Settings", "Organization"],
  [PATHS.settingsMyProfilePath]: ["Settings", "Profile"],
  [PATHS.settingsUserProfilePath]: ["Settings", "Edit User"],
  [PATHS.settingsOrgAddGroupPath]: [
    "Settings",
    { path: PATHS.settingsOrgPath, label: "Organization" },
    "Create Group",
  ],
  [PATHS.settingsOrgGroupPath]: [
    "Settings",
    { path: PATHS.settingsOrgPath, label: "Organization" },
    "Edit Group",
  ],
};
