import * as R from "ramda";

import { BaseDataModel } from "types";

import { BaseAdapter } from "../BaseAdapter";

import { CompanyAdapter } from "./Company";
import { LicenseModule } from "./LicenseModule";
import { UserProfileAdapter } from "./User";

export type CompanyUser = {
  name: string;
  email: string;
  roles: string[];
  licenses: LicenseModule[];
  groupIds: number[];
  id: number;
  enabled: boolean;
  avatar?: string;
};

class CompanyStaffAdapterClass extends BaseAdapter<CompanyUser[]> {
  userMapper = {
    name: R.pipe(R.props(["name", "lastname"]), R.join(" ")),
    email: R.propOr("", "email"),
    roles: R.propOr([], "roles"),
    licenses: UserProfileAdapter.receivingMapper.licenses,
    groupIds: R.pipe(
      R.propOr([], "licenseAllocations"),
      R.pluck("appGroupId"),
      R.filter(Boolean),
      R.uniq
    ),
    id: R.prop("id"),
    avatar: CompanyAdapter.detailsMapper.logo,
    enabled: R.prop("enabled"),
  } as const;

  toModel(data: BaseDataModel[]) {
    return R.pipe(
      R.map(this.runMapper<CompanyUser>(this.userMapper)),
      R.sort((a, b) => {
        return a.enabled && !b.enabled
          ? -1
          : !a.enabled && b.enabled
          ? 1
          : a.name.localeCompare(b.name);
      })
    )(data);
  }
}

export const CompanyStaffAdapter = new CompanyStaffAdapterClass();
