export const isMacOS = () => /Mac/gm.test(window.navigator.platform);

export const isMobile = () => /Android|iPhone|iPad/gm.test(window.navigator.userAgent);

export enum KnownBrowsers {
  MS_EDGE = "Microsoft Edge",
  MS_IE = "Microsoft Internet Explorer",
  FIREFOX = "Mozilla Firefox",
  OPERA = "Opera",
  CHROME = "Google Chrome",
  SAFARI = "Apple Safari",
}
export const recommendedBrowsers: string[] = [KnownBrowsers.CHROME, KnownBrowsers.FIREFOX];

export const getBrowser = (): KnownBrowsers | "Unknown" => {
  const test = (regex: RegExp) => regex.test(window.navigator.userAgent);
  switch (true) {
    case test(/edg/i):
      return KnownBrowsers.MS_EDGE;
    case test(/trident/i):
      return KnownBrowsers.MS_IE;
    case test(/firefox|fxios/i):
      return KnownBrowsers.FIREFOX;
    case test(/opr\//i):
      return KnownBrowsers.OPERA;
    case test(/chrome|chromium|crios/i):
      return KnownBrowsers.CHROME;
    case test(/safari/i):
      return KnownBrowsers.SAFARI;
    default:
      return "Unknown";
  }
};

export const isRecommendedBrowser = () => recommendedBrowsers.includes(getBrowser());
