import { isNil } from "ramda";

import { getUserProfile } from "api/company";
import { settingsQueryKeys } from "constants/queryKeys";

import { useQueryData } from "./useQueryData";

export const useUserProfile = (userId: number) => {
  return useQueryData(
    [settingsQueryKeys.userProfile, userId],
    ({ signal }) => getUserProfile(userId, { signal }),
    {
      enabled: !isNil(userId),
    }
  );
};
