import { useContext } from "react";

import { clone } from "ramda";

import { ConfigContext } from "context/ConfigContext";

export const useFeatureFlags = () => {
  const { flags } = useContext(ConfigContext);
  return clone(flags);
};
