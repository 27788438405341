import { useContext } from "react";

import amplitude from "amplitude-js";

import { ConfigContext } from "context/ConfigContext";

export const useTracking = () => {
  const { ENV, AMPLITUDE_TRACKING_ID } = useContext(ConfigContext);

  const getInstance = () => amplitude.getInstance();

  const logEvent = (eventName: string, properties: unknown) => {
    ENV &&
      console.log(
        `%cAMPLITUDE LOG::%c${eventName}`,
        "font-weight: bold",
        "color: green",
        properties
      );
    AMPLITUDE_TRACKING_ID && getInstance().logEvent(eventName, properties);
  };

  const initializeTracking = (id: string) => {
    id && getInstance().init(id);
  };

  const setUserId = (userId: string) => {
    AMPLITUDE_TRACKING_ID && getInstance().setUserId(userId);
  };

  const pageView = (path: string) => {
    AMPLITUDE_TRACKING_ID && logEvent("pageView", { path });
  };

  return {
    logEvent,
    initializeTracking,
    setUserId,
    pageView,
  };
};
