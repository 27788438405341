export const colorsChart = [
  "#283f4a",
  "#e6514b",
  "#007670",
  "#97d057",
  "#316776",
  "#ef7b05",
  "#18916a",
  "#48cd99",
  "#35929e",
  "#f3a000",
  "#48996c",
  "#9ad0b1",
  "#8ecae6",
  "#f1c40f",
  "#5b9b45",
  "#d8debf",
  "#3ebfc2",
  "#ffc247",
  "#55a950",
  "#faf0e6",
];
