import React, { useEffect } from "react";

import { Outlet, useLocation } from "react-router-dom";

import { ErrorBoundary } from "components/ErrorBoundary";
import { useHotJar, useTracking } from "hooks";

import { AppErrorFallback } from "../App";

export const RouteTracker = () => {
  const { pathname } = useLocation();
  const { pageView } = useTracking();
  const { stateChangeHotJar } = useHotJar();

  useEffect(() => {
    pageView(pathname);
    stateChangeHotJar(pathname);
  }, [pathname]);

  return (
    <ErrorBoundary fallback={AppErrorFallback}>
      <Outlet />
    </ErrorBoundary>
  );
};
