import { FormikProps } from "formik";
import { all, equals, filter, isEmpty, keys, pipe, propOr, symmetricDifference } from "ramda";

export const useFormikActions = <F extends FormikProps<any>>(formik: F) => {
  const handleChange = (field: keyof F["values"]) => (value: unknown) => {
    formik.setFieldValue(field as string, value);
  };

  const handleBlur = (field: keyof F["values"]) => () => {
    formik.setFieldTouched(
      field as string,
      !!formik.initialValues[field] ? formik.values[field] !== formik.initialValues[field] : true
    );
  };

  const getError = (field: keyof F["errors"]) =>
    formik.touched[field] && formik.errors[field] ? `${formik.errors[field]}` : undefined;

  const checkIsDataUnchanged = (
    omitKeys: (keyof F["values"])[] = [],
    applied: Partial<F["values"]> = {}
  ) => {
    return pipe(
      keys,
      filter<keyof F["values"]>((key) => !omitKeys.includes(key)),
      all((key) => {
        const currData = formik.values[key];
        const initData: any = propOr(propOr([], key, formik.initialValues), key, applied);
        if (Array.isArray(currData)) {
          return isEmpty(symmetricDifference(currData, initData));
        } else {
          return equals(currData, initData);
        }
      })
    )(formik.values);
  };

  return { handleChange, handleBlur, getError, checkIsDataUnchanged };
};
