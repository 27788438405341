import * as R from "ramda";

import { BaseAdapter } from "adapters/BaseAdapter";
import { MapTableSeriesModel } from "adapters/facilityMap";
import { SegmentFiltersAdapter } from "adapters/SegmentFilters";
import { Mapper } from "types";
import { getStateCode } from "utils";

type PhysicianModel = {
  npi: string;
  name: string;
  facilityName: string;
  address: string;
  zipCode: string;
  state: string;
  city: string;
  facilityNpi: string;
  totalCount: number;
  counts: MapTableSeriesModel;
  periods: MapTableSeriesModel;
};

export type PhysicianMapTableModel = {
  totalRecords: number;
  physicians: PhysicianModel[];
};

const takeoutCounts = (data: { name: string; value: number | null }[]) => {
  return data.reduce((acc, { name, value }) => {
    acc[name] = value;
    return acc;
  }, Object.assign({}));
};

class PhysicianMapTableAdapterClass extends BaseAdapter<PhysicianMapTableModel> {
  paginationMapper = {
    pageSize: R.prop("pageSize"),
    pageNumber: R.prop("pageIndex"),
    sort: R.pipe(R.prop("sort"), ({ id, desc }: { id: string; desc: boolean }) => {
      const [column, value] = id.split(".");
      return {
        column,
        value,
        direction: desc ? "desc" : "asc",
      };
    }),
  } as const;

  areaCoordinatesMapper = {
    latMin: R.prop("latMin"),
    latMax: R.prop("latMax"),
    lngMin: R.prop("lngMin"),
    lngMax: R.prop("lngMax"),
  } as const;

  propsSendingMapper = {
    ...SegmentFiltersAdapter.sendingMapper,
    paging: R.pipe(R.prop("paging"), this.runMapper(this.paginationMapper)),
    physicianName: R.prop("search"),
    area: R.pipe(
      R.prop("area"),
      R.cond([
        [R.equals(undefined), R.always(undefined)],
        [R.T, (data: any) => this.runMapper(this.areaCoordinatesMapper)(data)],
      ])
    ),
  } as const;

  physicianMapper = {
    npi: R.propOr("", "physicianNpi"),
    name: R.propOr("", "physicianName"),
    facilityName: R.propOr("", "facilityName"),
    facilityNpi: R.propOr("", "facilityNpi"),
    address: R.propOr("", "facilityAddress"),
    zipCode: R.pipe(R.propOr("", "zipCode"), R.slice(0, 5)),
    state: R.pipe(R.propOr("", "state"), getStateCode),
    city: R.propOr("", "city"),
    totalCount: R.propOr(null, "totalCount"),
    counts: R.pipe(R.propOr([], "counts"), takeoutCounts),
    periods: R.pipe(R.propOr([], "periods"), takeoutCounts),
  } as const;

  receivingMapper = {
    totalRecords: R.prop("totalResults"),
    physicians: R.pipe(R.prop("physicians"), R.map(this.runMapper(this.physicianMapper))),
  } as const;

  toApiModel(data: unknown) {
    return R.map<Mapper, any>((fn) => fn(data), this.propsSendingMapper);
  }

  toModel(data: Record<string, unknown>) {
    return this.runMapper<PhysicianMapTableModel>(this.receivingMapper)(data);
  }
}

export const PhysicianMapTableAdapter = new PhysicianMapTableAdapterClass();
