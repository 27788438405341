import * as R from "ramda";

import { DiagnosisApiOption, DiagnosisItem, IAdapter, Mapper, OptionProps } from "types";
import { adaptDiagnosisOption, normalizeName } from "utils";

import {
  ProcedureItem,
  ProceduresAggregationLevel,
  ProceduresOptions,
  ProceduresOptionsAdapterClass,
} from "../ProceduresOptions";

export enum FacilityProfileAggregationLevels {
  PROCEDURE_MARKET_SEGMENT = "procedureSegment",
  PROCEDURE_CATEGORY = "procedureCategory",
  PROCEDURE = "procedure",
  DIAGNOSIS_MARKET_SEGMENT = "diagnosisMarketSegments",
  DIAGNOSIS_CATEGORY = "diagnosisCategories",
  DIAGNOSIS = "diagnosis",
}

const sendingAggregationLevelMapping = {
  [ProceduresAggregationLevel.PROCEDURE]: "procedures",
  [ProceduresAggregationLevel.CATEGORY]: "proceduresCategories",
  [ProceduresAggregationLevel.SEGMENT]: "proceduresMarketSegments",
  // [FacilityProfileAggregationLevels.DIAGNOSIS]: "diagnosis",
  // [FacilityProfileAggregationLevels.DIAGNOSIS_CATEGORY]: "diagnosisCategories",
  // [FacilityProfileAggregationLevels.DIAGNOSIS_MARKET_SEGMENT]: "diagnosisMarketSegments",
};

export type FacilityProfileFiltersOptionsModel = ProceduresOptions & {
  // diagnosisMarketSegments: DiagnosisItem[];
  // diagnosis: DiagnosisItem[];
  diagnosisCategories: DiagnosisItem[];
  procedureAspOptions: OptionProps[];
  stackingColumns: OptionProps[];
  kpisTimeSpans: OptionProps[];
};

export type FacilityProfileFiltersModel = {
  [ProceduresAggregationLevel.SEGMENT]: ProcedureItem | undefined;
  [ProceduresAggregationLevel.CATEGORY]: ProcedureItem[];
  [ProceduresAggregationLevel.PROCEDURE]: ProcedureItem[];
  diagnosisCategories: DiagnosisItem[];
  aggregationLevel: FacilityProfileAggregationLevels;

  procedureAspType: OptionProps;

  timeGranularity: OptionProps;
  periodStart: string;
  periodEnd: string;
};

const ProceduresOptionsAdapter = new ProceduresOptionsAdapterClass();

type OptionType<T = string> = { label: string; value: T };
export const sortOptionsByLabels = R.sort((a: OptionType, b: OptionType) =>
  a.label === "N/A" || a.label.startsWith(">") || b.label.startsWith("<")
    ? 1
    : b.label === "N/A" || a.label.startsWith("<") || b.label.startsWith(">")
    ? -1
    : a.label.localeCompare(b.label)
);

export const getSortedProp = (key: string) =>
  R.pipe<any, OptionType[], OptionType[]>(R.propOr([], key), sortOptionsByLabels);

const takeOutProcedure = (prop: ProceduresAggregationLevel) =>
  R.pipe<any, ProcedureItem[], string[]>(R.prop(prop), R.map(R.prop("label")));

class FacilityProfileFiltersAdapterClass implements IAdapter {
  receivingMapper = {
    diagnosisCategories: R.pipe<any, DiagnosisApiOption[], DiagnosisItem[]>(
      R.prop("diagnosisCategories"),
      R.map(adaptDiagnosisOption)
    ),
    procedureAspOptions: R.pipe(getSortedProp("procedureAspType"), (data) => {
      if (R.isEmpty(data)) return data;
      const allItem = R.find(R.propEq("label", "all"), data);
      if (!allItem) {
        return R.prepend({ value: "all", label: "Show all" }, data);
      }
      return data;
    }),
    stackingColumns: R.prop("stackingColumnsOptions"),
    kpisTimeSpans: R.pipe<any, string[], OptionProps[]>(
      R.prop("kpisTimeSpans"),
      R.map((item) => ({ value: item, label: normalizeName(item) }))
    ),
  };

  sendingMapper = {
    aggregationLevel: R.pipe(
      R.prop("aggregationLevel"),
      R.prop(R.__, sendingAggregationLevelMapping) as any
    ),
    npi: R.prop("npi"),

    procedures: (item: any) =>
      R.cond<any, string[]>([
        [
          R.equals(ProceduresAggregationLevel.PROCEDURE),
          R.always(takeOutProcedure(ProceduresAggregationLevel.PROCEDURE)(item)),
        ],
        [R.T, R.always([])],
      ])(R.prop("aggregationLevel", item)),
    proceduresCategories: (item: any) =>
      R.cond<any, string[]>([
        [
          R.equals(ProceduresAggregationLevel.CATEGORY),
          R.always(takeOutProcedure(ProceduresAggregationLevel.CATEGORY)(item)),
        ],
        [
          R.equals(ProceduresAggregationLevel.PROCEDURE),
          R.always(
            R.uniq(
              R.flatten<string[]>(
                R.map(R.propOr("", "categories"))(
                  R.prop(ProceduresAggregationLevel.PROCEDURE, item)
                )
              )
            )
          ),
        ],
        [R.T, R.always([])],
      ])(R.prop("aggregationLevel", item)),
    proceduresMarketSegments: R.pipe(
      R.path([ProceduresAggregationLevel.SEGMENT, "label"]),
      (data) => [data]
    ),
    diagnosisCategories: R.pipe<any, DiagnosisItem[], string[]>(
      R.prop("diagnosisCategories"),
      R.map(R.prop("label"))
    ),
    procedureAspType: R.pathOr(undefined, ["procedureAspType", "value"]),
    timeGranularity: R.path(["timeGranularity", "value"]),
    periodStart: R.prop("periodStart"),
    periodEnd: R.prop("periodEnd"),
  };

  toModel(item: unknown) {
    const options = R.map<Mapper, FacilityProfileFiltersOptionsModel>(
      (fn) => fn(item),
      this.receivingMapper
    );
    return { ...options, ...ProceduresOptionsAdapter.toModel(item) };
  }

  toApiModel(item: unknown) {
    return R.map<Mapper, any>((fn) => fn(item), this.sendingMapper);
  }
}

export const FacilityProfileFiltersAdapter = new FacilityProfileFiltersAdapterClass();
