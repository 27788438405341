import React from "react";

import cn from "classnames";

import styles from "./FilterPill.module.scss";

type ConditionalPillProps =
  | { permanent?: false; onClose: () => void }
  | { permanent: true; onClose?: never };

type Props = {
  label: string;
} & ConditionalPillProps;

export const FilterPill = ({ label, onClose, permanent }: Props) => {
  return (
    <span className={styles.pill}>
      {label}
      <button onClick={onClose} className={cn(styles.close, { [styles.hidden]: permanent })}>
        &#x2715;
      </button>
    </span>
  );
};
