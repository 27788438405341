import * as R from "ramda";

import { BaseDataModel, DataSeriesResponse } from "types";

import { BaseAdapter } from "../BaseAdapter";

export type FacilityPhysician = {
  fullName: string;
  specialty: string;
  npi: string;
  totals: DataSeriesResponse;
};

class FacilityStaffAdapterClass extends BaseAdapter<FacilityPhysician[]> {
  physicianMapper = {
    fullName: R.prop("fullName"),
    specialty: R.prop("specialty"),
    npi: R.prop("npi"),
    totals: R.prop("total"),
  } as const;

  toModel(item: BaseDataModel) {
    return R.pipe(
      R.propOr([], "physicians"),
      R.map(this.runMapper<FacilityPhysician>(this.physicianMapper)),
      R.sort((a, b) => {
        if (!a.npi) return 1;
        if (!b.npi) return -1;
        return a.fullName.localeCompare(b.fullName);
      })
    )(item);
  }
}

export const FacilityStaffAdapter = new FacilityStaffAdapterClass();
