import * as R from "ramda";

import { IAdapter, Mapper, ProcedureApiOption } from "types";

export type ProcedureItem = {
  segments?: string[];
  categories?: string[];
  locations?: string[];
  procedure?: string;
  label: string;
  value: string;
};

export enum ProceduresAggregationLevel {
  SEGMENT = "procedureSegment",
  CATEGORY = "procedureCategory",
  LOCATION = "procedureLocation",
  PROCEDURE = "procedure",
}

export type ProceduresOptions = {
  [ProceduresAggregationLevel.SEGMENT]: ProcedureItem[];
  [ProceduresAggregationLevel.CATEGORY]: ProcedureItem[];
  [ProceduresAggregationLevel.LOCATION]: ProcedureItem[];
  [ProceduresAggregationLevel.PROCEDURE]: ProcedureItem[];
};

export const aggregationLevel2KeyMap: Record<ProceduresAggregationLevel, keyof ProcedureApiOption> =
  {
    [ProceduresAggregationLevel.SEGMENT]: "segment",
    [ProceduresAggregationLevel.CATEGORY]: "category",
    [ProceduresAggregationLevel.LOCATION]: "location",
    [ProceduresAggregationLevel.PROCEDURE]: "name",
  };

const itemKey2ApiKey: Record<
  Exclude<keyof ProcedureItem, "label" | "value">,
  keyof ProcedureApiOption
> = {
  segments: "segment",
  categories: "category",
  locations: "location",
  procedure: "name",
};

const adjustItem = (
  keys: Exclude<keyof ProcedureItem, "label" | "value" | "procedure">[],
  itemIndex: number,
  array: ProcedureItem[],
  newItem: ProcedureApiOption
) => {
  keys.map((key) => {
    const apiKey = itemKey2ApiKey[key];
    const values = array[itemIndex][key] || [];
    values.push(newItem[apiKey]);
    array[itemIndex][key] = R.uniq(values);
  });
};

const createProceduresOptions =
  (key: Exclude<keyof ProcedureApiOption, "id">) => (options: ProcedureApiOption[]) => {
    if (key === "segment") {
      options = R.uniqBy(R.prop(key), options);
    }
    return options.reduce<ProcedureItem[]>((acc, curr) => {
      let newItem: ProcedureItem = {
        segments: [curr.segment],
        categories: [curr.category],
        locations: [curr.location],
        procedure: curr.name,
      } as any;
      switch (key) {
        case "segment": {
          newItem = R.omit(["categories", "locations", "procedure"], newItem);
          newItem["label"] = curr.segment;
          newItem["value"] = curr.segment;
          acc.push(newItem);
          break;
        }
        case "category": {
          const index = R.findIndex(R.propEq("label", curr.category), acc as any);
          if (index !== -1) {
            adjustItem(["segments"], index, acc, curr);
          } else {
            newItem = R.omit(["locations", "procedure"], newItem);
            newItem["label"] = curr.category;
            newItem["value"] = `${curr.segment}_${curr.category}`;
            acc.push(newItem);
          }
          break;
        }
        case "location": {
          const index = R.findIndex(R.propEq("label", curr.location), acc);
          if (index !== -1) {
            adjustItem(["segments", "categories"], index, acc, curr);
          } else {
            newItem = R.omit(["procedure"], newItem);
            newItem["label"] = curr.location;
            newItem["value"] = `${curr.segment}_${curr.category}_${curr.location}`;
            acc.push(newItem);
          }
          break;
        }
        default: {
          const index = R.findIndex(R.propEq("label", curr.name), acc as any);
          if (index !== -1) {
            adjustItem(["segments", "categories", "locations"], index, acc, curr);
            acc[index].value += `+${curr.id}`;
          } else {
            newItem["label"] = curr.name;
            newItem["value"] = curr.id;
            acc.push(newItem);
          }
          break;
        }
      }
      return acc;
    }, []);
  };

const takeOutProceduresOptions = (key: Exclude<keyof ProcedureApiOption, "id">) =>
  R.pipe<any, ProcedureApiOption[], ProcedureItem[], ProcedureItem[]>(
    R.prop("procedures"),
    createProceduresOptions(key),
    R.sortBy(R.prop("label"))
  );

const getSelectedNames = (level: ProceduresAggregationLevel, data: ProceduresOptions) => {
  if (level === ProceduresAggregationLevel.SEGMENT) {
    return [R.path([level, "label"], data) as string];
  } else {
    return R.pipe<any, ProcedureItem[], string[]>(R.prop(level), R.map(R.prop("label")))(data);
  }
};

export class ProceduresOptionsAdapterClass implements IAdapter {
  procedureOptions: ProcedureApiOption[] = [];

  receivingMapper = {
    procedureSegment: takeOutProceduresOptions("segment"),
    procedureCategory: takeOutProceduresOptions("category"),
    procedureLocation: takeOutProceduresOptions("location"),
    procedure: takeOutProceduresOptions("name"),
  } as const;

  sendingMapper = {
    procedures: (
      data: ProceduresOptions & { aggregationLevel: ProceduresAggregationLevel }
    ): string[] => {
      const level = R.prop("aggregationLevel", data);
      const key = aggregationLevel2KeyMap[level];
      const selectedNames = getSelectedNames(level, data);

      return this.procedureOptions.reduce((acc, curr) => {
        if (selectedNames.includes(curr[key])) {
          acc.push(curr.id);
        }
        return acc;
      }, [] as string[]);
    },
  } as const;

  toModel(item: unknown) {
    this.procedureOptions = R.prop("procedures", item as any);
    return R.map<Mapper, ProceduresOptions>((fn) => fn(item), this.receivingMapper);
  }

  toApiModel(item: unknown) {
    return R.map<Mapper, { procedures: string[] }>((fn) => fn(item), this.sendingMapper);
  }
}
