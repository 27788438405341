import React from "react";

import { Flex, Form, Layout } from "antd";
import { Outlet } from "react-router-dom";

import { AxiomHealthLogoSVG } from "assets/icons";
import { Footer } from "components/Footer";

import styles from "./AuthLayout.module.scss";

const { Content } = Layout;

export const AuthLayout = () => {
  return (
    <>
      <Content className={styles.authLayoutMain}>
        <Flex vertical align='center' gap='0.5rem' className={styles.container}>
          <Flex align='center' justify='center'>
            <AxiomHealthLogoSVG className={styles.logo} />
          </Flex>
          <Form className={styles.authFormContent} layout='vertical'>
            <Outlet />
          </Form>
        </Flex>
      </Content>
      <Footer />
    </>
  );
};
