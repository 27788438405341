import React from "react";

import { Alert } from "antd";
import cn from "classnames";

import { useAlerts } from "hooks";
import { AlertItem } from "types";

import styles from "./Alerts.module.scss";

const renderAlert = (alert: AlertItem, dismiss: (id: string) => void) => {
  return (
    <Alert
      closable
      onClose={() => {
        dismiss(alert.key);
      }}
      key={alert.key}
      className={cn(styles.ah_alert, {
        [styles.hiding]: !alert.isShown,
        [styles.showing]: alert.isShowing,
        [styles.shaking]: alert.isShaking,
      })}
      type={alert.type}
      message={alert.message}
    />
  );
};

export const Alerts = () => {
  const { alerts, dismissAlert } = useAlerts();

  if (!alerts?.length) {
    return null;
  }

  return (
    <div className={styles.alertsContainer} data-testid='alerts-container'>
      {alerts.map((item) => renderAlert(item, dismissAlert))}
    </div>
  );
};
