import { RefObject } from "react";

import { ExportingOptions, Options } from "highcharts/highcharts";
import { mergeDeepRight } from "ramda";

import { exportDateFormat } from "constants/dateFormat";
import { ChartExportingMimeType } from "types";
import { formatDate } from "utils";

export type ChartRefProp = {
  chart: {
    exportChartLocal: (exportOptions: ExportingOptions, chartOptions?: Options) => void;
    exportChart: (exportOptions: ExportingOptions, chartOptions?: Options) => void;
  };
};

const pdfLabelStyles = { textOutline: "none", textShadow: false };

export const chartExport = (
  chartRef: RefObject<ChartRefProp>,
  type: ChartExportingMimeType,
  filename: string,
  exportingOptions: ExportingOptions = {},
  chartOptions: Options = {},
  useLocalExport = false
) => {
  if (chartRef.current) {
    const exportFunction = useLocalExport ? "exportChartLocal" : "exportChart";
    try {
      const chartOpts =
        type === ChartExportingMimeType.PDF
          ? {
              plotOptions: {
                series: { dataLabels: { style: pdfLabelStyles } },
              },
              yAxis: {
                stackLabels: { style: pdfLabelStyles },
                labels: { style: pdfLabelStyles },
              },
              xAxis: { labels: { style: pdfLabelStyles } },
            }
          : {};
      chartRef.current.chart[exportFunction](
        {
          type,
          filename: `${filename}_${formatDate(null, exportDateFormat)}`,
          ...mergeDeepRight({ sourceWidth: 1500 }, exportingOptions),
        },
        mergeDeepRight(chartOpts, chartOptions)
      );
    } catch (e) {
      console.log("chart export error:", e);
    }
  }
};
