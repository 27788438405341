import { useEffect, useState } from "react";

import { isNil, pluck } from "ramda";

import { getInitialStack, SegmentFiltersModel } from "adapters";
import { PageId } from "constants/pageIds";

import { useCombinedSegmentsConfig } from "./useCombinedSegmentsConfig";
import { useSegmentFilters } from "./useSegmentFilters";
import { useSegments } from "./useSegments";

export const useStackBy = (pageId: PageId, appliedFilters: SegmentFiltersModel | undefined) => {
  const { data: segmentsData } = useSegments(pageId);
  const segmentKeys = pluck("id", segmentsData || []);
  const defaultSegments =
    !isNil(appliedFilters) && !isNil(appliedFilters?.segments)
      ? appliedFilters.segments
      : segmentKeys.slice(0, 1);

  const { data } = useSegmentFilters(pageId, defaultSegments);

  const config = useCombinedSegmentsConfig(defaultSegments, data || {});

  const [stackBy, setStackBy] = useState(
    getInitialStack(appliedFilters?.aggregationLevel, config?.stackByColumns || [])
  );

  useEffect(() => {
    if (!isNil(appliedFilters) && !isNil(config) && !isNil(config?.stackByColumns)) {
      setStackBy((prev) => {
        const newStack = getInitialStack(appliedFilters.aggregationLevel, config.stackByColumns);
        return newStack !== prev ? newStack : prev;
      });
    }
  }, [appliedFilters, config?.stackByColumns]);

  return { stackByOptions: config?.stackByColumns, stackBy, setStackBy };
};
