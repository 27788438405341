import { RefObject, useEffect, useState } from "react";

export const useResizeObserver = (ref: RefObject<any>) => {
  const [windowSize, setWindowSize] = useState<{
    width: number | undefined;
    height: number | undefined;
  }>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize(entry?: any) {
      const elemRect = entry?.contentRect || ref.current.getBoundingClientRect();

      setWindowSize({
        width: elemRect.width,
        height: elemRect.height,
      });
    }

    let resizeObserver: ResizeObserver | undefined;
    if (typeof window !== "undefined" && typeof ResizeObserver !== "undefined") {
      resizeObserver = new ResizeObserver((entries: any) => {
        handleResize(entries[0]);
      });
      resizeObserver?.observe(ref.current);
    }

    handleResize();
    return () => {
      resizeObserver?.disconnect();
    };
  }, [ref]);

  return windowSize;
};
