import useHotjar from "react-use-hotjar";

import { UserProfileModel } from "adapters";

export const useHotJar = () => {
  const { identifyHotjar, initHotjar, readyState, stateChange } = useHotjar();

  const initHotJar = (id: number, version: number, debug: boolean) => {
    initHotjar(id, version, debug);
  };

  const identifyHotJar = (user: UserProfileModel) => {
    if (readyState) {
      const { id, ...rest } = user;
      identifyHotjar(`${id}`, rest as any);
    }
  };

  const stateChangeHotJar = (path: string) => {
    readyState && stateChange(path);
  };

  return { initHotJar, identifyHotJar, stateChangeHotJar };
};
