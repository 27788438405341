import * as R from "ramda";

import { BaseDataModel } from "types";

import { BaseAdapter } from "../BaseAdapter";

import { CompanyAdapter } from "./Company";
import { CompanyUser } from "./CompanyStaff";
import { LicenseModule, LicenseModuleAdapter } from "./LicenseModule";

export type CompanyGroup = {
  id: number;
  name: string;
  modules: LicenseModule[];
  users: CompanyUser[];
};

export type CompanyGroupCreate = {
  name: string;
  modules: number[];
};

export type CompanyGroupUpdate = CompanyGroupCreate & { id: number; users: number[] };

class CompanyGroupsAdapterClass extends BaseAdapter<CompanyGroup[]> {
  receivingMapper = {
    id: R.prop("id"),
    name: R.propOr("", "name"),
    modules: R.pipe(
      R.propOr([], "licensedModules"),
      R.map<BaseDataModel, LicenseModule>((data) => LicenseModuleAdapter.toModel(data))
    ),
    users: R.propOr([], "users"),
  } as const;

  createMapper = {
    name: R.prop("name"),
    moduleLicenseIds: R.prop("modules"),
  } as const;

  updateMapper = {
    ...this.createMapper,
    id: R.prop("id"),
    userIds: R.prop("users"),
  } as const;

  toModel(data: BaseDataModel[]) {
    return R.map(this.runMapper<CompanyGroup>(this.receivingMapper), data);
  }

  toCreateModel(data: CompanyGroupCreate) {
    return this.runMapper<BaseDataModel>(this.createMapper)(data);
  }

  toUpdateModel(data: CompanyGroupUpdate) {
    return this.runMapper<BaseDataModel>(this.updateMapper)(data);
  }
}

export const CompanyGroupsAdapter = new CompanyGroupsAdapterClass();
