import { forEach, isNil, keys } from "ramda";
import * as XLSX from "sheetjs-style";

import { blobExport } from "./blobExport";

export const xlsxExport = (
  filename: string,
  data: string[][],
  defaultFormat = "#,##0",
  percentFormat = "0%",
  noFormatColumns: number[] = []
) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(data);
  const cells = keys(ws) as string[];
  // set cell number format
  forEach((cellCode) => {
    const { c, r } = XLSX.utils.decode_cell(cellCode);
    if (r === 0) {
      ws[cellCode].s = { font: { bold: true } };
    }
    if (c < 1 || r < 1 || noFormatColumns.includes(c)) return;

    const cellStringValue = String(ws[cellCode].v);
    if (!cellStringValue.match(/\d+/gm)) {
      ws[cellCode].v = "-";
      return;
    }
    const isPercent = /%$/.test(cellStringValue);
    const format = isPercent ? percentFormat : defaultFormat;
    const cellValue = isPercent ? Number(cellStringValue.replace(/%/, "")) : cellStringValue;
    ws[cellCode].v =
      isPercent && typeof cellValue === "number" ? Number((cellValue / 100).toFixed(4)) : cellValue;
    ws[cellCode].t = "n";
    ws[cellCode].s = { numFmt: format, font: isPercent ? { italic: true } : undefined };
    ws[cellCode].z = format;
  }, cells);
  // set column width
  const range = XLSX.utils.decode_range(ws["!ref"] || "");
  const wsCols = [];
  const maxRow = Number(XLSX.utils.encode_row(range.e.r));
  for (let col = 0; col <= range.e.c; col++) {
    const colName = XLSX.utils.encode_col(col);
    let maxLen = String(ws[`${colName}1`].v).length;
    for (let row = 2; row <= maxRow; row++) {
      const cell = ws[`${colName}${row}`];
      const cellValue = cell?.v;
      const cellValLen = !isNil(cellValue) ? String(cellValue.v).length : 0;
      if (cellValLen > maxLen) {
        maxLen = cellValLen;
      }
    }
    if (col === 0) {
      maxLen -= 5;
    } else {
      maxLen += 5;
    }
    wsCols.push({ wch: maxLen });
  }
  ws["!cols"] = wsCols;
  XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
  const wbOut = XLSX.write(wb, { type: "array", bookType: "xlsx" });
  blobExport(filename, new Blob([wbOut], { type: "application/octet-stream" }), "xlsx");
};
